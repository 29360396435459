import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';

export default function ReloadPage(props) {
  const { t } = useTranslation();
  const { message } = props;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 3,
      gap: 2,
      height: '80vh',
    }}
    >

      <Typography
        component="span"
        sx={{ maxWidth: 800 }}
      >
        {message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.location.reload()}
        endIcon={<ReplayIcon />}
      >
        {t('reload')}
      </Button>
    </Box>
  );
}
