import React, { useEffect, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  query,
  collection,
  getDocs,
  where,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import IssuerCodes from './IssuerCodes';

export default function DisplayIssuer(props) {
  const { name, expanded = false } = props;
  const db = useFirestore();
  const { t } = useTranslation();
  const [displayAll, setdisplayAll] = React.useState(expanded);

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  const [availableReusableCodes, setavailableReusableCodes] = useState({ state: 'initial', codes: [] });
  const [availableSubscriptionCodes, setavailableSubscriptionCodes] = useState({ state: 'initial', codes: [] });
  const [availableReusableExpiredCodes, setavailableReusableExpiredCodes] = useState({ state: 'initial', codes: [] });
  const [availableSubscriptionExpiredCodes, setavailableSubscriptionExpiredCodes] = useState({
    state: 'initial',
    codes: [],
  });

  const [activatedReusableCodes, setactivatedReusableCodes] = useState({ state: 'initial', codes: [] });
  const [activatedSubscriptionCodes, setactivatedSubscriptionCodes] = useState({ state: 'initial', codes: [] });

  const setupAvailableReusableCodes = useCallback(async () => {
    const newCodes = await queryAvailableCodesByTypeAndIssuer(db, name, 'ReusableCode');
    setavailableReusableCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const setupAvailableSubscriptionCodes = useCallback(async () => {
    const newCodes = await queryAvailableCodesByTypeAndIssuer(db, name, 'ExtraCreditsSubscriptionCode');
    setavailableSubscriptionCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const setupAvailableReusableExpiredCodes = useCallback(async () => {
    const newCodes = await queryAvailableExpiredCodesByTypeAndIssuer(db, name, 'ReusableCode');
    setavailableReusableExpiredCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const setupAvailableSubscriptionExpiredCodes = useCallback(async () => {
    const newCodes = await queryAvailableExpiredCodesByTypeAndIssuer(db, name, 'ExtraCreditsSubscriptionCode');
    setavailableSubscriptionExpiredCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const setupActivatedReusableCodes = useCallback(async () => {
    const newCodes = await queryActivatedCodesByTypeAndIssuer(db, name, 'ReusableCode');
    setactivatedReusableCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const setupActivatedSubscriptionCodes = useCallback(async () => {
    const newCodes = await queryActivatedCodesByTypeAndIssuer(db, name, 'ExtraCreditsSubscriptionCode');
    setactivatedSubscriptionCodes({ state: 'loaded', codes: newCodes });
  }, [db, name]);

  const allReady = [
    availableReusableCodes,
    availableSubscriptionCodes,
    availableReusableExpiredCodes,
    availableSubscriptionExpiredCodes,
    activatedReusableCodes,
    activatedSubscriptionCodes,
  ].every((v) => v.state === 'loaded');

  useEffect(() => {
    if ([
      availableReusableCodes,
      availableSubscriptionCodes,
      availableReusableExpiredCodes,
      availableSubscriptionExpiredCodes,
      activatedReusableCodes,
      activatedSubscriptionCodes,
    ].every((v) => v.state === 'initial')
    && displayAll) {
      setavailableReusableCodes({ state: 'loading', codes: [] });
      setavailableSubscriptionCodes({ state: 'loading', codes: [] });
      setavailableReusableExpiredCodes({ state: 'loading', codes: [] });
      setavailableSubscriptionExpiredCodes({ state: 'loading', codes: [] });
      setactivatedReusableCodes({ state: 'loading', codes: [] });
      setactivatedSubscriptionCodes({ state: 'loading', codes: [] });
      setupAvailableReusableCodes();
      setupAvailableSubscriptionCodes();
      setupAvailableReusableExpiredCodes();
      setupAvailableSubscriptionExpiredCodes();
      setupActivatedReusableCodes();
      setupActivatedSubscriptionCodes();
    }
  }, [availableReusableCodes,
    availableReusableExpiredCodes, availableSubscriptionCodes,
    availableSubscriptionExpiredCodes, db, name,
    setupActivatedReusableCodes, setupActivatedSubscriptionCodes,
    setupAvailableReusableCodes, setupAvailableReusableExpiredCodes,
    setupAvailableSubscriptionCodes, setupAvailableSubscriptionExpiredCodes,
    activatedReusableCodes, activatedSubscriptionCodes, displayAll]);

  return (
    <Paper
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 2,
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          cursor: 'pointer',
        }}
        onClick={() => setdisplayAll(!displayAll)}
      >
        <Typography variant="h6">
          {name}
        </Typography>
        <Button variant="text" size="large">
          {expandIcon()}
        </Button>
      </Box>
      {displayAll && !allReady && (<LinearProgress sx={{ width: '100%' }} />)}
      <Collapse
        in={displayAll}
        sx={{
          width: '100%',
        }}
      >
        {allReady && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 3,
          }}
        >
          <IssuerCodes
            title={`${t('codes.subscription_codes')} ${t('codes.available')}`}
            codes={availableSubscriptionCodes.codes}
          />
          <IssuerCodes
            title={`${t('codes.subscription_codes')} ${t('codes.activated')}`}
            codes={activatedSubscriptionCodes.codes}
          />
          <IssuerCodes
            deletables
            title={`${t('codes.subscription_codes')} ${t('codes.available_expired')}`}
            codes={availableSubscriptionExpiredCodes.codes}
          />
          <br />
          <IssuerCodes
            title={`${t('codes.reusable_codes')} ${t('codes.available')}`}
            codes={availableReusableCodes.codes}
          />
          <IssuerCodes
            title={`${t('codes.reusable_codes')} ${t('codes.activated')}`}
            codes={activatedReusableCodes.codes}
          />
          <IssuerCodes
            deletables
            title={`${t('codes.reusable_codes')} ${t('codes.available_expired')}`}
            codes={availableReusableExpiredCodes.codes}
          />
        </Box>
        )}
      </Collapse>
    </Paper>
  );
}

async function queryAvailableCodesByTypeAndIssuer(firestore, issuer, codeType) {
  // console.log('queryAvailableCodesByTypeAndIssuer: ', issuer, codeType);
  const codesQuery = query(
    collection(firestore, 'apps', 'alcoholometry', 'availableCodes'),
    where('issuer', '==', issuer),
    where('codeExpiryDate', '>', new Date()),
    where('type', '==', codeType),
  );
  const querySnapshot = await getDocs(codesQuery);
  const { docs } = querySnapshot;
  return docs.map((operationDoc) => operationDoc.data());
}

async function queryAvailableExpiredCodesByTypeAndIssuer(firestore, issuer, codeType) {
  // console.log('queryAvailableExpiredCodesByTypeAndIssuer: ', issuer, codeType);
  const codesQuery = query(
    collection(firestore, 'apps', 'alcoholometry', 'availableCodes'),
    where('issuer', '==', issuer),
    where('codeExpiryDate', '<=', new Date()),
    where('type', '==', codeType),
  );
  const querySnapshot = await getDocs(codesQuery);
  const { docs } = querySnapshot;
  return docs.map((operationDoc) => operationDoc.data());
}

async function queryActivatedCodesByTypeAndIssuer(firestore, issuer, codeType) {
  // console.log('queryActivatedCodesByTypeAndIssuer: ', issuer, codeType);
  const codesQuery = query(
    collection(firestore, 'issuers', issuer, 'apps', 'alcoholometry', 'activatedCodes'),
    where('type', '==', codeType),
  );
  const querySnapshot = await getDocs(codesQuery);
  const { docs } = querySnapshot;
  return docs.map((operationDoc) => operationDoc.data());
}
