import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UllagingLaying from './UllagingLaying';
import UllagingStanding from './UllagingStanding';
import TruncatedConeTank from './TruncatedConeTank';

export default function Ullaging(props) {
  const { t } = useTranslation();
  const { ullaging, ullagingStanding, ullagingTruncatedCone } = props;
  const [tab, setTab] = React.useState(initTab());

  function initTab() {
    const savedTab = localStorage.getItem('last_ullaging_tab');
    if (!savedTab) {
      return 'laying';
    }
    return savedTab;
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('last_ullaging_tab', newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    }}
    >
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered>
            <Tab label={t('ullaging.laying')} value="laying" />
            <Tab label={t('ullaging.standing')} value="standing" />
            <Tab label={t('ullaging.truncated_cone')} value="truncatedCone" />

          </TabList>
        </Box>
        <TabPanel value="laying">
          <UllagingLaying
            ullaging={ullaging}
          />
        </TabPanel>
        <TabPanel value="standing">
          <UllagingStanding
            ullagingStanding={ullagingStanding}
          />
        </TabPanel>
        <TabPanel value="truncatedCone">
          <TruncatedConeTank
            ullagingTruncatedCone={ullagingTruncatedCone}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
