import * as yup from 'yup';

export default function validationSchema(t) {
  return yup.object().shape({
    SpiritVolumeValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    SpiritABVValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(100, t('validations.positive_value')),
    SpiritSugarValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    MoutVolumeValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    MoutABVValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(100, t('validations.positive_value')),
    MoutSugarValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    PmVolumeValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    PmABVValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(100, t('validations.positive_value')),
    PmSugarValue: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
  });
}
