import React from 'react';
import Box from '@mui/material/Box';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Link,
} from 'react-router-dom';
import { SignIn, Contact } from 'labox-ws-commons';
import AboutUs from 'components/about-us/AboutUs';
import Experts from 'components/about-us/Experts';
import { useTranslation } from 'react-i18next';
import BoxettesMenu from 'components/boxettes/BoxettesMenu';
import Subscriptions from 'components/subscriptions/Subscriptions';
import useGlobal from 'global-state/store';
import MyAccount from 'components/account/MyAccount';
import Tips from 'components/tips/Tips';
import Admin from 'components/admin/Admin';
import Issuers from 'components/admin/issuers-and-codes/Issuers';
import DeeplinksHandler from 'components/deeplinks/DeeplinksHandler';
import Automated from 'components/automated/Automated';
import WPIFrame from 'components/WPIFrame';
import PDFView from 'components/PDFView';
import cguFR from 'files/CGU.pdf';
import cgvFR from 'files/CGV.pdf';
import cgucgvFR from 'files/CGU+CGV.pdf';
import {
  getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import privacyFR from 'files/privacy-policy-fr.pdf';
import privacyEN from 'files/privacy-policy-en.pdf';
import {
  useFirestore,
  useSigninCheck,
} from 'reactfire';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import oimlPublication from 'files/publi.pdf';
import LaboxWS from 'icons/LaboxWS';

export default function DrawerRoutes() {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const analytics = getAnalytics();
  const provider = new GoogleAuthProvider();
  const db = useFirestore();
  const { data: signInCheckResult } = useSigninCheck();
  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  function privacyPolicy() {
    if (t('locale') === 'fr') {
      return privacyFR;
    }
    return privacyEN;
  }

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Alcoométrie, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  return (
    <Routes>
      <Route path="/*" element={<BoxettesMenu />} />
      <Route
        path="signin"
        element={(
          <SignIn
            t={t}
            globalActions={globalActions}
            navigate={navigate}
            location={location}
            Link={Link}
            auth={auth}
            analytics={analytics}
            gtcuCookieName="agreed-to-general-terms-alcoholometry"
            ourSignInWithPopup={ourSignInWithPopup}
            signInWithEmailAndPassword={signInWithEmailAndPassword}
            createUserWithEmailAndPassword={createUserWithEmailAndPassword}
            sendEmailVerification={sendEmailVerification}
            logEvent={logEvent}
            signinCallback={() => globalActions.setUserStatus('initial')}
            LaboxWSIcon={LaboxWS}
          />
        )}
      />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="experts" element={<Experts />} />
      <Route
        path="labox-news"
        element={(
          <WPIFrame
            url=""
            height="3000px"
          />
                  )}
      />
      <Route
        path="tech-info"
        element={(
          <WPIFrame
            url="?page_id=25"
            height="3000px"
          />
        )}
      />
      <Route path="account" element={<MyAccount />} />
      <Route path="menu-account" element={<MyAccount />} />
      <Route path="subscriptions" element={<Subscriptions />} />
      <Route path="admin" element={<Admin />} />
      <Route path="issuers" element={<Issuers />} />
      <Route path="Tips" element={<Tips />} />
      <Route path="cgu" element={<PDFView path={cguFR} />} />
      <Route path="cgv" element={<PDFView path={cgvFR} />} />
      <Route path="cgu-cgv" element={<PDFView path={cgucgvFR} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="oiml-publication" element={<PDFView path={oimlPublication} />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
          )}
      />
      <Route
        path="menu-contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
          )}
      />
      <Route path="deeplink" element={<DeeplinksHandler />} />
      <Route path="boxettes/*" element={<BoxettesMenu />} />
      <Route path="automated" element={<Automated />} />
      <Route
        path="*"
        element={(
          <Box>
            <p>There is nothing here!</p>
          </Box>
              )}
      />
    </Routes>
  );
}
