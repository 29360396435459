import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function EnergeticUnitPicker(props) {
  const { setunit, value } = props;
  return (
    <FormControl variant="outlined">
      <Select
        style={{ width: 130 }}
        defaultValue={value}
        id="energetic-select"
        value={value}
        onChange={(e) => setunit(e.target.value)}
      >
        <MenuItem value="kcal">kCal</MenuItem>
        <MenuItem value="kJ">kJ</MenuItem>
      </Select>
    </FormControl>
  );
}
