import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSigninCheck } from 'reactfire';
import BoxetteCard from './BoxetteCard';

export default function FavoriteBoxettes(props) {
  const { t } = useTranslation();
  const { boxettes, dispatchBoxettes } = props;
  const { data: signInCheckResult } = useSigninCheck();

  const boxettesActivated = (disabled) => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === true
    && !disabled;

  const favoriteBoxettes = Object.keys(boxettes).filter((key) => boxettes[key].favorite);

  if (favoriteBoxettes.length <= 0) {
    return null;
  }
  return (
    <Box sx={{
      display: 'flex',
      flexFlow: 'column',
      gap: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    }}
    >
      <Typography component="span" variant="h6">
        {t('favorite_boxettes')}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        mb: 5,
        gap: { xs: 1, sm: 2 },
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
      }}
      >
        {favoriteBoxettes.map((key) => (
          <BoxetteCard
            key={key}
            title={boxettes[key].title}
            image={boxettes[key].image}
            activated={boxettesActivated(boxettes[key].disabled)}
            route={boxettes[key].route}
            dispatchBoxettes={dispatchBoxettes}
            isFavorite={boxettes[key].favorite}
            free={boxettes[key].free}
          />
        ))}
      </Box>
    </Box>
  );
}
