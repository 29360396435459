import React from 'react';
import { useTranslation } from 'react-i18next';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import CreditLoadingButton from 'components/CreditLoadingButton';
import {
  Autocomplete, FormControl, IconButton, TextField,
} from '@mui/material';
import useGlobal from 'global-state/store';
import InfoIcon from '@mui/icons-material/Info';
import decimalsMask from './DecimalsMask';
import RectifySO2Results from './RectifySO2Results';

export default function RectifySO2InnerForm({ fromActiveSO2 }) {
  const {
    formik, handleChange, handleBlur, calculating,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  const handleOpenInfo = (message) => async () => {
    globalActions.setSnackbarMessage(
      { message: t(message), severity: 'info' },
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 2,
        width: '100%',
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          flexGrow: 2,
        }}
      >
        {/* Volume In */}
        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.volume_value')}</Typography>
          }
          input={(
            <NumberInput
              name="volumeInValue"
              onChange={handleChange}
              placeholder="ex: 100"
              value={formik.values.volumeInValue}
              decimalScale={decimalsMask[formik.values.volumeInUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.volumeInUnit);
              }}
            />
          )}
          error={formik.touched.volumeInValue && formik.errors.volumeInValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.volumeInValue}
            </Typography>
          )}
          select={(
            <VolumeUnitPicker
              unit={formik.values.volumeInUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('volumeInUnit', newUnit);
              }}
            />
          )}
        />

        {/* Free SO2 Concentration In */}
        {!fromActiveSO2
        && (
          <LabelInputSelect
            label={
              <Typography component="span">{t('so2.free_initial')}</Typography>
            }
            input={(
              <NumberInput
                name="freeSO2ConcentrationInValue"
                onChange={handleChange}
                placeholder="ex: 10"
                value={formik.values.freeSO2ConcentrationInValue}
                decimalScale={decimalsMask[formik.values.freeSO2ConcentrationInUnit]}
                onBlur={(e) => {
                  handleBlur(e, formik.values.freeSO2ConcentrationInUnit);
                }}
              />
            )}
            error={formik.touched.freeSO2ConcentrationInValue && formik.errors.freeSO2ConcentrationInValue && (
              <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
                {formik.errors.freeSO2ConcentrationInValue}
              </Typography>
            )}
            select={(
              <Typography sx={{ width: 75 }}>
                mg / L
              </Typography>
            )}
          />
        )}

        {/* Free Or Active SO2 Concentration Out */}
        <LabelInputSelect
          label={(
            <Typography component="span">
              {fromActiveSO2
                ? t('so2.active_out_value')
                : `${t('so2.free_out_value')} [1]`}
            </Typography>
          )}
          input={(
            <NumberInput
              name="freeOrActiveSO2ConcentrationOutValue"
              onChange={handleChange}
              placeholder="ex: 30"
              value={formik.values.freeOrActiveSO2ConcentrationOutValue}
              decimalScale={decimalsMask[formik.values.freeOrActiveSO2ConcentrationOutUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.freeOrActiveSO2ConcentrationOutUnit);
              }}
            />
            )}
          error={formik.touched.freeOrActiveSO2ConcentrationOutValue
            && formik.errors.freeOrActiveSO2ConcentrationOutValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.freeOrActiveSO2ConcentrationOutValue}
            </Typography>
          )}
          select={(
            <Typography sx={{ width: 75 }}>
              mg / L
            </Typography>
            )}
        />

        {/* Total SO2 Concentration In */}
        <LabelInputSelect
          label={
            <Typography component="span">{t('so2.total_initial')}</Typography>
          }
          input={(
            <NumberInput
              name="totalSO2ConcentrationInValue"
              onChange={handleChange}
              placeholder="ex: 32"
              value={formik.values.totalSO2ConcentrationInValue}
              decimalScale={decimalsMask[formik.values.totalSO2ConcentrationInUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.totalSO2ConcentrationInUnit);
              }}
            />
          )}
          error={formik.touched.totalSO2ConcentrationInValue && formik.errors.totalSO2ConcentrationInValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.totalSO2ConcentrationInValue}
            </Typography>
          )}
          select={(
            <Typography sx={{ width: 75 }}>
              mg / L
            </Typography>
          )}
        />

        {/* Combined Rate */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 1,
          }}
        >
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', alignSelf: 'flex-start',
          }}
          >
            <Typography component="span">{t('so2.combined_rate')}</Typography>
            <IconButton
              onClick={handleOpenInfo(t('so2.combination_info'))}
            >
              <InfoIcon />
            </IconButton>
          </Box>

          <FormControl sx={{ backgroundColor: 'white' }}>

            <Autocomplete
              freeSolo
              disableClearable
              options={[66.7, 50]}
              id="combinedRateValue"
              name="combinedRateValue"
              value={formik.values.combinedRateValue}
              onChange={(_, newValue) => {
                formik.setFieldValue('combinedRateValue', newValue);
              }}
              getOptionLabel={(option) => (typeof (option) == 'number' ? String(option) : option)}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  autoComplete="off"
                  sx={{ backgroundColor: 'white', minWidth: 195 }}
                  variant="outlined"
                  value={formik.values.combinedRateValue}
                  error={formik.errors.combinedRateValue && formik.touched.combinedRateValue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.combinedRateValue
                    && formik.touched.combinedRateValue
                  }
                />
              )}
            />

          </FormControl>
        </Box>

        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={0}
          text={t('forms.calcul')}
        />
        {!fromActiveSO2 && (
        <Typography
          sx={{ textAlign: 'left', mt: 3 }}
          component="span"
          variant="caption"
        >
          {t('so2.free_out_info')}
          <br />
        </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          flexGrow: 1,
        }}
      >
        <RectifySO2Results fromActiveSO2={fromActiveSO2} />
      </Box>
    </Box>
  );
}
