const seoConfig = {
  default: {
    titleKey: 'seo.default_title',
    descriptionKey: 'seo.default_description',
    pathname: '/',
  },
  experts: {
    titleKey: 'seo.experts.title',
    descriptionKey: 'seo.experts.description',
    pathname: '/experts',
  },
};

export default seoConfig;
