import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import ResultCard from 'components/boxettes/ResultCard';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import CreditLoadingButton from 'components/CreditLoadingButton';
import validationTAVSchema from './ValidationTAVSchema';
import decimalsMask from './DecimalsMask';

export default function AlcoConversionToUSBoxette(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { convertABVToTemperature, actions } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, temperatureUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, globalState.userUnits.densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await convertABVToTemperature(
      {
        abvValue: values.measuredTav.replace(/,/, '.'),
        abvUnit: values.alcoholUnit,
        abvOutUnit: 'proofUS',
        temperatureInValue: values.measuredTemp.replace(/,/, '.'),
        temperatureOutValue: '60',
        temperatureInUnit: values.temperatureUnit,
        temperatureOutUnit: 'fahrenheit',
        abvPrecision: decimalsMask[values.alcoholUnit],
        temperatureInPrecision: decimalsMask[values.temperatureUnit],
      },
    );
    return results;
  };

  const defaultFormValues = {
    measuredTav: '',
    measuredTemp: '20',
    temperatureUnit: globalState.userUnits.temperatureUnit,
    alcoholUnit: globalState.userUnits.alcoholUnit,
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="alco_to_us_form"
      validationSchema={() => validationTAVSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
          actions={actions}
        />
)}
    />

  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.alco_us_conversion')}
      </Typography>
      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
              alcoholUnit: dynamicUnitName(formik.values.alcoholUnit),
              referenceTempValue: formik.values.measuredTemp,
              referenceTempUnit: dynamicUnitName(
                formik.values.temperatureUnit,
              ),
            })}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="measuredTav"
            placeholder="ex: 56.88"
            value={formik.values.measuredTav}
            decimalScale={decimalsMask[formik.values.alcoholUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.alcoholUnit);
            }}
          />
        )}
        error={formik.touched.measuredTav && formik.errors.measuredTav && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.measuredTav}
        </Typography>
        )}
      />
      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={1}
        text={t('forms.calcul')}
      />
      <ResultCard
        title={t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
          alcoholUnit: dynamicUnitName('proofUS'),
          referenceTempValue: '60',
          referenceTempUnit: dynamicUnitName(
            'fahrenheit',
          ),
        })}
        result={resultsAvailable() ? results.ABVAtTemp.value : '-'}
        infoButtonIcon={<span style={{ fontSize: 14 }}>[1]</span>}
        infoButtonText={t('forms.us_proof_info')}
      />
      <Typography
        sx={{ textAlign: 'left' }}
        component="span"
        variant="caption"
      >
        [1]
        {' '}
        {t('forms.us_proof_info')}
      </Typography>
    </form>
  );
}
