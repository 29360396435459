import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Box, TextField,
  Typography,
  Paper,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import { getUnitCategories } from './unitCategories';
import SelectedUnitPicker from './SelectUnitPicker';

const { unit: mathUnit } = require('mathjs');

const unitCategories = getUnitCategories();
const decimalScale = 6;

function UnitConversions({ convertUnit }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [, globalActions] = useGlobal();

  function areValidUnitExpressions(expression1, expression2) {
    try {
      const unit1 = mathUnit(expression1);
      const unit2 = mathUnit(expression2);

      // Check if the units are compatible for conversion
      unit1.to(unit2);
      return true;
    } catch (error) {
      return false;
    }
  }

  const formik = useFormik({
    initialValues: {
      fromUnit: { singleField: false, array: ['L'], field: '' },
      toUnit: { singleField: false, array: ['m3'], field: '' },
      fromValue: '',
      toValue: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (values.fromValue === '' && values.toValue === '') {
        setLoading(false);
        return;
      }

      if (values.fromUnit.singleField) {
        if (!areValidUnitExpressions(values.fromUnit.field, values.toUnit.field)) {
          setLoading(false);
          globalActions.setSnackbarMessage({ message: 'Impossible', severity: 'error' });
          return;
        }
      } else {
        const hasEmptyUnits = values.fromUnit.array.includes('') || values.toUnit.array.includes('');
        const differentLengths = values.fromUnit.array.length !== values.toUnit.array.length;
        const differentCategories = values.fromUnit.array.some((unit, index) => {
          const fromCategory = Object.keys(unitCategories).find(
            (category) => unitCategories[category].some((u) => u.value === unit),
          );
          const toCategory = Object.keys(unitCategories).find(
            (category) => unitCategories[category].some((u) => u.value === values.toUnit.array[index]),
          );
          return fromCategory !== toCategory;
        });

        if (hasEmptyUnits || differentLengths || differentCategories) {
          setLoading(false);
          globalActions.setSnackbarMessage({ message: 'Impossible', severity: 'error' });
          return;
        }
      }

      let parameters;
      if (values.fromValue !== '') {
        parameters = {
          valueIn: values.fromValue,
          unitIn: values.fromUnit.singleField ? values.fromUnit.field : values.fromUnit.array.join('/'),
          unitOut: values.toUnit.singleField ? values.toUnit.field : values.toUnit.array.join('/'),
          precisionIn: decimalScale,
        };
      } else {
        parameters = {
          valueIn: values.toValue,
          unitIn: values.toUnit.singleField ? values.toUnit.field : values.toUnit.array.join('/'),
          unitOut: values.fromUnit.singleField ? values.fromUnit.field : values.fromUnit.array.join('/'),
          precisionIn: decimalScale,
        };
      }
      try {
        const result = await convertUnit(parameters);
        if (values.fromValue === '') {
          formik.setFieldValue('fromValue', result.converted.value.toString());
        } else {
          formik.setFieldValue('toValue', result.converted.value.toString());
        }
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `t('unexpected_error') , erreur: ${error}`, severity: 'error' });
      } finally {
        setLoading(false);
      }
    },
  });

  const addUnit = () => {
    if (formik.values.fromUnit.array.length < 2) {
      formik.setFieldValue('fromUnit.array', [...formik.values.fromUnit.array, '']);
      formik.setFieldValue('toUnit.array', [...formik.values.toUnit.array, '']);
    }
  };

  const removeUnit = () => {
    if (formik.values.fromUnit.array.length > 1) {
      formik.setFieldValue('fromUnit.array', formik.values.fromUnit.array.slice(0, -1));
      formik.setFieldValue('toUnit.array', formik.values.toUnit.array.slice(0, -1));
    }
  };

  //   console.log(unit('1.2345', 'cm').to('m3').format({ precision: 2, notation: 'fixed' }));

  const handleValueChange = (field, value, sourceInfo) => {
    if (!sourceInfo.event) {
      return;
    }

    formik.setFieldValue(field, value);
    if (field === 'fromValue') {
      formik.setFieldValue('toValue', '');
    } else {
      formik.setFieldValue('fromValue', '');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        mx: 'auto',
        width: '100%',
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('boxettes.unit_conversions')}
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Box sx={{
          display: 'flex', gap: 1, flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: 'center',
        }}
        >
          <Box sx={{ width: 250 }}>
            <NumberFormat
              isNumericString
              allowNegative
              allowedDecimalSeparators={['.', ',']}
              decimalSeparator="."
              customInput={TextField}
              name="fromValue"
              value={formik.values.fromValue}
              onValueChange={(values, sourceInfo) => handleValueChange('fromValue', values.value, sourceInfo)}
              decimalScale={decimalScale}
              fullWidth
            />
          </Box>

          <SelectedUnitPicker
            formik={formik}
            formikKey="fromUnit"
            addUnit={addUnit}
            removeUnit={removeUnit}
            unitCategories={unitCategories}
          />
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
        >
          <SwapVertIcon />

        </LoadingButton>

        <Box sx={{
          display: 'flex', gap: 1, flexDirection: 'row', width: '100%', flexWrap: 'wrap', justifyContent: 'center',
        }}
        >
          <Box sx={{ width: 250 }}>
            <NumberFormat
              isNumericString
              allowNegative
              allowedDecimalSeparators={['.', ',']}
              decimalSeparator="."
              customInput={TextField}
              name="toValue"
              value={formik.values.toValue}
              onValueChange={(values, sourceInfo) => handleValueChange('toValue', values.value, sourceInfo)}
              decimalScale={decimalScale}
              fullWidth
            />
          </Box>
          <SelectedUnitPicker
            formik={formik}
            formikKey="toUnit"
            addUnit={addUnit}
            removeUnit={removeUnit}
            unitCategories={unitCategories}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default UnitConversions;
