import React from 'react';
import {
  useFunctions,
  useSigninCheck, useUser,
} from 'reactfire';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';

export default function BoxettesApiCalls(props) {
  const { boxette } = props;
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  // eslint-disable-next-line no-unused-vars
  const { statusAuth, data: signInCheckResult } = useSigninCheck();
  // eslint-disable-next-line no-unused-vars
  const { statusUser, data: user } = useUser();
  const analytics = getAnalytics();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const laboxAlcoholometryApiCall = httpsCallable(functions, 'laboxAlcoholometryApiCall2ndGen');

  async function apiCall(call, parameters) {
    logEvent(analytics, 'boxette_api_call', {
      call,
      appName: 'Alcoholometry',
      organization: globalState.activeOrganization,
      userUid: user?.uid,
    });
    try {
      const results = await laboxAlcoholometryApiCall({
        call,
        form: {
          intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
          ...parameters,
        },
        app: 'alcoholometry',
      });

      return results.data.result;
    } catch (err) {
      if (globalState.networkState === 'offline') {
        globalActions.setSnackbarMessage({
          message: t('offline'),
          severity: 'error',
        });
        return {};
      }
      handleApiError(err, call, parameters);
      return {};
    }
  }

  function handleApiError(err, call, parameters) {
    let parsedError;
    try {
      parsedError = JSON.parse(err.message);
    } catch {
      logEvent(analytics, 'boxette_api_call_unhandled_error', { error: err.message });
      globalActions.setSnackbarMessage({ message: err.message, severity: 'error' });
      return;
    }

    const handledErrors = ['IndexOutOfBoundsError', 'INDEX_OUT_OF_BOUNDS_ERROR',
      'HeaderOutOfBoundsError', 'HEADER_OUT_OF_BOUNDS_ERROR',
      'NotFoundError', 'NOT_FOUND_ERROR', 'ValueError', 'VALUE_ERROR',
      'SolveError', 'SOLVE_ERROR', 'DependentValueError', 'DEPENDENT_VALUES_ERROR'];
    if (parsedError && handledErrors.includes(parsedError.name)) {
      globalActions.setSnackbarMessage({
        message: `${t('error.handled_error_values')}\n\n${err.message}`,
        severity: 'warning',
      });
    } else if (parsedError && parsedError.name === 'NotEnoughCredits') {
      globalActions.setSnackbarMessage({ message: t('menu_boxettes.error_credits'), severity: 'error' });
    } else {
      logEvent(analytics, 'boxette_api_call_unhandled_error', { error: err.message });
      globalActions.setSnackbarMessage({ message: err.message, severity: 'error' });
    }
  }

  async function convertABVToTemperature(parameters) {
    return apiCall('convertABVToTemperature', parameters);
  }

  async function convertABVToTemperatureFromVolumetricMass(
    volumetricMassValue,
    volumetricMassUnit,
    temperatureInValue,
    abvTemperatureUnit,
    temperatureOutValue,
    alcoholByVolumeUnit,
    temperatureInPrecision: 2,
    temperatureOutPrecision: 2,
    densityPrecision: 2,
  ) {
    return apiCall('convertABVToTemperatureFromVolumetricMass', {
      volumetricMassValue,
      volumetricMassUnit,
      temperatureInValue,
      abvTemperatureUnit,
      temperatureOutValue,
      alcoholByVolumeUnit,
      temperatureInPrecision,
      temperatureOutPrecision,
      densityPrecision,
      intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
    });
  }

  async function ullaging(parameters) {
    return apiCall('ullaging', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function ullagingStanding(parameters) {
    return apiCall('ullagingStanding', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function ullagingTruncatedCone(parameters) {
    return apiCall('ullagingTruncatedCone', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function convertMassToVolumeFromTemp(parameters) {
    return apiCall('massVolumeConversion', parameters);
  }

  async function prepareProduct(parameters) {
    return apiCall('prepareProduct', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function rectifyExistingProduct(parameters) {
    return apiCall('rectifyExistingProduct', parameters);
  }

  async function convertVolumeToVolumeAtTemp(
    alcoholAt20cValue,
    alcoholByVolumeUnit,
    volumeValue,
    volumeUnit,
    temperatureInValue,
    temperatureOutValue,
    temperatureInUnit,
    temperatureOutUnit,
    abvPrecision,
    volumePrecision,
    temperatureInPrecision,
  ) {
    return apiCall('convertVolumeToVolumeAtTemp', {
      abvAt20cValue: alcoholAt20cValue,
      abvAt20cUnit: alcoholByVolumeUnit,
      volumeValue,
      volumeUnit,
      temperatureInValue,
      temperatureOutValue,
      temperatureInUnit,
      temperatureOutUnit,
      abvPrecision,
      volumePrecision,
      temperatureInPrecision,
      intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
    });
  }

  async function volumePAConversionToTemp(
    volumeAPAt20cValue,
    temperatureValue,
    abvAt20cValue,
    volumeAPAt20cUnit,
    temperatureUnit,
    abvAt20cUnit,
    volumeAPAt20cPrecision,
    temperaturePrecision,
    abvAt20cPrecision,
  ) {
    return apiCall('getVolumeAtTempFromAbvAndPureAlcoholAtTemperature', {
      volumeAPAt20cValue,
      temperatureValue,
      abvAt20cValue,
      volumeAPAt20cUnit,
      temperatureUnit,
      abvAt20cUnit,
      volumeAPAt20cPrecision,
      temperaturePrecision,
      abvAt20cPrecision,
      intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
    });
  }

  async function volumePAConversionWithDryExtract(parameters) {
    return apiCall('convertVolumePAWithDryExtract', parameters);
  }

  async function ingredientAdjustments(parameters) {
    return apiCall('ingredientAdjustments', parameters);
  }

  async function computeObscurationAndDryExtract(parameters) {
    return apiCall('computeObscurationAndDryExtract', parameters);
  }

  async function computeSampleProductAbv(parameters) {
    return apiCall('computeSampleProductAbv', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function energeticValue(parameters) {
    return apiCall('energeticValue', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function flashPoint(parameters) {
    return apiCall('flashPoint', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function convertActiveSO2(parameters) {
    return apiCall('convertActiveSO2', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function convertFreeSO2(parameters) {
    return apiCall('convertFreeSO2', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function rectifySO2(parameters) {
    return apiCall('rectifySO2', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function mutage(parameters) {
    return apiCall('mutage', {
      ...parameters,
      intermediateRounding: false,
    });
  }

  async function convertUnit(parameters) {
    return apiCall('convertUnit', {
      ...parameters,
      intermediateRounding: false,
      decimalRounding: true,
    });
  }

  return (
    <div>
      {React.cloneElement(boxette, {
        convertABVToTemperature,
        convertABVToTemperatureFromVolumetricMass,
        ullaging,
        ullagingStanding,
        ullagingTruncatedCone,
        convertMassToVolumeFromTemp,
        convertVolumeToVolumeAtTemp,
        volumePAConversionToTemp,
        volumePAConversionWithDryExtract,
        rectifyExistingProduct,
        prepareProduct,
        ingredientAdjustments,
        computeObscurationAndDryExtract,
        computeSampleProductAbv,
        energeticValue,
        flashPoint,
        convertActiveSO2,
        convertFreeSO2,
        rectifySO2,
        mutage,
        convertUnit,
      })}
    </div>
  );
}
