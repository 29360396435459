import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import useGlobal from 'global-state/store';
import { useFirestore, useFirestoreCollection, useUser } from 'reactfire';
import { collection, query, where } from 'firebase/firestore';
import ActiveSubscription from './ActiveSubscription';

export default function OldSubscriptions() {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const { data: user } = useUser();
  const db = useFirestore();

  const subscriptionsCollectionRef = globalState.activeOrganization === '' ? collection(
    db,
    'users',
    user?.uid,
    'apps',
    'alcoholometry',
    'subscriptions',
  )
    : collection(
      db,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'alcoholometry',
      'data',
      'sharedCredits',
      'subscriptions',
    );

  const activeSubsQuery = query(subscriptionsCollectionRef, where('status', '==', 'active'));

  const { data: activeSubsQuerySnapshot } = useFirestoreCollection(activeSubsQuery);

  const activeSubscriptions = activeSubsQuerySnapshot
    ? activeSubsQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) : [];

  if (activeSubscriptions.length <= 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography sx={{ flexGrow: 1 }} component="span" variant="h6">
        Abonnement ancien modèle :
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {activeSubscriptions.map((subscription) => (
          <ActiveSubscription key={subscription.id} subscription={subscription} />
        ))}
      </Box>
      ´
      <Button
        href="https://billing.stripe.com/p/login/7sIeXZ5Vrgi49dmfYY"
          // href="https://billing.stripe.com/p/login/test_9AQ00E8KZ8yu9IAbII"
        color="primary"
        variant="contained"
        endIcon={<ArrowRight />}
        size="large"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
          }}
        >
          <Typography componant="span">
            {t('subscriptions.manage_sub')}
          </Typography>
          <Typography componant="span" sx={{ fontSize: 12 }}>
            {t('subscriptions.consult_invoices')}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}
