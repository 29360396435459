import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import CreditLoadingButton from 'components/CreditLoadingButton';
import ResultCard from 'components/boxettes/ResultCard';
import Box from '@mui/material/Box';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';

export default function EbulliometerBoxette(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { computeSampleProductAbv } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, temperatureUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, globalState.userUnits.densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await computeSampleProductAbv(
      {
        referenceProductAbvAt20cValue: values.referenceProductAbvAt20cValue.replace(/,/, '.'),
        referenceProductBoilingPointValue: values.referenceProductBoilingPointValue.replace(/,/, '.'),
        sampleProductBoilingPointValue: values.sampleProductBoilingPointValue.replace(/,/, '.'),
      },
    );
    return results;
  };

  // This boxette only is done for TAV at 20°C, could be updated to use other ABV and
  // temperature unit but has to be verified
  const defaultFormValues = {
    referenceProductAbvAt20cValue: '',
    referenceProductBoilingPointValue: '',
    sampleProductBoilingPointValue: '',
    temperatureValue: '20',
    temperatureUnit: 'celsius',
    abvUnit: 'TAV',
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="ebulliometer_form"
      validationSchema={() => validationSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
        />
      )}
    />

  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 500,
      }}
    >
      <Typography variant="h4" component="span">
        {t('boxettes.ebulliometer')}
      </Typography>
      <Typography variant="h6" component="span">
        {t('ebulliometer.reference_wine_point')}
      </Typography>
      <LabelInputSelect
        label={(
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography component="span">
              {t('forms.tav_at', {
                tempValue: formik.values.temperatureValue,
                tempUnit: dynamicUnitName(formik.values.temperatureUnit),
              })}
            </Typography>
            <IconButton
              onClick={() => globalActions.setSnackbarMessage({
                message: t('ebulliometer.tav_info'),
                severity: 'info',
              })}
              sx={{ color: 'primary.main' }}
            >
              <Info sx={{ fontSize: 20, p: 0, m: 0 }} />
            </IconButton>

          </Box>

        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="referenceProductAbvAt20cValue"
            placeholder="ex: 11"
            value={formik.values.referenceProductAbvAt20cValue}
            decimalScale={decimalsMask[formik.values.abvUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.abvUnit);
            }}
          />
        )}
        error={formik.touched.referenceProductAbvAt20cValue && formik.errors.referenceProductAbvAt20cValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.referenceProductAbvAt20cValue}
        </Typography>
        )}
        select={(
          <Typography component="span">
            {dynamicUnitName(formik.values.abvUnit)}
          </Typography>
        )}
      />

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_boiling_temp')}</Typography>
        }
        input={(
          <NumberInput
            name="referenceProductBoilingPointValue"
            onChange={handleChange}
            placeholder="ex: 91.5"
            value={formik.values.referenceProductBoilingPointValue}
            decimalScale={decimalsMask[formik.values.temperatureUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.temperatureUnit);
            }}
            allowNegative
          />
        )}
        error={formik.touched.referenceProductBoilingPointValue && formik.errors.referenceProductBoilingPointValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.referenceProductBoilingPointValue}
        </Typography>
        )}
        select={(
          <Typography component="span">
            {dynamicUnitName(formik.values.temperatureUnit)}
          </Typography>
        )}
      />

      <Typography variant="h6" component="span">
        {t('ebulliometer.sample_product')}
      </Typography>

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_boiling_temp')}</Typography>
        }
        input={(
          <NumberInput
            name="sampleProductBoilingPointValue"
            onChange={handleChange}
            placeholder="ex: 89.2"
            value={formik.values.sampleProductBoilingPointValue}
            decimalScale={decimalsMask[formik.values.temperatureUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.temperatureUnit);
            }}
            allowNegative
          />
        )}
        error={formik.touched.sampleProductBoilingPointValue && formik.errors.sampleProductBoilingPointValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.sampleProductBoilingPointValue}
        </Typography>
        )}
        select={(
          <Typography component="span">
            {dynamicUnitName(formik.values.temperatureUnit)}
          </Typography>
        )}
      />
      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={0}
        text={t('forms.calcul')}
      />
      <ResultCard
        title={t('forms.tav_at', {
          tempValue: formik.values.temperatureValue,
          tempUnit: dynamicUnitName(formik.values.temperatureUnit),
        })}
        result={resultsAvailable()
          ? `${results.sampleProductTav.value} ${dynamicUnitName(results.sampleProductTav.unit)}`
          : '-'}
      />
    </Box>
  );
}
