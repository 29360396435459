import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function FavoriteUnitsSelection(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [unitsConfig, setunitsConfig] = React.useState(globalState.userUnits);

  const handleUnitsConfigChange = (event, newConfigName) => {
    let newUnitsConfig;
    if (newConfigName !== null) {
      if (newConfigName === 'eu') {
        newUnitsConfig = {
          name: 'eu',
          volumeUnit: 'L',
          temperatureUnit: 'celsius',
          temperatureValue: '20',
          lengthUnit: 'cm',
          massUnit: 'kg',
          alcoholUnit: 'TAV',
          densityUnit: 'g / L',
        };
      } else if (newConfigName === 'us') {
        newUnitsConfig = {
          name: 'us',
          volumeUnit: 'barrelUS',
          temperatureUnit: 'fahrenheit',
          temperatureValue: '60',
          lengthUnit: 'in',
          massUnit: 'kg',
          alcoholUnit: 'Proof',
          densityUnit: 'g / L',
        };
      } else if (newConfigName === 'uk') {
        newUnitsConfig = {
          name: 'uk',
          volumeUnit: 'barrelUK',
          temperatureUnit: 'fahrenheit',
          temperatureValue: '60',
          lengthUnit: 'in',
          massUnit: 'kg',
          alcoholUnit: 'Proof',
          densityUnit: 'g / L',
        };
      } else if (newConfigName === 'jp') {
        newUnitsConfig = {
          name: 'jp',
          volumeUnit: 'barrelUS',
          temperatureUnit: 'fahrenheit',
          temperatureValue: '60',
          lengthUnit: 'in',
          massUnit: 'kg',
          alcoholUnit: 'Proof',
          densityUnit: 'g / L',
        };
      }
      setunitsConfig(newUnitsConfig);
      globalActions.setUserUnits(newUnitsConfig);
      localStorage.setItem('user_units_config', JSON.stringify(newUnitsConfig));
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Typography component="span" variant="h6">
        {t('units.unit_system')}
      </Typography>

      <ToggleButtonGroup
        value={unitsConfig.name}
        exclusive
        onChange={handleUnitsConfigChange}
        aria-label="text alignment"
      >
        <ToggleButton size="small" value="eu" aria-label="centered">
          EU
        </ToggleButton>
        <ToggleButton disabled size="small" value="us" aria-label="centered">
          US
        </ToggleButton>
        <ToggleButton disabled size="small" value="uk" aria-label="centered">
          UK
        </ToggleButton>
        <ToggleButton disabled size="small" value="jp" aria-label="centered">
          JPN
        </ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 1,
      }}
      >
        <Typography component="span">
          {`${t('units.alcohol')}: ${dynamicUnitName(unitsConfig.alcoholUnit)}` }
        </Typography>
        <Typography component="span">
          {`${t('units.volume')}: ${unitsConfig.volumeUnit}` }
        </Typography>
        <Typography component="span">
          {`${t('units.density')}: ${unitsConfig.densityUnit}` }
        </Typography>
        <Typography component="span">
          {`${t('units.mass')}: ${unitsConfig.massUnit}` }
        </Typography>
        <Typography component="span">
          {`${t('units.length')}: ${unitsConfig.lengthUnit}` }
        </Typography>
        <br />
        <Typography component="span">
          {t('units.ref_temperature')}
          :
        </Typography>
        <Typography component="span">
          {`${t('units.unit')}: ${unitsConfig.temperatureUnit}` }
        </Typography>
        <Typography component="span">
          {`${t('units.value')}: ${unitsConfig.temperatureValue}` }
        </Typography>
      </Box>

    </Paper>
  );
}
