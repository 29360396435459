import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import Point from './Point';

export default function VSCard({ loadingStatus, handleCheckout }) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  return (

    <Paper sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 300,
      height: { xs: '', md: 550 },
      pb: 3,
    }}
    >
      <Box sx={{
        width: '100%',
        backgroundColor: 'primary.main',
        borderRadius: 1,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Typography sx={{ fontWeight: 'bold', color: 'white', mb: 2 }} variant="h5"> VS </Typography>
        <Typography sx={{ color: 'white' }}>
          <span style={{ fontWeight: 'bold' }}>
            {`4.99€ / ${t('month')}`}
          </span>
          {` ${t('subscriptions.excl_tax')}`}
        </Typography>
        <Typography variant="caption" sx={{ color: '#d6d6d6' }}>
          {`59.88€ ${t('subscriptions.excl_tax')} ${t('subscriptions.billed_yearly')} `}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: 250,
        alignItems: 'flex-start',
        py: 3,
        px: 2,
        flexGrow: 1,
      }}
      >
        <Point text={t('subscriptions.boxettes_access')} />
        <Point text={t('subscriptions.individual_subscription')} />
      </Box>
      {globalState.activeOrganization ? (
        <Typography sx={{ width: 245 }}>
          {t('subscriptions.only_on_personal_account')}
        </Typography>
      ) : (
        <LoadingButton
          sx={{ textTransform: 'none' }}
          loading={loadingStatus === 'loading'}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => handleCheckout('vs')}
        >
          {t('start_now')}
        </LoadingButton>
      )}
    </Paper>
  );
}
