import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useGlobal from 'global-state/store';
import {
  useSigninCheck,
} from 'reactfire';
import { getAnalytics, logEvent } from 'firebase/analytics';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import usePrevious from 'customHooks/usePrevious';

export default function DeeplinksHandler() {
  const [, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const { t } = useTranslation();
  const [pageStatus, setPageStatus] = useState('initial');
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const [deeplink, setdeeplink] = useState({});
  const prevUserStatus = usePrevious(pageStatus);

  // EXAMPLE OF DEEPLINK
  // eslint-disable-next-line max-len
  // https://alcoholometry.labox-apps.com/deeplink?type=volumeConversion&hookName=DigiTank+GC&hookUrl=https%3A%2F%2Fgc.digitank.labox-apps.com%2Foperation%3FtankPath%3Dclients%252FtestClient%252Fcellars%252FC-01%252Ftanks%252FCLIM%2B1%26form%3D%257B%2522volumeToAdd%2522%253A%257B%2522value%2522%253A%252211%2522%252C%2522error%2522%253Anull%257D%252C%2522fields%2522%253A%257B%2522volume%2522%253A%257B%2522error%2522%253Anull%252C%2522value%2522%253A%252244%2522%257D%252C%2522height%2522%253A%257B%2522error%2522%253Anull%252C%2522value%2522%253A%2522596%2522%257D%257D%252C%2522results%2522%253A%257B%2522height%2522%253A%2522788%2522%252C%2522volume%2522%253A%252255.00%2522%257D%252C%2522nominalVolume%2522%253A207640%252C%2522volumeChange%2522%253A%2522add%2522%257D&volumeUnit=hL&volume=55.00&volumeUnit=hL

  const shouldRunEffect = useCallback(
    (newState) => (prevUserStatus === 'initial' && newState === 'initial'),
    [prevUserStatus],
  );

  useEffect(() => {
    if (!shouldRunEffect(pageStatus)) {
      return;
    }

    const queryParameters = new URLSearchParams(window.location.search);

    const goToVolume = (volume, unit) => navigate('/boxettes/vol-conv', {
      replace: true,
      state: {
        volumeValue: volume,
        volumeUnit: unit,
      },
    });

    try {
      const type = queryParameters.get('type');
      if (!type) {
        throw new Error('missing type parameter');
      }

      if (signInCheckResult?.signedIn === false) {
        setPageStatus('showSignIn');
      } else if (type === 'volumeConversion') {
        const volume = queryParameters.get('volume');
        const volumeUnit = queryParameters.get('volumeUnit');
        const hookUrl = queryParameters.get('hookUrl');
        const hookName = queryParameters.get('hookName');
        if (!volume || !volumeUnit) {
          throw new Error('missing volume or volumeUnit parameter');
        }
        const newDeeplink = {
          type, volume, volumeUnit, time: new Date(), hookUrl, hookName,
        };
        setdeeplink(newDeeplink);
        globalActions.setCurrentDeeplink(newDeeplink);
        sessionStorage.setItem('current_deeplink', JSON.stringify(newDeeplink));
        goToVolume(volume, volumeUnit);
      } else if (type === 'volumePAConversion') {
        const volume = queryParameters.get('volume') || '';
        const volumeUnit = queryParameters.get('volumeUnit') || '';
        const hookUrl = queryParameters.get('hookUrl');
        const hookName = queryParameters.get('hookName');
        const deeplinkReturnId = queryParameters.get('deeplinkReturnId');
        const newDeeplink = {
          type, volume, volumeUnit, time: new Date(), hookUrl, hookName, deeplinkReturnId,
        };
        setdeeplink(newDeeplink);
        globalActions.setCurrentDeeplink(newDeeplink);
        sessionStorage.setItem('current_deeplink', JSON.stringify(newDeeplink));
        goToVolume(volume, volumeUnit);
      } else {
        throw new Error('Unknown type');
      }
    } catch (err) {
      logEvent(analytics, 'deeplinks_error', {
        error_message: err.message,
      });
      globalActions.setSnackbarMessage({ message: t('deeplinks.cant_parse'), severity: 'error' });
      setPageStatus('error');
    }
  }, [analytics, globalActions, navigate, pageStatus, shouldRunEffect, signInCheckResult?.signedIn, t]);

  return (
    <Box
      sx={{
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PageContent pageStatus={pageStatus} deeplink={deeplink} />
    </Box>
  );
}

function PageContent(props) {
  const { pageStatus, deeplink } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deeplinkName = () => (deeplink.hookName ? deeplink.hookName : t('deeplinks.default_hook_name'));

  if (pageStatus === 'initial') {
    return (
      <CircularProgress />
    );
  } if (pageStatus === 'showSignIn') {
    return (
      <Box sx={{
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
      >
        <Typography component="span" variant="h5">
          {t('please_sign_in_for_func')}
        </Typography>
        <Typography component="span">
          {t('deeplinks.login_info', { hookName: deeplinkName() })}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate('/signin', {
            state: {
              afterSignInRedirect: window.location.pathname + window.location.search,
            },
          })}
          startIcon={<AccountCircle />}
        >
          {t('deeplinks.login_or_create')}
        </Button>
      </Box>
    );
  }
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={() => {
        navigate('/boxettes');
      }}
    >
      <Typography component="span">{t('boxettes.return_menu')}</Typography>
    </Button>
  );
}
