import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    mvValidationPhrase: t('validations.mv_validation', {
      mvMin: alcoholUnitRelateds.mvMin,
      mvMax: alcoholUnitRelateds.mvMax,
      densityUnit: dynamicUnitName(alcoholUnitRelateds.densityUnit),
    }),
    dryExtractValidationPhrase: t('validations.dry_extract_validation', {
      densityMin: 0.1,
      densityMax: 100,
      densityUnit: dynamicUnitName(alcoholUnitRelateds.densityUnit),
    }),
  };

  return yup.object().shape({
    isMVSelected: yup.boolean(),
    isRealTAVSelected: yup.boolean(),
    mvValue: yup
      .number()
      .when('isMVSelected', {
        is: true,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.mvValidationPhrase)
          .required(validationPhrases.mvValidationPhrase)
          .min(alcoholUnitRelateds.mvMin, validationPhrases.mvValidationPhrase)
          .max(alcoholUnitRelateds.mvMax, validationPhrases.mvValidationPhrase),
        otherwise: (schema) => schema,
      }),
    rawTAVValue: yup
      .number()
      .when('isMVSelected', {
        is: false,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.tavValidationPhrase)
          .required(validationPhrases.tavValidationPhrase)
          .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
          .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
        otherwise: (schema) => schema,
      }),
    realTAVValue: yup
      .number()
      .when('isRealTAVSelected', {
        is: true,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.tavValidationPhrase)
          .required(validationPhrases.tavValidationPhrase)
          .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
          .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
        otherwise: (schema) => schema,
      }),
    dryExtractValue: yup
      .number()
      .when('isRealTAVSelected', {
        is: false,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.dryExtractValidationPhrase)
          .required(validationPhrases.dryExtractValidationPhrase)
          .min(0.1, validationPhrases.dryExtractValidationPhrase)
          .max(100, validationPhrases.dryExtractValidationPhrase),
        otherwise: (schema) => schema,
      }),
  });
}
