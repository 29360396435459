import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationMVSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    mvValidationPhrase: t('validations.mv_validation', {
      mvMin: alcoholUnitRelateds.mvMin,
      mvMax: alcoholUnitRelateds.mvMax,
      densityUnit: dynamicUnitName(alcoholUnitRelateds.densityUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  return yup.object().shape({
    temperatureUnit: yup.string().required(),
    measuredMV: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.mvValidationPhrase)
      .required(validationPhrases.mvValidationPhrase)
      .min(alcoholUnitRelateds.mvMin, validationPhrases.mvValidationPhrase)
      .max(alcoholUnitRelateds.mvMax, validationPhrases.mvValidationPhrase),
    measuredTemp: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
  });
}
