import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useGlobal from 'global-state/store';
import { getOrCleanupDeeplink } from './deeplinkUtils';

export default function BackToHookButton() {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    getOrCleanupDeeplink(globalActions);
  }, [globalActions]);

  const handleClick = () => {
    window.location.assign(globalState.currentDeeplink.hookUrl);
  };

  const hookName = () => (globalState.currentDeeplink.hookName
    ? globalState.currentDeeplink.hookName : t('deeplinks.default_hook_name'));

  if (globalState.currentDeeplink) {
    return (
      <Button
        sx={{ mt: 3 }}
        color="secondary"
        variant="contained"
        onClick={handleClick}
      >
        <Typography component="span">{t('deeplinks.back_to', { hookName: hookName() })}</Typography>
      </Button>
    );
  }
  return (
    <div />
  );
}
