import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function ActiveSubscription(props) {
  const { subscription } = props;
  const { t } = useTranslation();

  const subscriptionActivePrice = subscription.prices.find((p) => p.active === true);

  const titleForKey = {
    'law-vs': `Labox ${t('menu_boxettes.alcoholometry')} - VS`,
    'law-vsop': `Labox ${t('menu_boxettes.alcoholometry')} - VSOP`,
    'law-xo': `Labox ${t('menu_boxettes.alcoholometry')} - XO`,
  };

  const priceTitle = titleForKey[subscriptionActivePrice.key] || '';

  return (
    <Card
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Typography sx={{ flexGrow: 1 }} component="span">
        {priceTitle}
      </Typography>
      {subscription.cancelAt && (
      <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
        <Typography color="red" component="span" variant="subtitle2">
          {t('subscriptions.cancel_at')}
          {': '}
        </Typography>
        {subscription.cancelAt.toDate().toLocaleDateString(t('locale'), {
          year: 'numeric', month: 'long', day: 'numeric',
        })}
      </Typography>
      )}
      {subscription.cancelAt === null && (
        <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
          <Typography color="primary" component="span" variant="subtitle2">
            {t('subscriptions.renew_at')}
            {': '}
          </Typography>
          {subscription.currentPeriodEnd.toDate().toLocaleDateString(t('locale'), {
            year: 'numeric', month: 'long', day: 'numeric',
          })}
        </Typography>
      )}
      {subscription.code
              && (
              <Typography sx={{ flexGrow: 1 }} component="span" variant="subtitle2">
                {`${t('codes.activated_code')}: ${subscription.code}`}
              </Typography>
              )}
    </Card>
  );
}
