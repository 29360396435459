import React, { useState } from 'react';
import {
  Typography,
  Paper,
  IconButton,
  Box,
} from '@mui/material';
import Info from '@mui/icons-material/Info';
import CalculateIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useGlobal from 'global-state/store';

export default function ResultCard(props) {
  const {
    width,
    maxWidth, title, infoButtonText,
    result, actions, infoButtonIcon, color = 'ourGreen.main', textColor = 'white',
  } = props;
  const myWidth = width || '100%';
  const myMaxWidth = maxWidth || '100%';
  const [actionsOpen, setactionsOpen] = useState(false);
  const [actionsAnchorEl, setactionsAnchorEl] = useState(null);
  const [, globalActions] = useGlobal();

  const clickActionsMenu = (event) => {
    setactionsAnchorEl(event.currentTarget);
    setactionsOpen(true);
  };

  return (
    <Paper
      sx={{
        backgroundColor: color,
        width: myWidth,
        maxWidth: myMaxWidth,
        display: 'flex',
        flexDirection: 'column',
        px: 2,
        py: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{ color: textColor, width: '100%', textAlign: 'left' }}
        >
          {title}
        </Typography>
        {infoButtonText && (
        <IconButton
          onClick={() => globalActions.setSnackbarMessage(
            { message: infoButtonText, severity: 'info' },
          )}
          sx={{ color: 'white', flexShrink: 0 }}
        >
          {infoButtonIcon || <Info sx={{ fontSize: 16, p: 0, m: 0 }} />}
        </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 40,
        }}
      >
        <Typography
          component="span"
          sx={{ color: textColor, fontWeight: 'bold', width: '100%' }}
        >
          {result}
        </Typography>
        {actions && (
        <IconButton
          onClick={clickActionsMenu}
          sx={{ color: 'white', flexShrink: 0 }}
        >
          <CalculateIcon actions={actions} />
        </IconButton>
        )}
      </Box>
      {actions && (
        <ActionsMenu
          actionsOpen={actionsOpen}
          setactionsOpen={setactionsOpen}
          actions={actions}
          actionsAnchorEl={actionsAnchorEl}
          setactionsAnchorEl={setactionsAnchorEl}
        />
      )}
    </Paper>
  );
}

function ActionsMenu(props) {
  const {
    actionsOpen,
    setactionsOpen,
    actions,
    setactionsAnchorEl,
    actionsAnchorEl,
  } = props;

  const handleClose = () => {
    setactionsOpen(false);
    setactionsAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={actionsAnchorEl}
      open={actionsOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {actions.map((action, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={i} onClick={action.callback}>
          {action.title}
        </MenuItem>
      ))}
    </Menu>
  );
}
