import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function BackupNameDialog(props) {
  const {
    operation,
    formNameInStorage,
    dispatchBackupsOperations,
    open,
    setOpen,
  } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
        {t('save')}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ name: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = t('validations.required');
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatchBackupsOperations({
              type: 'add',
              payload: { operation: { ...operation, name: values.name }, formNameInStorage },
            });
            setOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                mt: 1,
                gap: 1,
              }}
            >
              <TextField
                style={{ maxWidth: 200 }}
                size="small"
                fullWidth
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={t('name')}
                error={touched.name && Boolean(errors.name)}
                helperText={errors.name && touched.name && errors.name}
              />
              <Button size="small" variant="contained" type="submit">
                ok
              </Button>
            </Box>
          )}
        </Formik>
      </DialogContent>
    </Dialog>

  );
}
