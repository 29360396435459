import React, { useState } from 'react';
import {
  TextField,
  Paper,
  Autocomplete,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertFirestoreTimestampToDate } from 'components/subscriptions/utils';
import AccessGrantsGrid from './AccessGrantsGrid';

function ManageAccessGrants() {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const addAccessGrantsCall = httpsCallable(functions, 'addAccessGrantsCall');
  const getUserGrantAccessesCall = httpsCallable(functions, 'getAccessGrantsCall');
  const [, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);

  const [showAccesses, setShowAccesses] = useState(false);
  const [displayedAccesses, setDisplayedAccesses] = useState([]);

  const [accesses, setAccesses] = useState([]);
  const [isUser, setIsUser] = useState(true);
  const [identifier, setIdentifier] = useState('');
  const [apps, setApps] = useState([]);
  const [expirationDate, setExpirationDate] = useState('');

  const handleAccessChange = (event, newValue) => {
    setAccesses(newValue);
  };

  const handleAppsChange = (event, newValue) => {
    setApps(newValue);
  };
  const handleAddAccesses = async (event) => {
    event.preventDefault();

    if (!expirationDate || !apps.length || !identifier || !accesses.length) {
      globalActions.setSnackbarMessage({
        // eslint-disable-next-line max-len
        message: 'Veuillez remplir tous les champs requis (email ou nom de l\'organisation, date d\'expiration, applications, identifiant, accès).',
        severity: 'error',
      });
      return;
    }

    setLoading(true);

    try {
      await addAccessGrantsCall({
        isUser,
        identifier,
        expirationDate: expirationDate.toString(),
        apps,
        accesses,
      });
      globalActions.setSnackbarMessage({ message: 'succès', severity: 'success' });
    } catch (error) {
      globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Function to check if an object is a serialized Firestore Timestamp
  const isSerializedFirestoreTimestamp = (value) => value
    && typeof value === 'object' && '_seconds' in value && '_nanoseconds' in value;

  // Recursive function to convert all serialized Firestore Timestamps in an object
  const convertTimestamps = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(convertTimestamps);
    } if (obj && typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (isSerializedFirestoreTimestamp(obj[key])) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = convertFirestoreTimestampToDate(obj[key]).toLocaleDateString(); // Convert and format date
        } else if (obj[key] instanceof Object) {
          convertTimestamps(obj[key]); // Recurse into sub-objects
        }
      });
    }
    return obj;
  };

  const handleGetAccesses = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const result = await getUserGrantAccessesCall({
        isUser,
        identifier,
      });
      setShowAccesses(true);

      const parsedAccesses = result.data.map((access) => convertTimestamps(access));

      setDisplayedAccesses(parsedAccesses);
    } catch (error) {
      globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
      elevation={0}
    >
      <Typography variant="h6">
        Gestion des AccessGrants
      </Typography>
      <ToggleButtonGroup
        value={isUser}
        exclusive
        onChange={(event, newAlignment) => {
          if (newAlignment !== null) {
            setIsUser(newAlignment);
          }
        }}
      >
        <ToggleButton value aria-label="left aligned">
          Utilisateur
        </ToggleButton>
        <ToggleButton value={false} aria-label="centered">
          Organisation
        </ToggleButton>
      </ToggleButtonGroup>
      <TextField
        label={isUser ? 'Email utilisateur' : "Nom de l'organisation"}
        value={identifier}
        onChange={(event) => setIdentifier(event.target.value)}
        required
      />
      <LoadingButton loading={loading} onClick={handleGetAccesses} variant="outlined" color="secondary">
        Voir les accès
      </LoadingButton>
      {showAccesses && (
        <AccessGrantsGrid accessGrants={displayedAccesses} />
      )}
      <Typography sx={{ mt: 2 }} variant="h6">
        Ajouter les accès :
      </Typography>
      <Autocomplete
        sx={{ width: 300 }}
        multiple
        freeSolo
        options={['law-vs', 'law-vsop', 'law-xo']}
        onChange={handleAccessChange}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label="Grants"
            placeholder="Ajouter"
            required
          />
        )}
      />
      <Autocomplete
        sx={{ width: 300 }}
        multiple
        freeSolo
        options={['alcoholometry', 'inventory', 'digitank-tanker-trucks', 'digitank-gauged-containers']}
        onChange={handleAppsChange}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label="applications"
            required
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Date d'expiration"
          value={expirationDate}
          onChange={(newValue) => {
            setExpirationDate(newValue);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} required />}
        />
      </LocalizationProvider>
      <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleAddAccesses}>
        Ajouter les accès
      </LoadingButton>
    </Paper>
  );
}

export default ManageAccessGrants;
