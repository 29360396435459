import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CreditLoadingButton from 'components/CreditLoadingButton';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import useGlobal from '../../../global-state/store';
import {
  NumberInput,
  RadioBoxetteInput,
} from '../BoxetteInputs';
import DensityUnitPicker from '../../units/DensityUnitPicker';
import AlcoholUnitPicker from '../../units/AlcoholUnitPicker';
import decimalsMask from './DecimalsMask';
import DryExtractResults from './DryExtractResults';

export default function DryExtractForm(props) {
  const {
    formik, handleChange, handleBlur, calculating,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const { setAlcoholUnitRelatedsFrom } = props;
  const [globalState] = useGlobal();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 3,
          maxWidth: { xs: '100%', sm: 350 },
        }}
      >
        <RadioBoxetteInput
          isselected={formik.values.isMVSelected}
          onFocused={() => formik.setFieldValue('isMVSelected', true)}
          label={(
            <Typography component="span">
              {t('forms.volumetric_mass_at_temp')}
              {globalState.userUnits.temperatureValue}
              {' '}
              {dynamicUnitName(globalState.userUnits.temperatureUnit)}
            </Typography>
        )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="mvValue"
              placeholder="ex: 801.22"
              value={formik.values.mvValue}
              decimalScale={decimalsMask[formik.values.densityUnit]}
              onBlur={(e) => {
                formik.setFieldValue('rawTAVValue', '');
                handleBlur(e, formik.values.densityUnit);
              }}
            />
        )}
          error={
          formik.touched.mvValue
          && formik.errors.mvValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.mvValue}
            </Typography>
          )
        }
          select={(
            <DensityUnitPicker
              unit={formik.values.densityUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  formik.values.tavUnit,
                  newUnit,
                );
                await formik.setFieldValue('densityUnit', newUnit);
              }}
            />
        )}
        />

        <RadioBoxetteInput
          isselected={!formik.values.isMVSelected}
          onFocused={() => formik.setFieldValue('isMVSelected', false)}
          label={(
            <Typography component="span">
              {t('forms.tav_at_ref_alcohol_and_ref_temp', {
                referenceTempValue: globalState.userUnits.temperatureValue,
                referenceTempUnit: dynamicUnitName(globalState.userUnits.temperatureUnit),
              })}
            </Typography>
        )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="rawTAVValue"
              placeholder="ex: 31.2"
              value={formik.values.rawTAVValue}
              decimalScale={decimalsMask[formik.values.tavUnit]}
              onBlur={(e) => {
                formik.setFieldValue('mvValue', '');
                handleBlur(e, formik.values.tavUnit);
              }}
            />
        )}
          error={
          formik.touched.rawTAVValue
          && formik.errors.rawTAVValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.rawTAVValue}
            </Typography>
          )
        }
          select={(
            <AlcoholUnitPicker
              unit={formik.values.tavUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  newUnit,
                  formik.values.densityUnit,
                );
                await formik.setFieldValue('tavUnit', newUnit);
              }}
            />
        )}
        />

        <RadioBoxetteInput
          isselected={formik.values.isRealTAVSelected}
          onFocused={() => formik.setFieldValue('isRealTAVSelected', true)}
          label={(
            <Typography component="span">
              {t('forms.real_tav_at_ref_temp', {
                referenceTempValue: globalState.userUnits.temperatureValue,
                referenceTempUnit: dynamicUnitName(globalState.userUnits.temperatureUnit),
              })}
            </Typography>
        )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="realTAVValue"
              placeholder="ex: 34.5"
              value={formik.values.realTAVValue}
              decimalScale={decimalsMask[formik.values.tavUnit]}
              onBlur={(e) => {
                formik.setFieldValue('dryExtractValue', '');
                handleBlur(e, formik.values.densityUnit);
              }}
            />
        )}
          error={
          formik.touched.realTAVValue
          && formik.errors.realTAVValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.realTAVValue}
            </Typography>
          )
        }
          select={(
            <AlcoholUnitPicker
              unit={formik.values.tavUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  newUnit,
                  formik.values.densityUnit,
                );
                await formik.setFieldValue('tavUnit', newUnit);
              }}
            />
        )}
        />

        <RadioBoxetteInput
          isselected={!formik.values.isRealTAVSelected}
          onFocused={() => formik.setFieldValue('isRealTAVSelected', false)}
          label={(
            <Typography component="span">
              {t('forms.dry_extract')}
            </Typography>
        )}
          input={(
            <NumberInput
              onChange={handleChange}
              name="dryExtractValue"
              placeholder="ex: 45"
              value={formik.values.dryExtractValue}
              decimalScale={decimalsMask[formik.values.densityUnit]}
              onBlur={(e) => {
                formik.setFieldValue('realTAVValue', '');
                handleBlur(e, formik.values.densityUnit);
              }}
            />
        )}
          error={
          formik.touched.dryExtractValue
          && formik.errors.dryExtractValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.dryExtractValue}
            </Typography>
          )
        }
          select={(
            <DensityUnitPicker
              unit={formik.values.densityUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  formik.values.tavUnit,
                  newUnit,
                );
                await formik.setFieldValue('densityUnit', newUnit);
              }}
            />
        )}
        />
        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={1}
          text={t('forms.calcul')}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 3,
          maxWidth: { xs: '100%', sm: 350 },
        }}
      >
        <DryExtractResults formikValues={formik.values} />
      </Box>
    </Box>
  );
}
