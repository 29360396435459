import * as yup from 'yup';
import {
  dynamicUnitName,
  dynamicUnitRestrictions,
} from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationSchema(t, alcoholUnitRelateds) {
  const oneDecimExp = /^\d+([.]\d{1,1})?$/;

  const validationPhrases = {
    tav_validation: t('validations.tav_validation_tenth', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
  };

  const notRestrictedValidationPhrases = {
    tav_validation: t('validations.tav_validation_tenth', {
      tavMin: dynamicUnitRestrictions(
        'tav_min_',
        'TAV',
        alcoholUnitRelateds.alcoholUnit,
      ),
      tavMax: dynamicUnitRestrictions(
        'tav_max_',
        'TAV',
        alcoholUnitRelateds.alcoholUnit,
      ),
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
  };

  const volumeExp = /^\d+([.]\d{1,4})?$/;

  return yup.object().shape({
    tavStart: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tav_validation)
      .required(validationPhrases.tav_validation)
      .min(alcoholUnitRelateds.tavMin, validationPhrases.tav_validation)
      .max(alcoholUnitRelateds.tavMax, validationPhrases.tav_validation)
      .test('nb-of-decimal', validationPhrases.tav_validation, (value) => (`${value}`).match(oneDecimExp)),
    tavAdded: yup.number().when('alcoholUnit', (alcoholUnit, schema) => schema
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(notRestrictedValidationPhrases.tav_validation)
      .required(notRestrictedValidationPhrases.tav_validation)
      .min(
        dynamicUnitRestrictions('tav_min_', 'TAV', alcoholUnit),
        notRestrictedValidationPhrases.tav_validation,
      )
      .max(
        dynamicUnitRestrictions('tav_max_', 'TAV', alcoholUnit),
        notRestrictedValidationPhrases.tav_validation,
      )
      .test(
        'nb-of-decimal',
        notRestrictedValidationPhrases.tav_validation,
        (value) => (`${value}`).match(oneDecimExp),
      )),
    tavFinal: yup.number().when('alcoholUnit', (alcoholUnit, schema) => schema
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(notRestrictedValidationPhrases.tav_validation)
      .required(notRestrictedValidationPhrases.tav_validation)
      .min(
        dynamicUnitRestrictions('tav_min_', 'TAV', alcoholUnit),
        notRestrictedValidationPhrases.tav_validation,
      )
      .max(
        dynamicUnitRestrictions('tav_max_', 'TAV', alcoholUnit),
        notRestrictedValidationPhrases.tav_validation,
      )
      .test(
        'nb-of-decimal',
        notRestrictedValidationPhrases.tav_validation,
        (value) => (`${value}`).match(oneDecimExp),
      )),
    isPreparation: yup.boolean(),
    volumeUnit: yup.string().required(),
    volumeValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.volume_validation'))
      .required(t('validations.volume_validation'))
      .min(0, t('validations.volume_validation'))
      .max(99999999, t('validations.volume_validation'))
      .test('nb-of-decimal', t('validations.volume_validation'), (value) => (`${value}`).match(volumeExp)),
  });
}
