import * as yup from 'yup';
import {
  dynamicUnitName,
  dynamicUnitRestrictions,
} from '../UnitDefinitionsAndMasks';

export default function validationSchema(globalState, t) {
  function volumeValidationPhrase(volumeUnit) {
    return t('ullaging.vol_validation', {
      volMin: dynamicUnitRestrictions('volume_min_', 'L', volumeUnit),
      volMax: dynamicUnitRestrictions('volume_max_', 'L', volumeUnit),
      volUnit: dynamicUnitName(volumeUnit),
    });
  }

  function diameterValidationPhrase(diameterUnit) {
    return t('ullaging.diameter_validation', {
      diameterMin: dynamicUnitRestrictions(
        'diameter_min_',
        'cm',
        diameterUnit,
      ),
      diameterMax: dynamicUnitRestrictions(
        'diameter_max_',
        'cm',
        diameterUnit,
      ),
      diameterUnit: dynamicUnitName(diameterUnit),
    });
  }

  function diameterRestrictions(diameterUnit) {
    return {
      diameterMin: dynamicUnitRestrictions(
        'diameter_min_',
        'cm',
        diameterUnit,
      ),
      diameterMax: dynamicUnitRestrictions(
        'diameter_max_',
        'cm',
        diameterUnit,
      ),
    };
  }

  function volumeRestrictions(volumeUnit) {
    return {
      volMin: dynamicUnitRestrictions('volume_min_', 'L', volumeUnit),
      volMax: dynamicUnitRestrictions('volume_max_', 'L', volumeUnit),
    };
  }

  return yup.object().shape({
    volumeUnit: yup.string().required(),
    lengthUnit: yup.string().required(),
    isLiquidHeight: yup.boolean(),
    totalVol: yup.number().when('volumeUnit', (volumeUnit, schema) => schema
      .typeError(volumeValidationPhrase(volumeUnit))
      .required(volumeValidationPhrase(volumeUnit))
      .min(
        volumeRestrictions(volumeUnit).volMin,
        volumeValidationPhrase(volumeUnit),
      )
      .max(
        volumeRestrictions(volumeUnit).volMax,
        volumeValidationPhrase(volumeUnit),
      )),
    diameterBond: yup.number().when('lengthUnit', (lengthUnit, schema) => schema
      .typeError(diameterValidationPhrase(lengthUnit))
      .required(diameterValidationPhrase(lengthUnit))
      .min(
        diameterRestrictions(lengthUnit).diameterMin,
        diameterValidationPhrase(lengthUnit),
      )
      .max(
        diameterRestrictions(lengthUnit).diameterMax,
        diameterValidationPhrase(lengthUnit),
      )),
    liquidHeight: yup
      .number()
      .when('isLiquidHeight', {
        is: true,
        then: (schema) => schema
          .typeError(t('ullaging.liquid_height_validation'))
          .required(t('ullaging.liquid_height_validation'))
          .min(0, t('ullaging.liquid_height_validation'))
          .max(99999999, t('ullaging.liquid_height_validation')),
        otherwise: (schema) => schema,
      }),
    emptyHeight: yup
      .number()
      .when('isLiquidHeight', {
        is: false,
        then: (schema) => schema
          .typeError(t('ullaging.empty_height_validation'))
          .required(t('ullaging.empty_height_validation'))
          .min(0, t('ullaging.empty_height_validation'))
          .max(99999999, t('ullaging.empty_height_validation')),
        otherwise: (schema) => schema,
      }),
  });
}
