import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import orangeVolume from 'files/orange-volume.png';
import blueVolume from 'files/blue-volume.png';
import wineVolume from 'files/wine-volume.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NumberInput } from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import decimalsMask from './DecimalsMask';

export default function TAVsForm(props) {
  const {
    formik, handleChange, handleBlur,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const { setPage } = props;

  const handleGoToVolume = () => {
    if (!formik.values.tavFinal || !formik.values.tavStart || !formik.values.tavAdded) {
      globalActions.setSnackbarMessage({ message: t('fill_all_fields'), severity: 'info' });
    } else if (
      (Number(formik.values.tavFinal) <= Number(formik.values.tavStart)
        && Number(formik.values.tavFinal) <= Number(formik.values.tavAdded))
      || (Number(formik.values.tavFinal) >= Number(formik.values.tavStart)
        && Number(formik.values.tavFinal) >= Number(formik.values.tavAdded))
    ) {
      globalActions.setSnackbarMessage({ message: t('error.reduc_improv_impossible'), severity: 'warning' });
    } else {
      setPage('volume');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 1,
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {t('reduction_improvement.intro_phrase')}
      </Typography>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2,
      }}
      >
        <Typography component="span">
          {t('reduction_improvement.enter_tav')}
          {' '}
          % vol.
        </Typography>
        <Typography
          component="span"
        >
          {t('reduction_improvement.at_20C')}
        </Typography>
        <IconButton
          color="primary"
          onClick={() => globalActions.setSnackbarMessage({
            message: t('reduction_improvement.ref_tav_temp_info'),
            severity: 'info',
          })}
        >
          <Info />
        </IconButton>
      </Box>

      <ImageInput
        name="tavStart"
        value={formik.values.tavStart}
        alcoholUnit={formik.values.alcoholUnit}
        imageSource={orangeVolume}
        placeHolder="ex:54.2"
        handleChange={handleChange}
        handleBlur={handleBlur}
        text={t('reduction_improvement.tav_start')}
      />
      {formik.touched.tavStart && formik.errors.tavStart && (
        <Typography component="span" sx={{ fontSize: 13, color: 'red' }}>
          {formik.errors.tavStart}
        </Typography>
      )}
      <AddIcon />
      <ImageInput
        name="tavAdded"
        value={formik.values.tavAdded}
        alcoholUnit={formik.values.alcoholUnit}
        imageSource={blueVolume}
        placeHolder={t('reduction_improvement.for_water')}
        handleChange={handleChange}
        handleBlur={handleBlur}
        text={t('reduction_improvement.tav_added')}
      />
      {formik.touched.tavAdded && formik.errors.tavAdded && (
        <Typography component="span" sx={{ fontSize: 13, color: 'red' }}>
          {formik.errors.tavAdded}
        </Typography>
      )}
      <ArrowDownwardIcon />
      <ImageInput
        name="tavFinal"
        value={formik.values.tavFinal}
        alcoholUnit={formik.values.alcoholUnit}
        imageSource={wineVolume}
        placeHolder="ex:40"
        handleBlur={handleBlur}
        handleChange={handleChange}
        text={t('reduction_improvement.tav_final')}
      />
      {formik.touched.tavFinal && formik.errors.tavFinal && (
        <Typography component="span" sx={{ fontSize: 13, color: 'red' }}>
          {formik.errors.tavFinal}
        </Typography>
      )}
      <Button
        color="secondary"
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        onClick={handleGoToVolume}
      >
        {t('reduction_improvement.go_to_volumes')}
      </Button>
    </Box>
  );
}

function ImageInput(props) {
  const {
    handleChange, imageSource, name, placeHolder, value, text, handleBlur, alcoholUnit,
  } = props;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      maxWidth: 500,
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
    >
      <img
        src={imageSource}
        style={{
          flexBasis: 1,
          width: '100%',
          maxWidth: 100,
          height: 'auto',
        }}
        alt={imageSource.toString()}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 3,
        textAlign: 'left',
      }}
      >
        <Typography component="span">{text}</Typography>
        <NumberInput
          onChange={handleChange}
          name={name}
          placeholder={placeHolder}
          decimalScale={decimalsMask[alcoholUnit]}
          value={value}
          onBlur={(e) => {
            handleBlur(e, alcoholUnit);
          }}
        />
      </Box>
    </Box>
  );
}
