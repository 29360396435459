import {
  collection, doc, getDoc, getDocs, query, where,
} from 'firebase/firestore';

export const getActiveSubscriptions = async (db, user, globalState) => {
  const ownerRef = globalState.activeOrganization === '' ? collection(
    db,
    'users',
    user?.uid,
    'subscriptions',
  )
    : collection(
      db,
      'organizations',
      globalState.activeOrganization,
      'subscriptions',
    );
  const activeSubsQuery = query(ownerRef, where('status', '==', 'active'));
  const activeSubsQuerySnapshot = await getDocs(activeSubsQuery);
  const activeSubs = [];
  activeSubsQuerySnapshot.forEach((document) => {
    activeSubs.push(document.data());
  });
  return activeSubs;
};

export const getFirstActiveSubscription = async (db, globalState, user) => {
  const subs = await getActiveSubscriptions(db, user, globalState);
  return subs[0];
};

// export const getAlcoholometryAccessGrants = async (db, user, globalState) => {
//   const accessGrantsRef = globalState.activeOrganization === '' ? doc(
//     db,
//     'users',
//     user?.uid,
//     'data',
//     'accessGrants',
//   )
//     : doc(
//       db,
//       'organizations',
//       globalState.activeOrganization,
//       'data',
//       'accessGrants',
//     );
//   const accessGrantsDoc = await getDoc(accessGrantsRef);
//   if (accessGrantsDoc.exists()) {
//     return accessGrantsDoc.data().accessGrants;
//   }
//   return [];
// };

export const getAlcoholometryAccessGrants = async (db, user, activeOrganization) => {
  const basePath = activeOrganization ? [
    'organizations', activeOrganization, 'accessGrants',
  ] : [
    'users', user?.uid, 'accessGrants',
  ];

  const accessGrantsRef = collection(db, ...basePath);

  const now = new Date();
  const validGrantsQuery = query(
    accessGrantsRef,
    where('expirationDate', '>', now),
    where('status', '==', 'active'),
  );

  const querySnapshot = await getDocs(validGrantsQuery);
  const validGrants = [];
  const grantNames = new Set();

  querySnapshot.forEach((d) => {
    validGrants.push(d.data());
    grantNames.add(d.data().name);
  });

  const uniqueGrantNames = Array.from(grantNames);

  return {
    validGrants,
    uniqueGrantNames,
  };
};

export const getAccessGrantMeaningfullName = (accessGrants) => {
  const accessGrantsDisplay = {
    'law-xo': 'XO',
    'law-vsop': 'VSOP',
    'law-vs': 'VS',
  };

  const importanceOrder = ['law-xo', 'law-vsop', 'law-vs'];

  for (const grant of importanceOrder) {
    if (accessGrants.includes(grant)) {
      return accessGrantsDisplay[grant];
    }
  }

  return 'free';
};

export const convertFirestoreTimestampToDate = (timestamp) => new Date(
  // eslint-disable-next-line no-underscore-dangle
  timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000,
);
