import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function TemperatureUnitPicker(props) {
  const { unit, setUnit } = props;

  return (
    <FormControl variant="outlined">
      <Select
        labelId="temp-select"
        id="temp-select"
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
      >
        <MenuItem value="celsius">°C</MenuItem>
        <MenuItem value="fahrenheit" disabled>°F</MenuItem>
      </Select>
    </FormControl>
  );
}
