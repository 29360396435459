import React from 'react';
import { Typography, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export default function OIMLConformity() {
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex', flexFlow: 'column', gap: 1, mt: 6, mb: 3,
    }}
    >
      <Typography align="center" component="i" variant="subtitle1" gutterBottom>
        {t('welcome.oiml', { joinArrays: '' })}
      </Typography>

      <Typography component="span" variant="body2">
        <MUILink
          component={Link}
          to="/oiml-publication"
          color="primary"
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
          }}
        >
          {t('welcome.publi')}
          <PdfIcon fontSize="small" style={{ marginLeft: 5 }} />
        </MUILink>
      </Typography>

      <Typography component="span" variant="body2">
        {t('welcome.publi_info')}
      </Typography>

      <Typography component="span" variant="body2" style={{ marginTop: 10 }}>
        <MUILink
          component={Link}
          to="/experts"
          color="secondary"
        >
          {t('welcome.experts_network')}
        </MUILink>
      </Typography>
    </Box>
  );
}
