export default function tierSubscriptions() {
  return productionPrices();
}

// eslint-disable-next-line no-unused-vars
function productionPrices() {
  return {
    vs: [
      {
        price: 'price_1P7W41FV0PdvbceXok9btbzz',
        quantity: 1,
      }],
  };
}

// eslint-disable-next-line no-unused-vars
function testPrices() {
  return {
    vs: [
      {
        price: 'price_1OicykFV0PdvbceXC7IboRQn',
        quantity: 1,
      }],
  };
}
