import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export default function VolumeUnitPicker(props) {
  const {
    withBottle, unit, setUnit = () => {},
  } = props;
  const { t } = useTranslation();
  return (
    <FormControl variant="outlined">
      <Select
        style={{ width: 130 }}
        defaultValue={unit}
        id="volume-select"
        value={unit}
        onChange={(e) => {
          const newVolumeUnit = e.target.value;
          setUnit(newVolumeUnit);
        }}
      >
        {withBottle && (<MenuItem value={t('forms.bottle')}>{t('forms.bottle')}</MenuItem>)}
        <MenuItem value="m3">m³</MenuItem>
        <MenuItem value="hL">HL (100L)</MenuItem>
        <MenuItem value="L">L (dm³)</MenuItem>
        <MenuItem value="dL">dl (100ml)</MenuItem>
        <MenuItem value="cL">cl</MenuItem>
        <MenuItem value="mL">ml (cm³)</MenuItem>
        <MenuItem value="barrelUS">barrel (US)</MenuItem>
        <MenuItem value="barrelUK">barrel (UK)</MenuItem>
        <MenuItem value="flozUK">floz (UK)</MenuItem>
        <MenuItem value="flozUS">floz (US)</MenuItem>
        <MenuItem value="galUK">gal (UK)</MenuItem>
        <MenuItem value="galUS">gal (US)</MenuItem>
        <MenuItem value="giUK">gi (UK)</MenuItem>
        <MenuItem value="giUS">gi (US)</MenuItem>
        <MenuItem value="pintUK">pint (UK)</MenuItem>
        <MenuItem value="pintUS">pint (US)</MenuItem>
        <MenuItem value="qtUK">qt (UK)</MenuItem>
        <MenuItem value="qtUS">qt (US)</MenuItem>
      </Select>
    </FormControl>
  );
}
