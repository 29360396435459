import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationTAVSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  const measuredTavExp = /^\d+([.]\d{1,2})?$/;

  return yup.object().shape({
    abvAt20cValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
      .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase)
      .test('nb-of-decimal', validationPhrases.tavValidationPhrase, (value) => (`${value}`).match(measuredTavExp)),
  });
}
