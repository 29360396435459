import React from 'react';
import useGlobal from 'global-state/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function GlobalSnackbar() {
  const [globalState, globalActions] = useGlobal();

  const handleClose = (event, reason) => {
    globalActions.closeSnackbar();
  };

  return (
    <Snackbar
      open={globalState.globalSnackbar.display}
      autoHideDuration={15000}
      onClose={() => globalActions.closeSnackbar()}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        severity={globalState.globalSnackbar.severity}
        sx={{ p: 5, boxShadow: 3 }}
        action={(
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
      )}
      >
        {globalState.globalSnackbar.message}
      </Alert>
    </Snackbar>
  );
}
