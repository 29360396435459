import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import TemperatureUnitPicker from 'components/units/TemperatureUnitPicker';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import CreditLoadingButton from 'components/CreditLoadingButton';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Arrow from '@mui/icons-material/ArrowForward';
import validationActiveSo2Schema from './ValidationActiveSO2Schema';
import decimalsMask from './DecimalsMask';
import ActiveSO2Results from './ActiveSO2Results';

export default function ActiveSO2Form(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { convertFreeSO2 } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, temperatureUnit, densityUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await convertFreeSO2(
      {
        abvAt20cValue: values.abvAt20cValue,
        pHValue: values.pHValue,
        temperatureInValue: values.temperatureInValue,
        freeSO2ConcentrationInValue: values.freeSO2ConcentrationInValue,

        abvAt20cUnit: values.abvAt20cUnit,
        pHUnit: values.pHUnit,
        temperatureInUnit: values.temperatureInUnit,
        freeSO2ConcentrationInUnit: values.freeSO2ConcentrationInUnit,
        activeSO2ConcentrationOutUnit: values.freeSO2ConcentrationInUnit,

        abvAt20cPrecision: decimalsMask[values.abvAt20cUnit],
        pHPrecision: decimalsMask[values.pHUnit],
        temperatureInPrecision: decimalsMask[values.temperatureInUnit],
        freeSO2ConcentrationInPrecision: decimalsMask[values.freeSO2ConcentrationInUnit],
      },
    );
    return results;
  };

  const defaultFormValues = {
    abvAt20cValue: '',
    pHValue: '',
    temperatureInValue: '',
    freeSO2ConcentrationInValue: '',
    abvAt20cUnit: 'TAV',
    pHUnit: 'pH',
    temperatureInUnit: globalState.userUnits.temperatureUnit,
    freeSO2ConcentrationInUnit: 'mg / L',
    activeSO2ConcentrationOutUnit: 'mg / L',
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="active_so2_form"
      validationSchema={() => validationActiveSo2Schema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
        />
      )}
    />
  );
}

function InnerForm({ setactiveSO2Values, setAlcoholUnitRelatedsFrom }) {
  const {
    formik, handleChange, handleBlur, calculating,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        ml: 'auto',
        mr: 'auto',
        gap: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          flexGrow: 2,
        }}
      >
        {/* ABV at 20C */}
        <LabelInputSelect
          label={(
            <Typography component="span">
              {t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
                alcoholUnit: dynamicUnitName(formik.values.abvAt20cUnit),
                referenceTempValue: '20',
                referenceTempUnit: dynamicUnitName(
                  'celsius',
                ),
              })}
            </Typography>
          )}
          input={(
            <NumberInput
              name="abvAt20cValue"
              onChange={handleChange}
              placeholder="ex: 12.2"
              value={formik.values.abvAt20cValue}
              decimalScale={decimalsMask[formik.values.abvAt20cUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.abvAt20cUnit);
              }}
            />
          )}
          error={formik.touched.abvAt20cValue && formik.errors.abvAt20cValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.abvAt20cValue}
            </Typography>
          )}
        />

        {/* pH */}
        <LabelInputSelect
          label={
            <Typography component="span">pH</Typography>
          }
          input={(
            <NumberInput
              name="pHValue"
              onChange={handleChange}
              placeholder="ex: 3.5"
              value={formik.values.pHValue}
              decimalScale={decimalsMask[formik.values.pHUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.pHUnit);
              }}
            />
          )}
          error={formik.touched.pHValue && formik.errors.pHValue && (
            <Typography component="span" sx={{ fontSize: 13, color: 'red' }}>
              {formik.errors.pHValue}
            </Typography>
          )}
          select={
            <Typography component="span" sx={{ ml: 1 }}>Uph</Typography>
          }
        />
        {/* Temperature */}
        <LabelInputSelect
          label={
            <Typography component="span">{t('forms.simple_measured_temp')}</Typography>
          }
          input={(
            <NumberInput
              name="temperatureInValue"
              onChange={handleChange}
              placeholder="ex: 18.1"
              value={formik.values.temperatureInValue}
              decimalScale={decimalsMask[formik.values.temperatureInUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.temperatureInUnit);
              }}
            />
          )}
          error={formik.touched.temperatureInValue && formik.errors.temperatureInValue && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.temperatureInValue}
            </Typography>
          )}
          select={(
            <TemperatureUnitPicker
              unit={formik.values.temperatureInUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  formik.values.abvAt20cUnit,
                  newUnit,
                  formik.values.freeSO2ConcentrationInUnit,
                );
                await formik.setFieldValue('temperatureInUnit', newUnit);
              }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          flexGrow: 1,
        }}
      >
        {/* Free SO2 Concentration */}
        <LabelInputSelect
          label={
            <Typography component="span">{t('so2.free_initial')}</Typography>
          }
          input={(
            <NumberInput
              name="freeSO2ConcentrationInValue"
              onChange={handleChange}
              placeholder="ex: 10"
              value={formik.values.freeSO2ConcentrationInValue}
              decimalScale={decimalsMask[formik.values.freeSO2ConcentrationInUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.freeSO2ConcentrationInUnit);
              }}
            />
          )}
          error={formik.touched.freeSO2ConcentrationInValue && formik.errors.freeSO2ConcentrationInValue && (
          <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
            {formik.errors.freeSO2ConcentrationInValue}
          </Typography>
          )}
          select={(
            <DensityUnitPicker
              unit={formik.values.freeSO2ConcentrationInUnit}
              setUnit={async (newUnit) => {
                await setAlcoholUnitRelatedsFrom(
                  formik.values.abvAt20cUnit,
                  formik.values.temperatureInUnit,
                  newUnit,
                );
                await formik.setFieldValue('freeSO2ConcentrationInUnit', newUnit);
              }}
            />
          )}
        />
        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={0}
          text={t('forms.calcul')}
        />
        <ActiveSO2Results />
        <Button
          sx={{ marginTop: 1, width: '100%' }}
          color="secondary"
          variant="contained"
          disabled={!formik.isValid}
          onClick={() => {
            navigate('rectify', {
              state: {
                activeSO2Values: formik.values,
              },
            });
          }}
        >
          <Typography component="span">
            {t('so2.rectify_so2')}
          </Typography>
          <Arrow style={{ color: '#ffffff' }} />
        </Button>
      </Box>
    </Box>
  );
}
