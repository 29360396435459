import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import TemperatureUnitPicker from 'components/units/TemperatureUnitPicker';
import AlcoholUnitPicker from 'components/units/AlcoholUnitPicker';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import validationTAVSchema from './ValidationTAVSchema';
import decimalsMask from './DecimalsMask';

export default function AlcoConversionTAVForm(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { convertABVToTemperature, actions, displayTemperatureConversion } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, temperatureUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, globalState.userUnits.densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await convertABVToTemperature(
      {
        abvValue: values.measuredTav.replace(/,/, '.'),
        abvUnit: values.alcoholUnit,
        abvOutUnit: values.alcoholUnit,
        temperatureInValue: values.measuredTemp.replace(/,/, '.'),
        temperatureOutValue: values.toTemp.replace(/,/, '.'),
        temperatureInUnit: values.temperatureUnit,
        temperatureOutUnit: globalState.userUnits.temperatureUnit,
        abvPrecision: decimalsMask[values.alcoholUnit],
        temperatureInPrecision: decimalsMask[values.temperatureUnit],
      },
    );
    return results;
  };

  const defaultFormValues = {
    measuredTav: '',
    measuredTemp: '',
    toTemp: '20.0',
    temperatureUnit: globalState.userUnits.temperatureUnit,
    alcoholUnit: globalState.userUnits.alcoholUnit,
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="tav_form"
      validationSchema={() => validationTAVSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
          actions={actions}
          displayTemperatureConversion={displayTemperatureConversion}
        />
      )}
    />
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, setResults,
  } = React.useContext(BoxetteFormContext);
  const { actions, setAlcoholUnitRelatedsFrom, displayTemperatureConversion } = props;
  const { t } = useTranslation();

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_tav')}</Typography>
        }
        input={(
          <NumberInput
            onChange={handleChange}
            name="measuredTav"
            placeholder="ex: 56.88"
            value={formik.values.measuredTav}
            decimalScale={decimalsMask[formik.values.alcoholUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.alcoholUnit);
            }}
          />
        )}
        error={formik.touched.measuredTav && formik.errors.measuredTav && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.measuredTav}
        </Typography>
        )}
        select={(
          <AlcoholUnitPicker
            unit={formik.values.alcoholUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                newUnit,
                formik.values.temperatureUnit,
              );
              await formik.setFieldValue('alcoholUnit', newUnit);
            }}
          />
        )}
      />

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_temp')}</Typography>
        }
        input={(
          <NumberInput
            name="measuredTemp"
            onChange={handleChange}
            placeholder="ex: 12.2"
            value={formik.values.measuredTemp}
            decimalScale={decimalsMask[formik.values.temperatureUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.temperatureUnit);
            }}
            allowNegative
          />
        )}
        error={formik.touched.measuredTemp && formik.errors.measuredTemp && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.measuredTemp}
        </Typography>
        )}
        select={(
          <TemperatureUnitPicker
            unit={formik.values.temperatureUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                formik.values.alcoholUnit,
                newUnit,
              );
              await formik.setFieldValue('temperatureUnit', newUnit);
            }}
          />
        )}
      />

      {displayTemperatureConversion && (
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.to_temp')}</Typography>
        }
        input={(
          <NumberInput
            name="toTemp"
            onChange={handleChange}
            placeholder="ex: 12.2"
            value={formik.values.toTemp}
            decimalScale={decimalsMask[formik.values.temperatureUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.temperatureUnit);
            }}
            allowNegative
          />
        )}
        error={formik.touched.toTemp && formik.errors.toTemp && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.toTemp}
        </Typography>
        )}
        select={(
          <TemperatureUnitPicker
            unit={formik.values.temperatureUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                formik.values.alcoholUnit,
                newUnit,
              );
              await formik.setFieldValue('temperatureUnit', newUnit);
            }}
          />
        )}
      />
      )}

      {React.cloneElement(actions, {
        formikSubmitForm: formik.submitForm,
        calculating,
        formikIsValid: formik.isValid,
        formikValues: formik.values,
        alcoholConversionResults: results,
        setResults,
      })}
    </form>
  );
}
