import React, { useMemo, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSigninCheck } from 'reactfire';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import {
  useNavigate, useLocation, Link,
} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { menuPaths } from 'components/utils';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMenu from 'menu-actions/useMenu';
import Divider from '@mui/material/Divider';
import LanguageSelectToggle from 'components/account/LanguageSelectToggle';
import OrganizationsSelect from 'components/organization/OrganizationsSelect';
import useGlobal from 'global-state/store';
// import { getAccessGrantMeaningfullName } from './subscriptions/utils';

export default function TopToolbar(props) {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const { menuActions: originalMenuActions } = useMenu();
  const [globalState] = useGlobal();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const isSmallScreen = useMemo(() => innerWidth < 600, [innerWidth]);

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuActions = useMemo(() => {
    if (!isSmallScreen
      || !signInCheckResult?.signedIn
      || menuPaths(location)
      || location.pathname.includes('account')) {
      return originalMenuActions;
    }

    const myAccountAction = {
      name: t('my_account'),
      id: 'my_account_action',
      callback: () => navigate('/account'),
    };

    // const myCreditsAction = {
    //   name: (<Typography variant="caption">{getAccessGrantMeaningfullName(globalState.accessGrants)}</Typography>),
    //   id: 'my_credits',
    //   callback: () => { },
    // };

    const languageToggleAction = {
      name: <LanguageSelectToggle height={32} />,
      id: 'languageToggle',
      callback: () => { },
    };

    return {
      ...originalMenuActions,
      myAccountAction,
      languageToggleAction,
    };
  }, [isSmallScreen, location, navigate,
    originalMenuActions, signInCheckResult?.signedIn, t]);

  const hasActions = useMemo(() => Object.keys(menuActions).length > 0, [menuActions]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const displayOrganization = () => signInCheckResult?.signedIn === true
  && Object.entries(globalState.userOrganizations).length > 0;

  const displayMyAccount = () => signInCheckResult?.signedIn === true
    && (!isSmallScreen || !hasActions)
    && !location.pathname.includes('account');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LogoOrBack />
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 0.5, sm: 1 },
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {(!hasActions || !isSmallScreen) && (<LanguageSelectToggle height={32} />)}
        {displayOrganization() && (<OrganizationsSelect small />)}

        {/* {(!isSmallScreen || !hasActions) && (
          <Typography variant="caption">{getAccessGrantMeaningfullName(globalState.accessGrants)}</Typography>
        )} */}

        {displayMyAccount() && (
          <Typography variant="h6">
            <IconButton
              aria-label="account"
              component={Link}
              to="/account"
              sx={{ p: 0, fontSize: 37 }}
              color="primary"
            >
              <AccountBox fontSize="inherit" />
            </IconButton>
          </Typography>
        )}
        {!(signInCheckResult?.signedIn === true) && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            component={Link}
            to="/signin"
            startIcon={<AccountCircle />}
          >
            {t('signin.login')}
          </Button>
        )}
        {hasActions && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />

      </Box>
    </Box>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions,
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      {Object.entries(actions).map((action) => (
        <MyMenuItem
          key={action[0]}
          title={action[1].name}
          callback={action[1].callback}
          handleMenuClose={handleMenuClose}
        />
      ))}
    </Menu>
  );
}

function MyMenuItem(props) {
  const { title, callback, handleMenuClose } = props;
  if (typeof title === 'string' && title.includes('divider')) {
    return <Divider />;
  }
  return (
    <MenuItem onClick={(event) => { handleMenuClose(); callback(); }}>
      {title}
    </MenuItem>
  );
}

function LogoOrBack(props) {
  const location = useLocation();
  const { t } = useTranslation();
  const { returnAction, setreturnAction } = useMenu();
  const navigate = useNavigate();

  React.useEffect(() => {
    setreturnAction(() => () => navigate(-1));
  }, [location, navigate, setreturnAction]);

  if (menuPaths(location)) {
    return (
      <Box />
    );
  }
  return (
    <Button
      sx={{ color: 'text.primary' }}
      startIcon={<ChevronLeft />}
      onClick={returnAction}
    >
      {t('back')}
    </Button>
  );
}
