import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import ResultCard from '../ResultCard';
import { dynamicUnitName, readibleValueAndUnit } from '../UnitDefinitionsAndMasks';

export default function ActiveSO2Results(props) {
  const {
    results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  const notLessThanZeroResult = (result) => (result.value > 0
    ? readibleValueAndUnit(result.value, result.unit) : '-');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 600,
        minWidth: 350,
      }}
    >
      <ResultCard
        title={t('so2.active_present', {
          temperatureValue: resultsAvailable() ? results.activeSO2.temperature.value : '',
          temperatureUnit: resultsAvailable() ? dynamicUnitName(
            results.activeSO2.temperature.unit,
          ) : '',
        })}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.activeSO2)
          : '-'}
      />
    </Box>
  );
}
