import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

export default function ReadOrRenameOperation(props) {
  const {
    displayName, operation, formNameInStorage, dispatchRenameOperation,
  } = props;
  const [edit, setedit] = React.useState(false);
  const { t } = useTranslation();

  const handleRename = (name) => {
    dispatchRenameOperation({ type: 'rename', payload: { operation, formNameInStorage, name } });
    setedit(false);
  };

  const handleEdit = (event) => {
    setedit(true);
  };

  if (edit) {
    return (
      <RenameForm
        handleRename={handleRename}
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
      }}
      onClick={handleEdit}
    >
      <Typography>{displayName}</Typography>
      <Tooltip title={t('rename')}>
        <IconButton
          size="small"
          aria-label="favorite"
          component="label"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

function RenameForm(props) {
  const { t } = useTranslation();
  const {
    handleRename,
  } = props;

  return (
    <Formik
      initialValues={{ name: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = t('validations.required');
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        handleRename(values.name);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <TextField
            style={{ maxWidth: 200 }}
            size="small"
            fullWidth
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            label={t('name')}
            error={touched.name && Boolean(errors.name)}
            helperText={errors.name && touched.name && errors.name}
          />
          <Button size="small" variant="contained" type="submit">
            ok
          </Button>
        </Box>
      )}
    </Formik>

  );
}
