import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import VolumePAConversionForm from './VolumePAConversionForm';

export default function VolumePAConversionBoxette(props) {
  const { volumePAConversionToTemp } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [alcoholConversionResults] = useState(
    location.state?.alcoholConversionResults,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (!alcoholConversionResults) {
      navigate('../vol-alco-conv', {
        replace: true,
        state: location.state,
      });
    }
  }, [navigate, alcoholConversionResults, location.state]);

  if (alcoholConversionResults) {
    return (
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
      }}
      >
        <Typography variant="h5" component="span">
          {t('boxettes.vol_pa_conversion')}
        </Typography>
        <VolumePAConversionForm
          alcoholConversionResults={alcoholConversionResults}
          volumePAConversionToTemp={volumePAConversionToTemp}
        />
      </Box>

    );
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
