import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { LoadingButton } from '@mui/lab';

function AccessGrantsGrid({ accessGrants }) {
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const deleteAccessGrantsCall = httpsCallable(functions, 'deleteAccessGrantsCall');
  const [loading, setLoading] = useState(false);
  const [, globalActions] = useGlobal();

  const getAllKeys = (grants) => {
    const keys = new Set();
    grants.forEach((grant) => {
      Object.keys(grant).forEach((key) => keys.add(key));
    });
    return Array.from(keys);
  };

  const columns = getAllKeys(accessGrants).filter((key) => key !== 'path').map((key) => ({
    field: key,
    headerName: key,
    width: 130,
    editable: false,
  }));

  // Add a custom column for the action button
  columns.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <LoadingButton
        loading={loading}
        size="small"
        variant="text"
        color="error"
        onClick={() => handleExpireAccess(params.row)}
      >
        Faire expirer
      </LoadingButton>
    ),
  });

  const handleExpireAccess = async (grant) => {
    setLoading(true);

    try {
      await deleteAccessGrantsCall({
        accessGrantPath: grant.path,
      });
      globalActions.setSnackbarMessage({ message: 'succès', severity: 'success' });
    } catch (error) {
      globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 1, width: '90%',
    }}
    >
      <DataGrid
        rows={accessGrants.map((grant, index) => ({
          id: grant.path,
          ...grant,
        }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight={false}
      />
    </Box>
  );
}

export default AccessGrantsGrid;
