import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

function OfflineAlert() {
  const [displayAll, setDisplayAll] = useState(false);
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  if (globalState.networkState !== 'offline') {
    return null;
  }

  return (
    <Alert
      sx={{
        padding: 1,
        mt: 1,
        justifyContent: 'center',
      }}
      severity="error"
      onClick={() => setDisplayAll(!displayAll)}
    >
      {t('offline')}
    </Alert>
  );
}

export default OfflineAlert;
