import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationTAVSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  return yup.object().shape({
    temperatureUnit: yup.string().required(),
    measuredTav: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
      .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
    measuredTemp: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
    toTemp: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
  });
}
