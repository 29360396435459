import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { doc, deleteDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import Button from '@mui/material/Button';
import useGlobal from 'global-state/store';

export default function IssuerCodes(props) {
  const { codes, title, deletables = false } = props;
  const db = useFirestore();
  const { t } = useTranslation();
  const [globalState] = useGlobal();

  const [displayCodes, setdisplayCodes] = useState(codes);

  const deleteCodes = () => {
    codes.forEach((code) => {
      deleteDoc(doc(db, 'apps', 'alcoholometry', 'availableCodes', code.id));
    });
    setdisplayCodes([]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 2,
        padding: 3,
        flexGrow: 1,
        backgroundColor: '#eeeeee',
      }}
    >
      <Typography component="span" variant="h5">
        {title}
      </Typography>
      {displayCodes.length > 0 && deletables && globalState.roles.includes('admin') && (
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={deleteCodes}
        >
          {`${t('delete')} ${t('all')}`}
        </Button>
      )}
      { displayCodes.map((element) => (
        <DisplayCode key={element.id} code={element} />
      )) }
    </Box>
  );
}

function DisplayCode(props) {
  const { code } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    >
      <Typography component="span" sx={{ mb: 0.5, fontWeight: 'bold' }}>
        {code.id}
      </Typography>
      {code.activationsLeft !== undefined && (
      <Typography component="span">
        {t('codes.activations_left')}
        {': '}
        {code.activationsLeft}
      </Typography>
      )}
      {code.activationsUsed !== undefined && (
      <Typography component="span">
        {t('codes.activations_used')}
        {': '}
        {code.activationsUsed}
      </Typography>
      )}

    </Box>
  );
}
