import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Select, MenuItem, Typography, Box,
} from '@mui/material';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import usePrevious from 'customHooks/usePrevious';

function OrganizationsSelect({ changeCallback = () => {}, small = false }) {
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { data: user } = useUser();
  const [activeOrganization, setActiveOrganization] = useState('');
  const [loadingState, setloadingState] = useState('initial');
  const [organizationNames, setOrganizationNames] = useState({});
  const { t } = useTranslation();
  const prevLoading = usePrevious(loadingState);

  const shouldLoad = useCallback(
    () => loadingState === 'initial' && prevLoading === 'initial',
    [loadingState, prevLoading],
  );

  const fetchOrganizationNames = useCallback(async () => {
    const orgs = globalState.userOrganizations;
    const areMissingNames = Object.entries(orgs).some((org) => org[1] === undefined);
    const completedOrgs = { ...orgs };
    if (areMissingNames) {
      await Promise.all(
        Object.entries(orgs).map(async (org) => {
          const orgId = org[0];
          const organizationRef = doc(db, 'organizations', orgId);
          const organizationDoc = await getDoc(organizationRef);
          const organizationData = organizationDoc.data();
          completedOrgs[orgId] = organizationData.name;
        }),
      );
    }
    completedOrgs[''] = t('organization.personnal_account');
    setOrganizationNames(completedOrgs);
    setloadingState('loaded');
  }, [db, globalState.userOrganizations, t]);

  useEffect(() => {
    if (shouldLoad()) {
      setloadingState('loading');
      setActiveOrganization(globalState.activeOrganization);
      fetchOrganizationNames();
    }
  }, [fetchOrganizationNames, globalState.activeOrganization, shouldLoad]);

  const handleOrganizationChange = async (event) => {
    const newActiveOrganization = event.target.value;
    setActiveOrganization(newActiveOrganization);
    globalActions.setActiveOrganization(newActiveOrganization);
    const userPrivateDataRef = doc(db, 'users', user.uid, 'private', 'data');
    await updateDoc(userPrivateDataRef, {
      activeOrganization: newActiveOrganization,
    });
    globalActions.setUserStatus('initial');
    changeCallback();
  };

  if (loadingState === 'loading') {
    return (
      <CircularProgress size={30} />
    );
  }

  if (loadingState !== 'loaded') {
    return null;
  }

  if (small) {
    return (
      <OrgSelect
        activeOrganization={activeOrganization}
        handleOrganizationChange={handleOrganizationChange}
        organizationNames={organizationNames}
        small
      />
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography variant="h6" sx={{ fontSize: 18 }}>
        {t('organization.organization')}
        {' :'}
      </Typography>
      <OrgSelect
        activeOrganization={activeOrganization}
        handleOrganizationChange={handleOrganizationChange}
        organizationNames={organizationNames}
      />
    </Box>

  );
}

function OrgSelect({
  activeOrganization, handleOrganizationChange, organizationNames, small = false,
}) {
  return (
    <Select
      displayEmpty
      size="small"
      value={activeOrganization}
      onChange={handleOrganizationChange}
      sx={
        small
          ? {
            height: 32, width: { xs: 175, sm: 230 }, flexGrow: 1, maxWidth: 250,
          }
          : { width: { xs: 175, sm: 230 }, flexGrow: 1, maxWidth: 250 }
      }
    >
      {Object.entries(organizationNames).map((org) => (
        <MenuItem key={org[0]} value={org[0]}>
          {organizationNames[org[0]]}
        </MenuItem>
      ))}
    </Select>
  );
}

export default OrganizationsSelect;
