import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import { Paper } from '@mui/material';
import decimalsMask from './DecimalsMask';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';

export default function MutedProduct(props) {
  const {
    formik, handleChange, handleBlur, setResults,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: 1,
        p: 2,
      }}
    >
      <Typography>
        {t('mutage.muted_product')}
      </Typography>
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.volume_value')}</Typography>
          }
        input={(
          <NumberInput
            disabled={['2', '4'].includes(formik.values.useCase)}
            id="PmVolumeValue"
            name="PmVolumeValue"
            value={formik.values.PmVolumeValue}
            placeholder="ex: 100"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.PmVolumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.PmVolumeUnit);
            }}
          />
          )}
        error={
            formik.touched.PmVolumeValue
            && formik.errors.PmVolumeValue && (
              <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
                {formik.errors.PmVolumeValue}
              </Typography>
            )
          }
        select={(
          <VolumeUnitPicker
            unit={formik.values.PmVolumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('PmVolumeUnit', newUnit);
              setResults({});
            }}
          />
          )}
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.with_tav')}</Typography>
        }
        input={(
          <NumberInput
            onChange={handleChange}
            name="PmABVValue"
            placeholder="ex: 56.88"
            value={formik.values.PmABVValue}
            decimalScale={decimalsMask[formik.values.PmABVUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.PmABVUnit);
            }}
          />
        )}
        error={formik.touched.PmABVValue && formik.errors.PmABVValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.PmABVValue}
        </Typography>
        )}
        select={(
          <Typography>
            {dynamicUnitName(formik.values.PmABVUnit)}
          </Typography>
        )}
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.sugar')}</Typography>
          }
        input={(
          <NumberInput
            disabled={['1', '2'].includes(formik.values.useCase)}
            onChange={handleChange}
            name="PmSugarValue"
            placeholder="ex: 220"
            value={formik.values.PmSugarValue}
            decimalScale={decimalsMask[formik.values.PmSugarUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.PmSugarUnit);
            }}
          />
          )}
        error={formik.touched[formik.values.PmSugarValue] && formik.errors[formik.values.PmSugarValue] && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors[formik.values.PmSugarValue]}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.PmSugarUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('PmSugarUnit', newUnit);
            }}
          />
          )}
      />
    </Paper>
  );
}
