export function initBackupsOperations(formNameInStorage) {
  const operationsFromStore = JSON.parse(localStorage
    .getItem(`backups_operations::${formNameInStorage}`));
  if (operationsFromStore) {
    return operationsFromStore;
  }
  return [];
}

export function reducerBackupsOperations(currentState, dispatch) {
  let finalOperations = [...currentState];

  const filteredOperations = (id) => finalOperations.filter(
    (op) => op.id !== id,
  );

  switch (dispatch.type) {
    case 'add':
      if (finalOperations.length >= 50) {
        throw new Error('maximum backups');
      }
      finalOperations.push({ ...dispatch.payload.operation, id: new Date().toISOString() });
      saveOperationInBackups(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    case 'remove':
      finalOperations = filteredOperations(dispatch.payload.operation.id);
      saveOperationInBackups(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    case 'rename':
      finalOperations = filteredOperations(dispatch.payload.operation.id);
      finalOperations.push({
        ...dispatch.payload.operation,
        name: dispatch.payload.name,
      });
      saveOperationInBackups(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    default:
      throw new Error();
  }
}

export function saveOperationInBackups(operations, formNameInStorage) {
  localStorage
    .setItem(`backups_operations::${formNameInStorage}`, JSON.stringify(operations));
}
