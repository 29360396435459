import React, { useState } from 'react';
import {
  Select, MenuItem, FormControl,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function UseCaseSelect({ defaultCaseIndex = '1', updateCallback = () => {} }) {
  const { t } = useTranslation();
  const statusKeys = ['-', '1', '2', '3', '4'];
  const allStatus = statusKeys.reduce((acc, key) => {
    acc[key] = t(`mutage.case_${key}`);
    return acc;
  }, {});
  allStatus['-'] = t('mutage.no_case');

  const status = Object.keys(allStatus);
  const statusNames = allStatus;
  const [activeStatus, setActiveStatus] = useState(defaultCaseIndex);

  const handleStatusChange = async (event) => {
    const newActiveStatus = event.target.value;
    setActiveStatus(newActiveStatus);
    updateCallback(newActiveStatus, statusNames[newActiveStatus]);
  };

  return (
    <SelectCore
      activeStatus={activeStatus}
      handleStatusChange={handleStatusChange}
      status={status}
      statusNames={statusNames}
    />
  );
}

function SelectCore({
  activeStatus, handleStatusChange, status, statusNames,
}) {
  return (
    <FormControl sx={{ minWidth: 116 }}>
      <Select
        labelId="client-select-label"
        displayEmpty
        size="large"
        value={activeStatus}
        onChange={handleStatusChange}
      >
        {status.map((id) => (
          <MenuItem key={id} value={id}>
            {statusNames[id]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>

  );
}
