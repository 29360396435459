import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InstallationGuideTip from './InstallationGuideTip';
// import FavoriteBoxettesTip from './FavoriteBoxettesTip';

function Tips() {
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2,
    }}
    >
      <Typography variant="h6" component="h3">
        Tips
      </Typography>
      <InstallationGuideTip />
      {/* <FavoriteBoxettesTip /> */}
      {/* Add more tips components here */}
    </Box>
  );
}

export default Tips;
