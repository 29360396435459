import * as yup from 'yup';
import {
  dynamicUnitName,
  dynamicUnitRestrictions,
} from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationSchema(t) {
  function temperationValidationPhrase(tempUnit) {
    return t('validations.temperature_validation', {
      tempMin: dynamicUnitRestrictions('temp_min_', 'TAV', tempUnit),
      tempMax: dynamicUnitRestrictions('temp_max_', 'TAV', tempUnit),
      tempUnit: dynamicUnitName(tempUnit),
    });
  }

  return yup.object().shape({
    volumeUnit: yup.string().required(),
    temperatureUnit: yup.string().required(),
    volumeValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.volume_validation'))
      .required(t('validations.volume_validation'))
      .min(0, t('validations.volume_validation'))
      .max(99999999, t('validations.volume_validation')),
    temperatureValue: yup.number().when('temperatureUnit', (tempUnit, schema) => schema
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(temperationValidationPhrase(tempUnit))
      .required(temperationValidationPhrase(tempUnit))
      .min(
        dynamicUnitRestrictions('temp_min_', 'TAV', tempUnit),
        temperationValidationPhrase(tempUnit),
      )
      .max(
        dynamicUnitRestrictions('temp_max_', 'TAV', tempUnit),
        temperationValidationPhrase(tempUnit),
      )),
  });
}
