import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import ResultCard from '../ResultCard';
import { dynamicUnitName, readibleValueAndUnit } from '../UnitDefinitionsAndMasks';

export default function DryExtractResults(props) {
  const {
    results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  const notLessThanZeroResult = (result) => (result.value > -0.1
    ? readibleValueAndUnit(result.value, result.unit) : '-');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 600,
        minWidth: 350,
      }}
    >
      <ResultCard
        title={t('forms.real_tav_at_ref_temp', {
          referenceTempValue: resultsAvailable() ? results.trueABV.temperature.value : '',
          referenceTempUnit: resultsAvailable() ? dynamicUnitName(
            results.trueABV.temperature.unit,
          ) : '',
        })}
        result={resultsAvailable()
          ? readibleValueAndUnit(results.trueABV.value, results.trueABV.unit)
          : '-'}
      />
      <ResultCard
        title={t('forms.tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: resultsAvailable() ? results.rawABV.temperature.value : '',
          referenceTempUnit: resultsAvailable() ? dynamicUnitName(
            results.rawABV.temperature.unit,
          ) : '',
        })}
        result={resultsAvailable()
          ? readibleValueAndUnit(results.rawABV.value, results.rawABV.unit)
          : '-'}
      />
      <ResultCard
        title={t('forms.dry_extract')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.dryExtract)
          : '-'}
      />
      <ResultCard
        title={t('forms.obscuration_degree')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.obscuration)
          : '-'}
      />
    </Box>
  );
}
