import React from 'react';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import Issuer from './Issuer';

export default function Issuers() {
  const [globalState] = useGlobal();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {globalState.issuers.length > 0 && (
      <Issuer key={globalState.issuers[0]} name={globalState.issuers[0]} expanded />)}
      {globalState.issuers.length > 1 && (
        globalState.issuers.slice(1).map((element) => (
          <Issuer key={element} name={element} />
        )))}
    </Box>
  );
}
