import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import CreditLoadingButton from 'components/CreditLoadingButton';
import { Typography } from '@mui/material';
import UseCaseSelect from './UseCaseSelect';
import MutageAlcohol from './MutageAlcohol';
import ProductToMute from './ProductToMute';
import MutedProduct from './MutedProduct';
import Results from './Results';

export default function InnerForm(props) {
  const {
    formik, calculating,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  const updateSelectedCase = (value) => {
    formik.setFieldValue('useCase', value);
    let fieldsToNullify = [];
    switch (value) {
      case '1':
        fieldsToNullify = ['SpiritVolumeValue', 'MoutVolumeValue', 'PmSugarValue'];
        break;
      case '2':
        fieldsToNullify = ['SpiritVolumeValue', 'PmVolumeValue', 'PmSugarValue'];
        break;
      case '3':
        fieldsToNullify = ['SpiritVolumeValue', 'SpiritABVValue', 'MoutVolumeValue'];
        break;
      case '4':
        fieldsToNullify = ['SpiritVolumeValue', 'SpiritABVValue', 'PmVolumeValue'];
        break;
      default:
        break;
    }

    fieldsToNullify.forEach((field) => formik.setFieldValue(field, ''));
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        width: '100%',
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: 3,
      }}
      >
        <Typography variant="h6" component="span">
          {t('boxettes.mutage')}
        </Typography>
        <UseCaseSelect
          defaultCaseIndex={formik.values.useCase}
          updateCallback={updateSelectedCase}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: { xs: 'wrap', lg: 'noWrap' },
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: 1,
        width: '100%',
      }}
      >
        <MutageAlcohol />
        <ProductToMute />
        <MutedProduct />
      </Box>
      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={0}
        text={t('forms.calcul')}
      />
      <Results />
    </Box>
  );
}
