import React from 'react';

import { useLocation } from 'react-router-dom';
import VolumePAWithDryExtractForm from './VolumePAWithDryExtractForm';

export default function VolumePAWithDryExtractBoxette(props) {
  const location = useLocation();
  const { volumePAConversionWithDryExtract } = props;

  return (
    <VolumePAWithDryExtractForm
      volumePAConversionWithDryExtract={volumePAConversionWithDryExtract}
      alcoholConversionResults={location.state.alcoholConversionResults}
      volumeConversionResults={location.state.volumeConversionResults}
    />
  );
}
