import React, { useMemo } from 'react';
import {
  Box, TextField, FormControl,
  Autocomplete,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import ScienceIcon from '@mui/icons-material/Science';
import UndoIcon from '@mui/icons-material/Undo';

export default function SelectedUnitPicker({
  formik, formikKey, addUnit, removeUnit, unitCategories,
}) {
  const { t } = useTranslation();
  const options = useMemo(() => Object.keys(unitCategories).flatMap(
    (category) => unitCategories[category].map((unit, index) => ({
      category,
      ...unit,
      index,
    })),
  ), [unitCategories]);

  const handleUnitChange = (index, newValue) => {
    const updatedUnits = [...formik.values[formikKey].array];
    updatedUnits[index] = newValue ? newValue.value : '';
    formik.setFieldValue(`${formikKey}.array`, updatedUnits);
  };

  const unitSelects = formik.values[formikKey].array.reduce((acc, unit, index) => {
    const unitKey = uuidv4();

    if (index > 0) {
      acc.push(<Box key={`${unitKey}/`} sx={{ alignSelf: 'center' }}>/</Box>);
    }
    acc.push(
      <FormControl key={unitKey} fullWidth sx={{ width: 180 }}>
        <Autocomplete
          id={`${formikKey}-unit-picker`}
          options={options.sort((a, b) => -b.category.localeCompare(a.category))}
          groupBy={(option) => t(`units.${option.category}`)}
          getOptionLabel={(option) => option.display}
          value={options.find((option) => option.value === unit) || null}
          onChange={(event, newValue) => handleUnitChange(index, newValue)}
            // eslint-disable-next-line react/jsx-props-no-spreading
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>,
    );
    return acc;
  }, []);

  const isSingleField = formik.values[formikKey].singleField;

  const switchSingleField = () => {
    formik.setFieldValue('fromUnit.singleField', !isSingleField);
    formik.setFieldValue('toUnit.singleField', !isSingleField);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap',
    }}
    >
      {isSingleField ? (
        <TextField
          sx={{ width: 250 }}
          value={formik.values[formikKey].field}
          onChange={(e) => formik.setFieldValue(`${formikKey}.field`, e.target.value)}
          inputProps={{ autoCapitalize: 'none' }}
        />
      ) : (
        <>
          { unitSelects }
          { formik.values[formikKey].array.length < 2 && (
          <Button onClick={() => addUnit()}>
            <AddIcon />
          </Button>
          )}
          { formik.values[formikKey].array.length > 1 && (
          <Button onClick={() => removeUnit()}>
            <RemoveIcon />
          </Button>
          )}
        </>
      )}
      <Button onClick={switchSingleField}>
        {!isSingleField ? (<ScienceIcon />) : (<UndoIcon />)}
      </Button>
    </Box>

  );
}
