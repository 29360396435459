import moment from 'moment';
import lz from 'lz-string';

export const lessThan10minutesAgo = (date) => moment(date).isAfter(moment().subtract(30, 'minutes'));

export const getOrCleanupDeeplink = (globalActions) => {
  const sessionLink = sessionStorage.getItem('current_deeplink');
  if (sessionLink) {
    const parsedDeeplink = JSON.parse(sessionLink);
    if (lessThan10minutesAgo(parsedDeeplink.time)) {
      return parsedDeeplink;
    }
    sessionStorage.removeItem('current_deeplink');
    globalActions.setCurrentDeeplink(undefined);
  }
  return undefined;
};

export const addValuesAndResultsToDeeplink = (valuesAndResults, globalActions) => {
  const deeplink = getOrCleanupDeeplink();

  if (deeplink) {
    const compressedValuesAndResults = lz.compressToEncodedURIComponent(
      JSON.stringify({ deeplinkReturnId: deeplink.deeplinkReturnId, valuesAndResults }),
    );
    const newHookUrl = new URL(deeplink.hookUrl);
    newHookUrl.searchParams.delete('alcoValuesAndResults');
    newHookUrl.searchParams.append('alcoValuesAndResults', compressedValuesAndResults);
    deeplink.hookUrl = newHookUrl.href;
    sessionStorage.setItem('current_deeplink', JSON.stringify(deeplink));
    globalActions.setCurrentDeeplink(deeplink);
  }
};
