import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CreditLoadingButton from 'components/CreditLoadingButton';
import { useNavigate } from 'react-router-dom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import useGlobal from '../../../global-state/store';
import {
  NumberInput,
  LabelInputSelect,
  RadioBoxetteInput,
} from '../BoxetteInputs';
import VolumeUnitPicker from '../../units/VolumeUnitPicker';
import LengthUnitPicker from '../../units/LengthUnitPicker';
import validationSchema from './ValidationSchemaStanding';
import ResultCard from '../ResultCard';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';
import barreldimensions from '../../../files/ullaging-standing.jpg';
import toVolConvActions from './actions';
import decimalsMask from './DecimalsMask';

export default function UllagingStanding(props) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const { ullagingStanding } = props;
  const [, globalActions] = useGlobal();

  const boxetteCall = async (values) => {
    let results;
    if (values.diameter === '' && values.totalVol === '') {
      globalActions.setSnackbarMessage({ message: t('ullaging.volume_or_diameter_mandatory'), severity: 'warning' });
    } else if (
      (values.isLiquidHeight
        && parseFloat(values.liquidHeight) > parseFloat(values.heightBond))
      || (!values.isLiquidHeight
        && parseFloat(values.emptyHeight) > parseFloat(values.heightBond))
    ) {
      globalActions.setSnackbarMessage({ message: t('error.ullaging_impossible'), severity: 'warning' });
    } else if (values.isLiquidHeight) {
      results = await ullagingStanding({
        heightBondValue: values.heightBond,
        diameterValue: values.diameter === '' ? null : values.diameter,
        barrelVolumeValue: values.totalVol === '' ? null : values.totalVol,
        emptyHeightValue: null,
        liquidHeightValue: values.liquidHeight,
        volumeUnit: values.volumeUnit,
        lengthUnit: values.lengthUnit,
        volumePrecision: decimalsMask[values.volumeUnit],
        lengthPrecision: decimalsMask[values.lengthUnit],
      });
    } else {
      results = await ullagingStanding({
        heightBondValue: values.heightBond,
        diameterValue: values.diameter === '' ? null : values.diameter,
        barrelVolumeValue: values.totalVol === '' ? null : values.totalVol,
        emptyHeightValue: values.emptyHeight,
        liquidHeightValue: null,
        volumeUnit: values.volumeUnit,
        lengthUnit: values.lengthUnit,
        volumePrecision: decimalsMask[values.volumeUnit],
        lengthPrecision: decimalsMask[values.lengthUnit],
      });
    }
    return results;
  };

  const defaultFormValues = {
    totalVol: '',
    heightBond: '',
    diameter: '',
    liquidHeight: '',
    emptyHeight: '',
    isLiquidHeight: true,
    volumeUnit: globalState.userUnits.volumeUnit,
    lengthUnit: globalState.userUnits.lengthUnit,
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <BoxetteForm
        applyHistoryForResults
        decimalsMask={decimalsMask}
        boxetteCall={boxetteCall}
        defaultFormValues={defaultFormValues}
        savedUnits={['volumeUnit', 'lengthUnit']}
        formNameInStorage="ullaging_standing_form"
        validationSchema={() => validationSchema(globalState, t)}
        form={<InnerForm />}
      />
      <Typography align="center" component="i" variant="subtitle1">
        {t('ullaging.about_standing_text', { joinArrays: '' })}
      </Typography>
    </Box>
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, setResults, resultsAvailable, backupSave,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [globalState] = useGlobal();

  const selectIsLiquidHeight = (setFieldValue) => {
    setFieldValue('isLiquidHeight', true);
  };
  const selectIsNotLiquidHeight = (setFieldValue) => {
    setFieldValue('isLiquidHeight', false);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 600,
      }}
    >
      <img
        src={barreldimensions}
        style={{ width: '75%', height: 'auto', alignSelf: 'center' }}
        alt="barrel-dimensions"
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('ullaging.total_volume')}</Typography>
        }
        input={(
          <NumberInput
            id="totalVol"
            name="totalVol"
            value={formik.values.totalVol}
            placeholder="ex: 351"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.volumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.volumeUnit);
            }}
          />
        )}
        error={
          formik.touched.totalVol
          && formik.errors.totalVol && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.totalVol}
            </Typography>
          )
        }
        select={(
          <VolumeUnitPicker
            unit={formik.values.volumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('volumeUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />
      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('ullaging.diameter')}
          </Typography>
        )}
        input={(
          <NumberInput
            id="diameter"
            name="diameter"
            value={formik.values.diameter}
            placeholder="ex: 75.9"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.lengthUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.lengthUnit);
            }}
          />
        )}
        error={
          formik.touched.diameter
          && formik.errors.diameter && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.diameter}
            </Typography>
          )
        }
        select={(
          <LengthUnitPicker
            unit={formik.values.lengthUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('lengthUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />

      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('ullaging.height_bond')}
          </Typography>
        )}
        input={(
          <NumberInput
            id="heightBond"
            name="heightBond"
            value={formik.values.heightBond}
            placeholder="ex: 88"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.lengthUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.lengthUnit);
            }}
          />
        )}
        error={
          formik.touched.heightBond
          && formik.errors.heightBond && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.heightBond}
            </Typography>
          )
        }
        select={(
          <LengthUnitPicker
            unit={formik.values.lengthUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('lengthUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />
      <RadioBoxetteInput
        isselected={formik.values.isLiquidHeight}
        onFocused={() => selectIsLiquidHeight(formik.setFieldValue)}
        label={(
          <Typography component="span">
            {t('ullaging.liquid_height')}
          </Typography>
        )}
        input={(
          <NumberInput
            inputcolor={formik.values.isLiquidHeight ? 'white' : '#F2F5F8'}
            id="liquidHeight"
            name="liquidHeight"
            value={formik.values.liquidHeight}
            placeholder="ex: 45.5"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.lengthUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.lengthUnit);
            }}
          />
        )}
        error={
          formik.touched.liquidHeight
          && formik.errors.liquidHeight && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.liquidHeight}
            </Typography>
          )
        }
        select={(
          <LengthUnitPicker
            unit={formik.values.lengthUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('lengthUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />
      <RadioBoxetteInput
        isselected={!formik.values.isLiquidHeight}
        onFocused={() => selectIsNotLiquidHeight(formik.setFieldValue)}
        label={
          <Typography component="span">{t('ullaging.empty_height')}</Typography>
        }
        input={(
          <NumberInput
            inputcolor={!formik.values.isLiquidHeight ? 'white' : '#F2F5F8'}
            id="emptyHeight"
            name="emptyHeight"
            value={formik.values.emptyHeight}
            placeholder="ex: 20.2"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.lengthUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.lengthUnit);
            }}
          />
        )}
        error={
          formik.touched.emptyHeight
          && formik.errors.emptyHeight && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.emptyHeight}
            </Typography>
          )
        }
        select={(
          <LengthUnitPicker
            unit={formik.values.lengthUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('lengthUnit', newUnit);
              setResults({});
            }}
          />
        )}
      />
      <Box sx={{ display: 'flex', justifyContent: 'stretch', gap: 1 }}>
        <Box sx={{ flexGrow: 5 }}>
          <CreditLoadingButton
            calculating={calculating}
            isValid={formik.isValid}
            cost={1}
            text={t('forms.calcul')}
          />
        </Box>
        <Button
          color="secondary"
          variant="contained"
          sx={{ flexGrow: 1 }}
          onClick={backupSave}
        >
          <SaveAsIcon />
        </Button>
      </Box>
      <ResultCard
        title={t('ullaging.result_volume')}
        result={resultsAvailable()
          ? `${results.liquidVolume.value} ${dynamicUnitName(results.liquidVolume.unit)}`
          : '-'}
        actions={resultsAvailable() ? toVolConvActions(
          t,
          navigate,
          results.liquidVolume.value,
          results.liquidVolume.unit,
        ) : undefined}
      />

    </form>
  );
}
