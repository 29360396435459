import React from 'react';
import Box from '@mui/material/Box';
import LaboxAlco from 'icons/LaboxAlco';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import Footer from './Footer';

export default function LoadingPage() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  let width;
  if (isSmallScreen) {
    width = '80%';
  } else if (isMediumScreen) {
    width = '60%';
  } else {
    width = '500px';
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LaboxAlco width={width} />
      <CircularProgress />
      <Footer minimal />
    </Box>
  );
}
