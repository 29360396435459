import React from 'react';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HomeContent from 'components/HomeContent';
import ecclogo from 'files/ecc-logo.png';
import evelyne from 'files/evelyne.jpg';
import OIMLConformity from 'components/OIMLConformity';
import { ContactCard, OrangeText, DoubleText } from './ContactCard';

export default function AboutUs(props) {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center' }}>
      <HomeContent />
      <Typography sx={{ mt: 5 }} variant="h3" component="span" gutterBottom>
        {t('about_us.creator')}
      </Typography>
      <ContactCard
        images={(
          <>
            <img
              src={ecclogo}
              style={{
                width: 100, height: 'auto', padding: 5, marginRight: 10,
              }}
              alt="ecc-logo"
            />
            <img
              src={evelyne}
              style={{
                width: 100, height: 'auto', padding: 5, marginRight: 10,
              }}
              alt="evelyne"
            />
          </>
      )}
        text={(
          <>
            <Typography variant="body2" color="textSecondary" component="span">
              Contact: Evelyne Chanson -
              {' '}
              {t('experts.evelyne')}
            </Typography>
            <br />
            <OrangeText text={t('experts.advice')} />
            <DoubleText text1={t('experts.choice')} text2={t('experts.choice_adt')} />
            <DoubleText text1={t('experts.practice')} text2={t('experts.practice_adt')} />
            <DoubleText text1={t('experts.expert')} text2={t('experts.expert_adt')} />
            <DoubleText text1={t('experts.formation')} text2={t('experts.formation_adt')} />
          </>
        )}
      />
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        href="/experts"
        style={{ marginTop: 30 }}
      >
        {t('experts.experts_link')}
      </Button>
      <OIMLConformity />
    </div>
  );
}
