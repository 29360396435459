import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import { useFunctions } from 'reactfire';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import { Link } from 'react-router-dom';
import tierSubscriptions from './tierSubscriptions';
import VSCard from './VSCard';
import VSOPCard from './VSOPCard';
import XOCard from './XOCard';
import FreeCard from './FreeCard';

export default function TierSubscriptionDisplays() {
  const { t } = useTranslation();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const analytics = getAnalytics();
  const [globalState, globalActions] = useGlobal();
  const checkoutSession = httpsCallable(functions, 'checkoutSessionTierPrices');
  const [loadingStatus, setloadingStatus] = useState('initial');

  const subscriptionsInfo = tierSubscriptions();

  const handleCheckout = async (subscriptionType) => {
    logEvent(analytics, 'clicked_checkout_session', {
      appName: 'Alcoholometry',
      organization: globalState.activeOrganization,
    });
    try {
      setloadingStatus('loading');
      const result = await checkoutSession({
        prices: subscriptionsInfo[subscriptionType],
        fbo: globalState.activeOrganization || undefined,
      });
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
    } finally {
      setloadingStatus('loaded');
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        mt: 3,
        gap: { xs: 3, lg: 1 },
        justifyContent: 'center',
        alignItems: { xs: 'center', lg: 'stretch' },
        flexWrap: { sm: 'wrap', lg: 'nowrap' },
      }}
      >
        <FreeCard />
        <VSCard loadingStatus={loadingStatus} handleCheckout={handleCheckout} />
        <VSOPCard loadingStatus={loadingStatus} handleCheckout={handleCheckout} />
        <XOCard />
      </Box>
      <Card sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
      }}
      >
        <Typography component="span" sx={{ mb: -1 }}>
          {t('subscriptions.credit_card_or_google')}
          :
        </Typography>
        <Typography component="span" variant="caption" sx={{ mt: -1 }}>
          {t('welcome.accept_by_continuing')}
          {' '}
          <MUILink
            component={Link}
            to="/cgv"
          >
            <b>{t('subscriptions.cgv')}</b>
          </MUILink>
        </Typography>
        <Typography component="span" variant="body2" sx={{ width: '100%', mb: 3 }}>
          {t('subscriptions.other_payment')}
          {' '}
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Typography>
      </Card>
    </Box>
  );
}
