import React from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function ActivateCodeToUser() {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [activating, setactivating] = React.useState(false);
  const [globalState, globalActions] = useGlobal();

  const activateCodeToUserOrOrgCall = httpsCallable(functions, 'activateCodeToUserOrOrgCall');

  return (
    <Formik
      initialValues={{ code: '', userEmail: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.code) {
          errors.code = t('validations.required');
        } if (!values.userEmail) {
          errors.userEmail = t('validations.required');
        } else if (
          !/^[A-Z0-9a-z-]*$/i.test(values.code)
        ) {
          errors.code = t('validations.code_invalid');
        } else if (
          !/^(.+)@(.+)$/i.test(values.userEmail)
        ) {
          errors.userEmail = 'format invalide';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setactivating(true);
          await activateCodeToUserOrOrgCall({ codeUid: values.code, userEmail: values.userEmail });
          globalActions.setSnackbarMessage({ message: t('codes.success'), severity: 'success' });
        } catch (error) {
          if (error.message === 'INTERNAL') {
            globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
          } else if (error.message === 'CodeAlreadyActivated') {
            globalActions.setSnackbarMessage({ message: t('validations.code_already_activated'), severity: 'error' });
          } else {
            globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
          }
          setSubmitting(false);
          logEvent(analytics, 'code_activation_error', {
            appName: 'Alcoholometry',
            organization: globalState.activeOrganization,
            error_message: error.message,
          });
        } finally {
          setactivating(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 2,
            padding: 3,
          }}
          onSubmit={handleSubmit}
        >
          <Typography component="span" variant="h6">
            Activer un code à un utilisateur
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="userEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.userEmail}
            label="Email de l'utilisateur"
            error={touched.userEmail && Boolean(errors.userEmail)}
            helperText={errors.userEmail && touched.userEmail && errors.userEmail}
          />
          <TextField
            size="small"
            fullWidth
            name="code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.code}
            label="code"
            error={touched.code && Boolean(errors.code)}
            helperText={errors.code && touched.code && errors.code}
          />
          <LoadingButton
            size="medium"
            loading={activating}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            {t('codes.activate')}
          </LoadingButton>
        </Paper>
      )}
    </Formik>
  );
}
