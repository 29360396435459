import * as yup from 'yup';

export default function validationRectifySO2Schema(t, fromActiveSO2) {
  return yup.object().shape({
    volumeInValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.volume_validation'))
      .required(t('validations.volume_validation'))
      .min(0, t('validations.volume_validation'))
      .max(99999999, t('validations.volume_validation')),
    freeSO2ConcentrationInValue: fromActiveSO2
      ? yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      : yup
        .number()
        .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
        .typeError(t('validations.positive_value'))
        .required(t('validations.positive_value'))
        .min(0, t('validations.positive_value'))
        .max(99999999, t('validations.positive_value')),
    freeOrActiveSO2ConcentrationOutValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999, t('validations.positive_value')),
    combinedRateValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.values_between', { min: 0, max: 100 }))
      .required(t('validations.values_between', { min: 0, max: 100 }))
      .min(0, t('validations.values_between', { min: 0, max: 100 }))
      .max(100, t('validations.values_between', { min: 0, max: 100 })),
    totalSO2ConcentrationInValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999, t('validations.positive_value')),
  });
}
