import React, { useContext } from 'react';
import {
  readibleValueAndUnit,
} from 'components/boxettes/UnitDefinitionsAndMasks';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ResultCard from 'components/boxettes/ResultCard';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import orangeVolume from 'files/orange-volume.png';
import blueVolume from 'files/blue-volume.png';
import wineVolume from 'files/wine-volume.png';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import SaveAsIcon from '@mui/icons-material/SaveAs';

export default function Results(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    formik, results, resultsAvailable, backupSave,
  } = useContext(BoxetteFormContext);

  const readibleResultFromName = (name) => {
    if (!resultsAvailable()) {
      return '-';
    }
    return readibleValueAndUnit(results[name].value, results[name].unit);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,

      }}
    >
      <Typography component="span" variant="h6" sx={{ fontWeight: 'bold' }}>
        {t('reduction_improvement.volume_20C')}
      </Typography>

      <ImageResultCard
        imageSource={orangeVolume}
        text1={t('reduction_improvement.result_volume_start')}
        result1={readibleResultFromName('volumeStart')}
        text2={t('forms.mass')}
        result2={readibleResultFromName('massStart')}
        tav={readibleValueAndUnit(formik.values.tavStart, formik.values.alcoholUnit)}
        volumeUnit={results?.volumeStart?.unit || '-'}
      />
      <AddIcon />
      <ImageResultCard
        imageSource={blueVolume}
        text1={t('reduction_improvement.result_volume_added')}
        result1={readibleResultFromName('volumeAdded')}
        text2={t('forms.mass')}
        result2={readibleResultFromName('massAdded')}
        tav={readibleValueAndUnit(formik.values.tavAdded, formik.values.alcoholUnit)}
        volumeUnit={results?.volumeStart?.unit || '-'}
      />
      <ArrowDownwardIcon />
      <ImageResultCard
        imageSource={wineVolume}
        text1={t('reduction_improvement.result_volume_final')}
        result1={readibleResultFromName('volumeFinal')}
        text2={t('forms.mass')}
        result2={readibleResultFromName('massFinal')}
        tav={readibleValueAndUnit(formik.values.tavFinal, formik.values.alcoholUnit)}
        volumeUnit={results?.volumeStart?.unit || '-'}
      />
      <ResultCard
        title={t('reduction_improvement.contraction_factor')}
        result={resultsAvailable() ? results.contractionFactor.value : '-'}
      />

      <Box sx={{
        display: 'flex', justifyContent: 'stretch', gap: 1, width: '100%',
      }}
      >
        <Box sx={{ flexGrow: 5 }}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate('/boxettes');
            }}
          >
            {t('boxettes.return_menu')}
          </Button>
        </Box>
        <Button
          color="secondary"
          variant="contained"
          sx={{ flexGrow: 1 }}
          onClick={backupSave}
        >
          <SaveAsIcon />
        </Button>
      </Box>
    </Box>
  );
}

function ImageResultCard(props) {
  const {
    imageSource, text1, result1, text2, result2, tav, volumeUnit,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  function toVolConvActions(volume, unit) {
    if (isNaN(volume)) {
      return undefined;
    }
    return [toVolConvAction(volume, unit)];
  }

  function toVolConvAction(volume, unit) {
    if (isNaN(volume)) {
      return undefined;
    }
    const callback = () => {
      sessionStorage.removeItem('current_form:tav_form');
      sessionStorage.removeItem('current_form:tav_from_mv_form');
      sessionStorage.removeItem('current_form:volume_conv_form');
      sessionStorage.removeItem('current_form:volume_pa_conv_form');
      navigate('/boxettes/vol-conv', {
        state: {
          volumeValue: volume,
          volumeUnit: unit,
          measuredTav: tav.split(' ')[0],
          alcoholUnit: 'TAV',
          measuredTemp: '20',
          temperatureUnit: 'celsius',
        },
      });
    };
    return { title: t('forms.toward_volume_conversion'), callback };
  }

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      gap: 1,
      width: '100%',
    }}
    >
      <img
        src={imageSource}
        style={{
          flexGrow: 1,
          maxWidth: 100,
          objectFit: 'contain',
        }}
        alt={imageSource.toString()}
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        width: '100%',
        flexGrow: 2,
      }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            p: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="span">
            {tav}
          </Typography>
        </Paper>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          flexWrap: 'wrap',
        }}
        >
          <Box sx={{
            flexGrow: 1,
          }}
          >
            <ResultCard
              title={text1}
              result={result1}
              actions={toVolConvActions(
                result1.split(' ')[0],
                volumeUnit,
              )}
            />
          </Box>

          <Box sx={{
            flexGrow: 1,
          }}
          >
            <ResultCard
              sx={{
                flexGrow: 1,
              }}
              title={text2}
              result={result2}
              infoButtonText={t('reduction_improvement.mass_res_info')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
