import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import CloudUpload from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import modelData from 'files/modele_tav_volume.csv';
import useGlobal from 'global-state/store';
import * as Papa from 'papaparse';
import Paper from '@mui/material/Paper';
import DataDropzone from './DataDropzone';

export default function TAVVolume(props) {
  const { t } = useTranslation();
  const [loadingStatus, setloadingStatus] = React.useState('initial');
  const [data, setdata] = React.useState(undefined);
  const { abvConvVolumeConv, units } = props;
  const [, globalActions] = useGlobal();
  const [columnDelimiter, setColumnDelimiter] = useState(';');
  const [decimalSeparator, setDecimalSeparator] = useState(',');

  const handleCSV = (csv) => {
    const dataArray = csv.data;
    const newDataArray = dataArray.map((element) => ({
      unit_volume: units.volumeUnit,
      unit_tav: units.abvUnit,
      unit_temperature: units.temperatureUnit,
      ...element,
    }));
    setdata(Papa.unparse(newDataArray, { delimiter: columnDelimiter }));
  };

  async function upload() {
    try {
      setloadingStatus('loading');
      await abvConvVolumeConv({ data, columnDelimiter, decimalSeparator });
    } catch (error) {
      console.log(error);
    } finally {
      setloadingStatus('done');
    }
  }
  return (
    <Paper sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 3,
    }}
    >
      <Typography variant="h5" component="div">
        {t('automated.tav_vol')}
      </Typography>

      <DataDropzone
        headers={['reference',
          'mesure_tav',
          'mesure_volume',
          'temp_tav',
          'temp_volume']}
        parsedCsvCallback={handleCSV}
        errorHandler={(err) => globalActions.setSnackbarMessage({ message: err.message, severity: 'error' })}
        modelData={modelData}
        columnDelimiter={columnDelimiter}
        setColumnDelimiter={setColumnDelimiter}
        decimalSeparator={decimalSeparator}
        setDecimalSeparator={setDecimalSeparator}
      />

      <LoadingButton
        sx={{
          maxWidth: 200,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
        loading={loadingStatus === 'loading'}
        variant="contained"
        onClick={() => upload()}
        size="large"
        disabled={data === undefined}
        endIcon={<CloudUpload />}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {t('calcul')}
        </Typography>
      </LoadingButton>
    </Paper>
  );
}
