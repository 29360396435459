function significantNonDecimals(value) {
  // negative significantNonDecimals means that first non-null digit is a decimal
  // example : significantNonDecimals of 0.001 is -2
  // (there are 2 0s in decimals before first significant digit)
  return value === 0 ? 1 : Math.floor(Math.log10(Math.abs(value))) + 1;
}

function precisionFromDecimals(value, decimals) {
  // how many DIGIT (/!\ not _decimals_) can we trust
  const signifNonDecimals = significantNonDecimals(value);
  return Math.max(signifNonDecimals + decimals, 1);
}

function round(value, precision) {
  return value.toPrecision(precision);
}

function roundNoFixed(value, precision) {
  return Number(round(value, precision));
}

function roundNoFixedDecimals(value, decimals) {
  const decimalFix = `${1}e${decimals}`;
  return Number(Math.round((value + Number.EPSILON) * decimalFix) / decimalFix);
}

function roundDecimals(value, decimals) {
  return roundNoFixedDecimals(Number(value), decimals).toFixed(decimals);
}

function roundStep(value, step) {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

module.exports = {
  significantNonDecimals,
  precisionFromDecimals,
  roundNoFixedDecimals,
  roundNoFixed,
  roundDecimals,
  round,
  roundStep,
};
