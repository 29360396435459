import React from 'react';
import Box from '@mui/material/Box';

export default function WPIFrame(props) {
  const { url, height, fullUrl } = props;
  // const [iFrameHeight, setiFrameHeight] = React.useState('80vh');

  // Don't know why that does not work well
  // React.useEffect(() => {
  //   function getHeight() {
  //     const iFrameDocument = document.getElementById('iframe').contentWindow.document;
  //     const theHeight = iFrameDocument.body.scrollHeight;
  //     setiFrameHeight(`'${theHeight}px'`);
  //     console.log(theHeight);
  //   }
  //   getHeight();
  // }, []);

  const frameUrl = fullUrl || `https://alcoholometry-docs.labox-apps.com${url}`;

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: height,
        overflow: 'hidden',
      }}
    >
      <Box
        component="iframe"
        id="iframe"
        sx={{
          margin: 0,
          padding: 0,
          left: { xs: 0, sm: 219 },
          right: 0,
          top: 30,
          position: 'absolute',
          border: 'none',
          width: { xs: '100%', sm: 'calc(100% - 219px)' },
          height,
          overflow: 'hidden',
        }}
        title={url}
        src={frameUrl}
      />
    </Box>
  );
}
