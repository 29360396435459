import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import seoConfig from '../config/seo';

export default function SEO() {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Get the current route's SEO config or fall back to default
  const currentPath = location.pathname.substring(1) || 'default';
  const config = seoConfig[currentPath] || seoConfig.default;

  const baseUrl = 'https://alcoholometry.labox-apps.com';
  const currentUrl = `${baseUrl}${config.pathname}`;

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: t(config.titleKey),
    description: t(config.descriptionKey),
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'Web',
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/OnlineOnly',
    },
    author: {
      '@type': 'Organization',
      name: 'Labox Applications',
      url: 'https://alcoholometry.labox-apps.com',
    },
  };

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{t(config.titleKey)}</title>
      <meta name="description" content={t(config.descriptionKey)} />
      <link rel="canonical" href={currentUrl} />

      {/* Language alternates for same URL */}
      <link rel="alternate" hrefLang="fr" href={currentUrl} />
      <link rel="alternate" hrefLang="en" href={currentUrl} />
      <link rel="alternate" hrefLang="de" href={currentUrl} />
      <link rel="alternate" hrefLang="es" href={currentUrl} />
      <link rel="alternate" hrefLang="pt" href={currentUrl} />
      <link rel="alternate" hrefLang="pl" href={currentUrl} />
      <link rel="alternate" hrefLang="x-default" href={currentUrl} />

      {/* Open Graph */}
      <meta property="og:locale" content={i18n.language === 'fr' ? 'fr_FR' : 'en_GB'} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={t(config.titleKey)} />
      <meta property="og:description" content={t(config.descriptionKey)} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Labox Applications" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={t(config.titleKey)} />
      <meta name="twitter:description" content={t(config.descriptionKey)} />

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>

      {/* Additional SEO */}
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={t('seo.keywords')} />
    </Helmet>
  );
}
