import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import TemperatureUnitPicker from 'components/units/TemperatureUnitPicker';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { roundDecimals } from 'components/boxettes/round';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import validationMVSchema from './ValidationMVSchema';
import decimalsMask from './DecimalsMask';

export default function AlcoConversionTAVForm(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { convertABVToTemperatureFromVolumetricMass, actions } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (temperatureUnit, densityUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(globalState.userUnits.alcoholUnit, temperatureUnit, densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await convertABVToTemperatureFromVolumetricMass(
      roundDecimals(values.measuredMV.replace(/,/, '.'), decimalsMask[values.densityUnit]),
      values.densityUnit,
      roundDecimals(values.measuredTemp.replace(/,/, '.'), decimalsMask[values.temperatureUnit]),
      values.temperatureUnit,
      roundDecimals(globalState.userUnits.temperatureValue.replace(/,/, '.'), 2),
      globalState.userUnits.alcoholUnit,
      decimalsMask[values.temperatureUnit],
      decimalsMask[values.temperatureUnit],
      decimalsMask[values.densityUnit],
    );

    return results;
  };

  const defaultFormValues = {
    measuredMV: '',
    densityUnit: globalState.userUnits.densityUnit,
    measuredTemp: '',
    temperatureUnit: globalState.userUnits.temperatureUnit,
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="tav_from_mv_form"
      validationSchema={() => validationMVSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
          actions={actions}
        />
)}
    />

  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results,
  } = React.useContext(BoxetteFormContext);
  const { actions, setAlcoholUnitRelatedsFrom } = props;
  const { t } = useTranslation();
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_mv')}</Typography>
        }
        input={(
          <NumberInput
            onChange={handleChange}
            name="measuredMV"
            placeholder="ex: 801.22"
            value={formik.values.measuredMV}
            decimalScale={decimalsMask[formik.values.densityUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.densityUnit);
            }}
          />
        )}
        error={formik.touched.measuredMV && formik.errors.measuredMV && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.measuredMV}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.densityUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                formik.values.temperatureUnit,
                newUnit,
              );
              await formik.setFieldValue('densityUnit', newUnit);
            }}
          />
        )}
      />

      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.measured_temp_ff')}</Typography>
        }
        input={(
          <NumberInput
            name="measuredTemp"
            onChange={handleChange}
            placeholder="ex: 12.2"
            value={formik.values.measuredTemp}
            decimalScale={decimalsMask[formik.values.temperatureUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.temperatureUnit);
            }}
            allowNegative
          />
        )}
        error={formik.touched.measuredTemp && formik.errors.measuredTemp && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.measuredTemp}
        </Typography>
        )}
        select={(
          <TemperatureUnitPicker
            unit={formik.values.temperatureUnit}
            setUnit={async (newUnit) => {
              await setAlcoholUnitRelatedsFrom(
                newUnit,
                formik.values.densityUnit,
              );
              await formik.setFieldValue('temperatureUnit', newUnit);
            }}
          />
        )}
      />

      {React.cloneElement(actions, {
        formikSubmitForm: formik.submitForm,
        calculating,
        formikIsValid: formik.isValid,
        formikValues: formik.values,
        alcoholConversionResults: results,
      })}
    </form>
  );
}
