import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationActiveSO2Schema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
      densityUnit: dynamicUnitName(alcoholUnitRelateds.densityUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  const twoDecimExp = /^-?\d+(?:\.\d{1,2})?$/;

  return yup.object().shape({
    abvAt20cValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
      .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase)
      .test('nb-of-decimal', validationPhrases.tavValidationPhrase, (value) => (`${value}`).match(twoDecimExp)),
    pHValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.values_between', { min: 0, max: 14 }))
      .required(t('validations.values_between', { min: 0, max: 14 }))
      .min(0, t('validations.values_between', { min: 0, max: 14 }))
      .max(14, t('validations.values_between', { min: 0, max: 14 }))
      .test(
        'nb-of-decimal',
        t('validations.values_between', { min: 0, max: 14 }),
        (value) => (`${value}`).match(twoDecimExp),
      ),
    temperatureInValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase)
      .test('nb-of-decimal', validationPhrases.tempValidationPhrase, (value) => (`${value}`).match(twoDecimExp)),
    freeSO2ConcentrationInValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999, t('validations.positive_value'))
      .test('nb-of-decimal', t('validations.positive_value'), (value) => (`${value}`).match(twoDecimExp)),
  });
}
