/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Arrow from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';

export default function ForwardButton(props) {
  const {
    calculating,
    isValid,
    text,
    handleClick,
  } = props;

  return (
    <LoadingButton
      loading={calculating}
      color="secondary"
      variant="contained"
      disabled={!isValid}
      onClick={handleClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 99,
      }}
      >
        <Typography sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        >
          {text}
        </Typography>
      </Box>
      <Arrow sx={{ flexGrow: 1, textAlign: 'right', color: '#ffffff' }} />
    </LoadingButton>
  );
}
