import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function StoragePDFViewer(props) {
  const { path, loadedCallback = () => {}, actionButtons = [] } = props;
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileError, setFileError] = useState('');
  const [scale, setScale] = useState(1);
  const [rescaled, setRescaled] = useState(false);
  // const [pdfDimensions, setPdfDimensions] = useState();
  // const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  // const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const innerWidth = useMemo(() => window.innerWidth, []);
  const innerHeight = useMemo(() => window.innerHeight, []);

  const updateScale = useCallback(({ width, height }) => {
    const pageAspectRatio = width / height;
    const windowAspectRatio = innerWidth / innerHeight;
    setRescaled(true);
    if (windowAspectRatio > pageAspectRatio) {
      const newScale = innerHeight * 75 / 100 / height;
      setScale(newScale);
    } else {
      const newScale = innerWidth * 90 / 100 / width;
      setScale(newScale);
    }
  }, [innerHeight, innerWidth]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setInnerWidth(window.innerWidth);
  //     setInnerHeight(window.innerHeight);
  //     updateScale(pdfDimensions);
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [pdfDimensions, updateScale]);

  const onDocumentLoadSuccess = (obj) => {
    setNumPages(obj.numPages);
    loadedCallback();
    setFileError('');
  };

  const onPageLoadSuccess = ({ width, height }) => {
    if (!rescaled) {
      // setPdfDimensions({ width, height });
      updateScale({ width, height });
    }
  };

  const onDocumentLoadError = (error) => {
    console.error('Doc Load Error: ', error);
    setFileError(error.message);
  };

  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', mb: 3,
    }}
    >
      {fileError.length === 0 && (
      <Box sx={{
        maxWidth: '100%', display: 'flex', flexDirection: 'column', gap: 1,
      }}
      >
        <Box sx={{ mb: 3 }}>
          <Box sx={{
            border: 1,
            borderColor: 'primary.main',
            maxWidth: '100%',
            overflow: 'auto',
          }}
          >
            <Document
              file={path}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            >
              <Page
                pageNumber={pageNumber}
                renderTextLayer={false}
                scale={scale}
                onLoadSuccess={onPageLoadSuccess}
              />
            </Document>
          </Box>
        </Box>
        {numPages > 1 && (
          <Box sx={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center', mb: 3,
          }}
          >
            <Button
              disabled={pageNumber === 1}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setPageNumber((prevNumber) => prevNumber - 1)}
            >
              {t('navigation.previous_page')}
            </Button>
            Page
            {' '}
            {pageNumber}
            {' '}
            /
            {' '}
            {numPages}
            <Button
              disabled={pageNumber === numPages}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setPageNumber((prevNumber) => prevNumber + 1)}
            >
              {t('navigation.next_page')}
            </Button>
          </Box>
        )}
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 2,
        }}
        >
          {actionButtons.map((action) => (
            <Button
              key={action.name}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => action.action()}
              endIcon={action.icon}
            >
              {action.name}
            </Button>
          ))}
        </Box>
      </Box>
      )}
      {fileError.length > 0 && <Alert severity="error">{fileError}</Alert>}
    </Box>
  );
}
