import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function LengthUnitPicker(props) {
  const { unit, setUnit } = props;
  return (
    <FormControl variant="outlined">
      <Select
        style={{ width: 130 }}
        defaultValue={unit}
        id="length-select"
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
      >
        <MenuItem value="cm">cm</MenuItem>
        <MenuItem value="in">in</MenuItem>
        <MenuItem value="ft">ft</MenuItem>
      </Select>
    </FormControl>
  );
}
