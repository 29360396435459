import React from 'react';
import { navigatorNetworkState } from 'components/utils';
import useGlobalHook from './useGlobalHook';
import * as actions from './actions';

function userUnitsFromLocalStorage() {
  const units = localStorage.getItem('user_units_config_v2');
  if (!units) {
    return {
      name: 'eu',
      volumeUnit: 'L',
      temperatureUnit: 'celsius',
      temperatureValue: '20.0',
      lengthUnit: 'cm',
      massUnit: 'kg',
      alcoholUnit: 'TAV',
      densityUnit: 'g / L',
    };
  }
  return JSON.parse(units);
}

const initialState = {
  cookiesAccepted: true,
  userUnits: userUnitsFromLocalStorage(),
  globalSnackbar: { display: false, mesage: '', severity: 'info' },
  roles: [],
  issuers: [],
  userStatus: 'initial',
  activeOrganization: '',
  userOrganizations: {},
  alcoApiConfig: { intermediateRounding: true },
  curentDeeplink: undefined,
  accessGrants: [],
  accessGrantNames: [],
  networkState: navigatorNetworkState(),
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
