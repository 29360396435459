import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import DensityUnitPicker from 'components/units/DensityUnitPicker';
import { Paper } from '@mui/material';
import decimalsMask from './DecimalsMask';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';

export default function ProductToMute(props) {
  const {
    formik, handleChange, handleBlur, setResults,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: 1,
        p: 2,
      }}
    >
      <Typography>
        {t('mutage.product_to_mute')}
      </Typography>
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.volume_value')}</Typography>
          }
        input={(
          <NumberInput
            disabled={['1', '3'].includes(formik.values.useCase)}
            id="MoutVolumeValue"
            name="MoutVolumeValue"
            value={formik.values.MoutVolumeValue}
            placeholder="ex: 100"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.MoutVolumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.MoutVolumeUnit);
            }}
          />
          )}
        error={
            formik.touched.MoutVolumeValue
            && formik.errors.MoutVolumeValue && (
              <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
                {formik.errors.MoutVolumeValue}
              </Typography>
            )
          }
        select={(
          <VolumeUnitPicker
            unit={formik.values.MoutVolumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('MoutVolumeUnit', newUnit);
              setResults({});
            }}
          />
          )}
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.with_tav')}</Typography>
        }
        input={(
          <NumberInput
            onChange={handleChange}
            name="MoutABVValue"
            placeholder="ex: 56.88"
            value={formik.values.MoutABVValue}
            decimalScale={decimalsMask[formik.values.MoutABVUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.MoutABVUnit);
            }}
          />
        )}
        error={formik.touched.MoutABVValue && formik.errors.MoutABVValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.MoutABVValue}
        </Typography>
        )}
        select={(
          <Typography>
            {dynamicUnitName(formik.values.MoutABVUnit)}
          </Typography>
        )}
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.sugar')}</Typography>
          }
        input={(
          <NumberInput
            onChange={handleChange}
            name="MoutSugarValue"
            placeholder="ex: 220"
            value={formik.values.MoutSugarValue}
            decimalScale={decimalsMask[formik.values.MoutSugarUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.MoutSugarUnit);
            }}
          />
          )}
        error={formik.touched[formik.values.MoutSugarValue] && formik.errors[formik.values.MoutSugarValue] && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors[formik.values.MoutSugarValue]}
        </Typography>
        )}
        select={(
          <DensityUnitPicker
            unit={formik.values.MoutSugarUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('MoutSugarUnit', newUnit);
            }}
          />
          )}
      />
    </Paper>
  );
}
