import React, { useState } from 'react';
import {
  Box, Typography, Switch, Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';

export default function AlcoApiConfig() {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [intermediateRounding, setIntermediateRounding] = useState(globalState.alcoApiConfig.intermediateRounding);

  const handleToggle = () => {
    globalActions.setAlcoApiConfig({ intermediateRounding: !intermediateRounding });
    setIntermediateRounding(!intermediateRounding);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        maxWidth: 1600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        gap: 3,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t('settings.intermediate_rounding')}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {t('settings.intermediate_rounding_explanation')}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {t('settings.intermediate_rounding')}
          :
        </Typography>
        <Switch
          checked={intermediateRounding}
          onChange={handleToggle}
          color="primary"
        />
      </Box>
    </Paper>
  );
}
