import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function validationSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: 0,
      tavMax: 17,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: 88,
      tempMax: 101,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  return yup.object().shape({
    temperatureUnit: yup.string().required(),
    abvUnit: yup.string().required(),
    referenceProductAbvAt20cValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(0, validationPhrases.tavValidationPhrase)
      .max(17, validationPhrases.tavValidationPhrase),
    referenceProductBoilingPointValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(88, validationPhrases.tempValidationPhrase)
      .max(101, validationPhrases.tempValidationPhrase),
    sampleProductBoilingPointValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(88, validationPhrases.tempValidationPhrase)
      .max(101, validationPhrases.tempValidationPhrase),
  });
}
