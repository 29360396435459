const mask = {
  m3: 2,
  hL: 3,
  L: 2,
  dL: 1,
  cL: 0,
  mL: 0,
  barrelUS: 3,
  barrelUK: 3,
  flozUK: 2,
  flozUS: 2,
  galUK: 3,
  galUS: 3,
  giUK: 3,
  giUS: 3,
  pintUK: 3,
  pintUS: 3,
  qtUK: 3,
  qtUS: 3,
  celsius: 2,
  fahrenheit: 2,
  cm: 1,
  in: 2,
  ft: 2,
};

module.exports = mask;
