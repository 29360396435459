import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import RectifySO2Form from './RectifySO2Form';

export default function ActiveSO2Boxette(props) {
  const { convertActiveSO2, rectifySO2 } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [activeSO2Values] = useState(
    location.state?.activeSO2Values,
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    }}
    >
      <Typography variant="h6" component="span" sx={{ mt: 3 }}>
        {t('so2.active_rectification')}
      </Typography>
      <RectifySO2Form
        rectifySO2={rectifySO2}
        convertActiveSO2={convertActiveSO2}
        activeSO2Values={activeSO2Values}
        fromActiveSO2
      />
      <Typography align="left" component="i">
        {t('so2.free_so2_info', { joinArrays: '' })}
      </Typography>
    </Box>

  );
}
