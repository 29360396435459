import React from 'react';
import {
  dynamicUnitName,
  readibleValueAndUnit,
} from 'components/boxettes/UnitDefinitionsAndMasks';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ResultCard from 'components/boxettes/ResultCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';

export default function VolumePAConversionResults(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [globalState] = useGlobal();

  const {
    alcoholConversionResults,
    volumeConversionResults,
    values,
  } = props;

  const volumeAtTempResult = () => {
    if (!resultAvailable()) {
      return '-';
    }
    return readibleValueAndUnit(volumeConversionResults.volumeAtTemp.value, volumeConversionResults.volumeAtTemp.unit);
  };

  const volumeAt20CResult = () => {
    if (!resultAvailable()) {
      return '-';
    }
    return readibleValueAndUnit(
      volumeConversionResults.volumeAt20c.value,
      volumeConversionResults.volumeAt20c.unit,
    );
  };

  const resultAvailable = () => volumeConversionResults && Object.keys(volumeConversionResults).length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        width: '100%',
        maxWidth: 350,
      }}
    >

      <ResultCard
        title={t('forms.tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(
            alcoholConversionResults.ABVAt20c.temperature.unit,
          ),
        })}
        result={
          `${alcoholConversionResults.ABVAt20c.value} ${dynamicUnitName(alcoholConversionResults.ABVAt20c.unit)}`
        }
        infoButtonIcon={<span style={{ fontSize: 14 }}>[1]</span>}
        infoButtonText={t('forms.real_tav_info_1')}
      />

      <ResultCard
        title={t('forms.volume_at_temp')
          + globalState.userUnits.temperatureValue
          + dynamicUnitName(
            globalState.userUnits.temperatureUnit,
          )}
        result={volumeAt20CResult()}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.correction_factor')}
        result={resultAvailable() ? volumeConversionResults.volumeCorrectionFactor.value : '-'}
      />

      <ResultCard
        title={
          t('forms.volume_at_temp')
          + values.temperatureValue
          + temperatureUnitString(values.temperatureUnit)
        }
        result={volumeAtTempResult()}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.mass')}
        result={resultAvailable()
          ? readibleValueAndUnit(volumeConversionResults.mass.value, volumeConversionResults.mass.unit)
          : '-'}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.mass_ap')}
        result={resultAvailable()
          ? readibleValueAndUnit(volumeConversionResults.massAP.value, volumeConversionResults.massAP.unit)
          : '-'}
      />

      <Button
        color="secondary"
        variant="contained"
        style={{ marginTop: 15, marginBottom: 20 }}
        onClick={() => {
          navigate('/boxettes');
        }}
      >
        <Typography component="span">{t('boxettes.return_menu')}</Typography>
      </Button>
    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}
