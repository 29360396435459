import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import useGlobal from 'global-state/store';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export default function DataDropzone(props) {
  const {
    errorHandler, aborthandler, modelData, headers, dataCallback = () => {}, parsedCsvCallback = () => {},
    columnDelimiter, setColumnDelimiter, decimalSeparator, setDecimalSeparator,
  } = props;
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();

  const parseCsv = (csvData) => {
    const parsedCsv = Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimitersToGuess: [';', ','],
    });
    return parsedCsv;
  };

  const createNewCsv = (parsedCsv) => {
    let modifiedRows;
    if (decimalSeparator !== ',') {
      modifiedRows = parsedCsv.data.map(
        (row) => Object.entries(row).reduce((obj, [key, value]) => ({
          ...obj,
          [key]: value?.toString().replace(',', decimalSeparator),
        }), {}),
      );
    } else {
      modifiedRows = parsedCsv.data;
    }
    const modifiedCsv = Papa.unparse(modifiedRows, {
      fields: parsedCsv.meta.fields,
      delimiter: columnDelimiter,
    });
    return modifiedCsv;
  };

  const handleDownload = () => {
    fetch(modelData)
      .then((response) => response.text())
      .then((csvData) => {
        const parsedCsv = parseCsv(csvData);
        const modifiedCsv = createNewCsv(parsedCsv);

        const modifiedBlob = new Blob([modifiedCsv], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(modifiedBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'automated_model.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle fetch error
        console.error('Error retrieving CSV file:', error);
      });
  };

  const callback = (acceptedFiles, setfileName) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      // Only parsing the first line just to get the headers
      const parsedCsv = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        delimitersToGuess: [';', ','],
      });
      if (!parsedCsv.meta.fields.every((v) => headers.includes(v))) {
        globalActions.setSnackbarMessage({ message: t('automated.incorrect_headers'), severity: 'error' });
        return;
      }
      setfileName(file.path);
      dataCallback(reader.result);
      parsedCsvCallback(parsedCsv);
    };
    reader.readAsText(file);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <FileDropzone
        text={t('automated.upload_csv_text')}
        callback={callback}
        accept={{ 'text/csv': ['.csv'] }}
      />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2,
          width: '100%',
        }}
      >
        <Button
          variant="outlined"
          component="span"
          onClick={handleDownload}
          startIcon={<DescriptionIcon />}
        >
          {t('automated.download_model_CSV')}
        </Button>
        <Typography>{`${t('csv.separators')}:` }</Typography>
        <FormControl size="small" sx={{ width: 90 }}>
          <InputLabel id="columnDelimiterLabel">{t('csv.columns')}</InputLabel>
          <Select
            labelId="columnDelimiterLabel"
            id="columnDelimiter"
            value={columnDelimiter}
            onChange={(e) => setColumnDelimiter(e.target.value)}
            label={t('csv.columns')}
          >
            <MenuItem value=",">,</MenuItem>
            <MenuItem value=";">;</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: 90 }}>
          <InputLabel id="decimalSeparatorLabel">{t('csv.decimals')}</InputLabel>
          <Select
            labelId="decimalSeparatorLabel"
            id="columnDelimiter"
            value={decimalSeparator}
            onChange={(e) => setDecimalSeparator(e.target.value)}
            label={t('csv.decimals')}
          >
            <MenuItem value=",">,</MenuItem>
            <MenuItem value=".">.</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
