import React from 'react';
import { Typography, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import homeImage from '../files/home-panorama-table.jpg';
// import googleplayEN from '../files/google-play-badge-en.png';
// import appleEN from '../files/apple-badge-en.png';
// import googleplayFR from '../files/google-play-badge-fr.png';
// import appleFR from '../files/apple-badge-fr.png';

export default function HomeContent() {
  const { t } = useTranslation();

  // function appStoreLink() {
  //   return t('menu_boxettes.app-store-link');
  // }

  // function googlePlayLink() {
  //   return t('menu_boxettes.google-play-link');
  // }

  // function googlePlayImage() {
  //   if (t('locale') === 'fr') {
  //     return googleplayFR;
  //   }
  //   return googleplayEN;
  // }

  // function appStoreImage() {
  //   if (t('locale') === 'fr') {
  //     return appleFR;
  //   }
  //   return appleEN;
  // }

  return (
    <Box>
      <img
        src={homeImage}
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
          height: 400,
          left: 0,
          right: 0,
          top: 0,
          position: 'absolute',
          objectFit: 'cover',
        }}
        alt="Paper table"
      />
      <Box sx={{
        display: 'flex', flexFlow: 'column', gap: 3, mt: 45,
      }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="span" sx={{ mb: 1 }} variant="h5">
            {t('welcome.welcome')}
          </Typography>
          <Typography component="span" sx={{ fontWeight: 'bold' }} variant="h5">
            {t('welcome.title')}
          </Typography>
          <Typography component="span" variant="h6">
            {t('welcome.your_assistant')}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexFlow: 'column',
          gap: 3,
          justifyContent: 'center',
          alignItems: 'flex-start',
          textAlign: 'left',
        }}
        >
          <Typography component="span" variant="subtite1">
            -
            {' '}
            {t('welcome.bullet1')}
          </Typography>
          <Typography component="span" variant="subtite1">
            -
            {' '}
            {t('welcome.bullet2')}
          </Typography>
          <Typography component="span" variant="subtite1">
            -
            {' '}
            {t('welcome.bullet3')}
          </Typography>
        </Box>

        <Typography>
          {t('welcome.contact_message')}
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Typography>
      </Box>
    </Box>
  );
}
