import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { BoxetteForm } from 'components/boxettes/BoxetteForm';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';
import InnerForm from './InnerForm';

export default function MutageBoxette(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { mutage } = props;

  const replaceComma = (value) => {
    if (value === null || value === undefined || value === '') {
      return null;
    }
    return value.toString().replace(/,/, '.');
  };

  const boxetteCall = async (values) => {
    const results = await mutage(
      {
        SpiritVolumeValue: replaceComma(values.SpiritVolumeValue),
        SpiritABVValue: replaceComma(values.SpiritABVValue),
        SpiritSugarValue: replaceComma(values.SpiritSugarValue),
        MoutVolumeValue: replaceComma(values.MoutVolumeValue),
        MoutABVValue: replaceComma(values.MoutABVValue),
        MoutSugarValue: replaceComma(values.MoutSugarValue),
        PmVolumeValue: replaceComma(values.PmVolumeValue),
        PmABVValue: replaceComma(values.PmABVValue),
        PmSugarValue: replaceComma(values.PmSugarValue),

        SpiritVolumeUnit: values.SpiritVolumeUnit,
        SpiritABVUnit: values.SpiritABVUnit,
        SpiritSugarUnit: values.SpiritSugarUnit,

        MoutVolumeUnit: values.MoutVolumeUnit,
        MoutABVUnit: values.MoutABVUnit,
        MoutSugarUnit: values.MoutSugarUnit,

        PmVolumeUnit: values.PmVolumeUnit,
        PmABVUnit: values.PmABVUnit,
        PmSugarUnit: values.PmSugarUnit,
        temperatureUnit: values.temperatureUnit,

        SpiritVolumePrecision: decimalsMask[values.SpiritVolumeUnit],
        SpiritABVPrecision: decimalsMask[values.SpiritABVUnit],
        SpiritSugarPrecision: decimalsMask[values.SpiritSugarUnit],

        MoutVolumePrecision: decimalsMask[values.MoutVolumeUnit],
        MoutABVPrecision: decimalsMask[values.MoutABVUnit],
        MoutSugarPrecision: decimalsMask[values.MoutSugarUnit],

        PmVolumePrecision: decimalsMask[values.PmVolumeUnit],
        PmABVPrecision: decimalsMask[values.PmABVUnit],
        PmSugarPrecision: decimalsMask[values.PmSugarUnit],

      },
    );
    return results;
  };

  const defaultFormValues = {
    useCase: '1',
    SpiritVolumeValue: null,
    SpiritABVValue: null,
    SpiritSugarValue: 0,

    MoutVolumeValue: null,
    MoutABVValue: 0,
    MoutSugarValue: null,

    PmVolumeValue: null,
    PmABVValue: null,
    PmSugarValue: null,

    SpiritVolumeUnit: 'L',
    SpiritABVUnit: 'TAV',
    SpiritSugarUnit: 'g / L',

    MoutVolumeUnit: globalState.userUnits.volumeUnit,
    MoutABVUnit: globalState.userUnits.alcoholUnit,
    MoutSugarUnit: globalState.userUnits.densityUnit,

    PmVolumeUnit: globalState.userUnits.volumeUnit,
    PmABVUnit: globalState.userUnits.alcoholUnit,
    PmSugarUnit: globalState.userUnits.densityUnit,
    temperatureUnit: globalState.userUnits.temperatureUnit,
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      savedUnits={['MoutVolumeUnit', 'MoutSugarUnit', 'PmVolumeUnit', 'PmSugarUnit', 'temperatureUnit', 'useCase']}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="mutage_form"
      validationSchema={() => validationSchema(t)}
      form={(
        <InnerForm />)}
    />

  );
}
