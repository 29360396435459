import * as yup from 'yup';

export default function validationSchema(t) {
  return yup.object().shape({
    productDensity: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    ingredientToAddDensity: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    finalDensity: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
    startVolume: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.positive_value'))
      .required(t('validations.positive_value'))
      .min(0, t('validations.positive_value'))
      .max(99999999999, t('validations.positive_value')),
  });
}
