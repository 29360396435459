import React, {
  useState, useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import {
  useSigninCheck, useUser, useFirestore,
  useFirestoreCollection,
} from 'reactfire';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  collection, query, where,
} from 'firebase/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import ActiveProducts from './ActiveProducts';
import ReloadPage from './ReloadPage';
import TierSubscriptionDisplays from './TierSubscriptionDisplays';
import { getAlcoholometryAccessGrants } from './utils';
import OldSubscriptions from './OldSubscriptions';

export default function Subscriptions() {
  const { status, data: signInCheckResult } = useSigninCheck();
  const { t } = useTranslation();

  if (status === 'loading') {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (signInCheckResult?.signedIn === false) {
    return (
      <Typography component="span" variant="h5" sx={{ my: 3 }}>
        {t('please_sign_in_for_func')}
      </Typography>
    );
  } if (signInCheckResult?.signedIn === true) {
    return (
      <>
        <SubscriptionsOfUser />
        <OldSubscriptions />
      </>
    );
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function SubscriptionsOfUser() {
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const { t } = useTranslation();
  const { data: user } = useUser();
  const db = useFirestore();
  const [pageStatus, setPageStatus] = useState('initial');

  const subscriptionsCollectionPath = globalState.activeOrganization === '' ? `users/${user?.uid}/subscriptions`
    : `organizations/${globalState.activeOrganization}/subscriptions`;

  const activeSubsQuery = query(collection(db, subscriptionsCollectionPath), where('status', '==', 'active'));

  const { data: activeSubsQuerySnapshot } = useFirestoreCollection(activeSubsQuery);

  const activeSubscriptions = useMemo(() => (activeSubsQuerySnapshot
    ? activeSubsQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) : []), [activeSubsQuerySnapshot]);

  useEffect(() => {
    if (activeSubscriptions.length > 0) {
      setPageStatus('showActiveSubs');
    } else {
      setPageStatus('showProducts');
    }
  }, [activeSubscriptions, pageStatus]);

  const refreshAccessGrants = useCallback(async (activeOrganization) => {
    const accessGrants = await getAlcoholometryAccessGrants(
      db,
      user,
      globalState.activeOrganization,
    );
    globalActions.setAccessGrants(accessGrants.validGrants);
    globalActions.setAccessGrantNames(accessGrants.uniqueGrantNames);
  }, [db, globalActions, globalState.activeOrganization, user]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const successParam = queryParameters.get('success');
    const sessionIdParam = queryParameters.get('session_id');
    if (queryParameters.get('canceled')) {
      globalActions.setSnackbarMessage({ message: t('subscriptions.order_canceled'), severity: 'error' });
      logEvent(analytics, 'subscriptions_checkout_canceled');
    } else if (successParam && sessionIdParam && activeSubscriptions.length === 0) {
      setPageStatus('shouldShowActiveSubs');
    } else if (successParam && sessionIdParam && activeSubscriptions.length > 0) {
      refreshAccessGrants();
    }
  }, [activeSubscriptions.length, analytics, globalActions, pageStatus, refreshAccessGrants, t]);

  if (['refetchActiveSubs', 'initial', 'loading'].includes(pageStatus)) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  } if (pageStatus === 'showSignIn') {
    return (
      <Typography component="span" variant="h5" sx={{ my: 3 }}>
        {t('please_sign_in_for_func')}
      </Typography>
    );
  } if (pageStatus === 'showActiveSubs') {
    return <ActiveProducts activeSubscriptions={activeSubscriptions} />;
  } if (pageStatus === 'shouldShowActiveSubs') {
    return <ReloadPage message={t('subscriptions.checkout_success')} />;
  }
  return <TierSubscriptionDisplays activeSubscriptions={[]} />;
}
