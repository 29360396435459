import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import { BoxetteFormContext, BoxetteForm } from 'components/boxettes/BoxetteForm';
import ResultCard from 'components/boxettes/ResultCard';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import CreditLoadingButton from 'components/CreditLoadingButton';
import validationTAVSchema from './ValidationTAVSchema';
import decimalsMask from './DecimalsMask';

export default function FlashPointBoxette(props) {
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const { flashPoint } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, temperatureUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, globalState.userUnits.densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    const results = await flashPoint(
      {
        abvAt20cValue: values.abvAt20cValue.replace(/,/, '.'),
        abvAt20cUnit: 'TAV',
        flashPointUnit: 'celsius',
        abvAt20cPrecision: decimalsMask.TAV,
      },
    );
    return results;
  };

  const defaultFormValues = {
    abvAt20cValue: '',
  };

  return (
    <BoxetteForm
      applyHistoryForResults
      decimalsMask={decimalsMask}
      boxetteCall={boxetteCall}
      defaultFormValues={defaultFormValues}
      formNameInStorage="flash_point_form"
      validationSchema={() => validationTAVSchema(t, alcoholUnitRelateds)}
      form={(
        <InnerForm
          setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
        />
      )}
    />
  );
}

function InnerForm(props) {
  const {
    formik, handleChange, handleBlur, calculating, results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 400,
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.flash_point')}
      </Typography>
      <LabelInputSelect
        label={(
          <Typography component="span">
            {t('forms.real_tav_at_ref_alcohol_and_ref_temp', {
              alcoholUnit: dynamicUnitName('TAV'),
              referenceTempValue: 20,
              referenceTempUnit: dynamicUnitName('celsius'),
            })}
          </Typography>
        )}
        input={(
          <NumberInput
            onChange={handleChange}
            name="abvAt20cValue"
            placeholder="ex: 56.88"
            value={formik.values.abvAt20cValue}
            decimalScale={decimalsMask.TAV}
            onBlur={(e) => {
              handleBlur(e, 'TAV');
            }}
          />
        )}
        error={formik.touched.abvAt20cValue && formik.errors.abvAt20cValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.abvAt20cValue}
        </Typography>
        )}
      />
      <CreditLoadingButton
        calculating={calculating}
        isValid={formik.isValid}
        cost={0}
        text={t('forms.calcul')}
      />
      <ResultCard
        title={t('flash_point.result')}
        result={resultsAvailable()
          ? `${results.flashPoint.value} ${dynamicUnitName(results.flashPoint.unit)}`
          : '-'}
      />
      <Typography
        sx={{ textAlign: 'left' }}
        component="span"
        variant="caption"
      >
        {t('flash_point.info', { joinArrays: '' })}
      </Typography>
    </form>
  );
}
