import * as yup from 'yup';
import {
  dynamicUnitName,
} from '../UnitDefinitionsAndMasks';

export default function validationSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    mvValidationPhrase: t('validations.dry_extract_validation', {
      densityMin: 0.1,
      densityMax: 100,
      densityUnit: dynamicUnitName(alcoholUnitRelateds.densityUnit),
    }),
  };

  return yup.object().shape({
    tavUnit: yup.string().required(),
    densityUnit: yup.string().required(),
    isRealTAV: yup.boolean(),
    realTAV: yup
      .number()
      .when('isRealTAV', {
        is: true,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.tavValidationPhrase)
          .required(validationPhrases.tavValidationPhrase)
          .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
          .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
        otherwise: (schema) => schema,
      }),
    dryExtract: yup
      .number()
      .when('isRealTAV', {
        is: false,
        then: (schema) => schema
          .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
          .typeError(validationPhrases.mvValidationPhrase)
          .required(validationPhrases.mvValidationPhrase)
          .min(0.1, validationPhrases.mvValidationPhrase)
          .max(100, validationPhrases.mvValidationPhrase),
        otherwise: (schema) => schema,
      }),
  });
}
