import React from 'react';
import { useTranslation } from 'react-i18next';
import ResultCard from 'components/boxettes/ResultCard';
import {
  dynamicUnitName,
} from 'components/boxettes/UnitDefinitionsAndMasks';
import useGlobal from 'global-state/store';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import toVolConvActions from './actions';

export default function MassVolumeResults(props) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const navigate = useNavigate();
  const {
    alcoholConversionResults,
    massVolumeConversionResults,
    formikValues,
  } = props;

  function resultsAvailable() {
    return (massVolumeConversionResults !== undefined && Object.keys(massVolumeConversionResults).length > 0);
  }

  const pureAlcoolMenuActions = () => {
    if (resultsAvailable()) {
      return [toPureAlcoolWithDryExtract()];
    }
    return undefined;
  };

  const toPureAlcoolWithDryExtract = () => {
    const callback = () => navigate('../pa-conv-with-dry-extract', {
      state: {
        alcoholConversionResults,
        volumeConversionResults: massVolumeConversionResults,
      },
    });
    return { title: t('boxettes.pa_conversion_with_dry_extract'), callback };
  };

  const volumeMenuActions = () => {
    if (resultsAvailable()) {
      return toVolConvActions(
        t,
        navigate,
        resultsAvailable() ? massVolumeConversionResults.volumeAtTemp.value : 0,
        globalState.userUnits.volumeUnit,
        alcoholConversionResults,
        formikValues,
      );
    }
    return undefined;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        width: '100%',
        maxWidth: 800,
      }}
    >
      <ResultCard
        title={t('forms.tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: alcoholConversionResults.ABVAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(
            alcoholConversionResults.ABVAt20c.temperature.unit,
          ),
        })}
        result={
          `${alcoholConversionResults.ABVAt20c.value} ${dynamicUnitName(alcoholConversionResults.ABVAt20c.unit)}`
        }
        infoButtonIcon={<span style={{ fontSize: 14 }}>[1]</span>}
        infoButtonText={t('forms.real_tav_info_1')}
      />

      <ResultCard
        color="secondary.main"
        title={
            t('forms.volume_at_temp')
            + formikValues.toVolTemp
            + temperatureUnitString(formikValues.temperatureUnit)
          }
        result={resultsAvailable() ? `${massVolumeConversionResults.volumeAtTemp.value} ${dynamicUnitName(
          massVolumeConversionResults.volumeAtTemp.unit,
        )}` : '-'}
        infoButtonIcon={<span style={{ fontSize: 14 }}>[2]</span>}
        infoButtonText={t('forms.to_volume_conversion_info')}
      />

      <ResultCard
        color="secondary.main"
        title={
            t('forms.volume_at_temp')
            + globalState.userUnits.temperatureValue
            + dynamicUnitName(globalState.userUnits.temperatureUnit)
          }
        result={resultsAvailable() ? `${massVolumeConversionResults.volumeAt20c.value} ${dynamicUnitName(
          massVolumeConversionResults.volumeAt20c.unit,
        )}` : '-'}
      />

      <ResultCard
        color="secondary.main"
        title={t('forms.pure_alcohol', {
          referenceTempValue: globalState.userUnits.temperatureValue,
          referenceTempUnit: dynamicUnitName(
            globalState.userUnits.temperatureUnit,
          ),
        })}
        result={resultsAvailable() ? `${massVolumeConversionResults.pureAlcoholVolumeAt20c.value} ${dynamicUnitName(
          massVolumeConversionResults.pureAlcoholVolumeAt20c.unit,
        )}` : '-'}
        infoButtonIcon={<span style={{ fontSize: 14 }}>[3]</span>}
        infoButtonText={t('forms.go_to_pa_with_dry_extract')}
        actions={pureAlcoolMenuActions()}
      />

      <ResultCard
        title={
            t('forms.volumetric_mass_at_temp')
            + globalState.userUnits.temperatureValue
            + temperatureUnitString(globalState.userUnits.temperatureUnit)
          }
        result={resultsAvailable()
          ? `${alcoholConversionResults.DensityAt20c.value} ${
            dynamicUnitName(alcoholConversionResults.DensityAt20c.unit)}`
          : '-'}
      />

      <ResultCard
        title={t('forms.pure_alcohol_mass')}
        result={resultsAvailable() ? `${massVolumeConversionResults.massAP.value} ${dynamicUnitName(
          massVolumeConversionResults.massAP.unit,
        )}` : '-'}
        infoButtonIcon={<span style={{ fontSize: 14 }}>[3]</span>}
        infoButtonText={t('forms.go_to_pa_with_dry_extract')}
        actions={pureAlcoolMenuActions()}
      />

      <Button
        color="secondary"
        variant="contained"
        style={{ marginTop: 15, marginBottom: 20 }}
        onClick={() => {
          navigate('/boxettes');
        }}
      >
        <Typography component="span">{t('boxettes.return_menu')}</Typography>
      </Button>
    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}
