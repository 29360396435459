import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import ResultCard from '../ResultCard';
import { readibleValueAndUnit } from '../UnitDefinitionsAndMasks';

export default function RectifySO2Results({ fromActiveSO2 }) {
  const {
    results, resultsAvailable, formik,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();

  const notLessThanZeroResult = (result) => (result.value > 0
    ? readibleValueAndUnit(result.value, result.unit) : '-');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 1,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: 600,
        minWidth: 350,
      }}
    >
      {fromActiveSO2 && (
        <ResultCard
          color="secondary.main"
          title={t('so2.free_calculated')}
          result={resultsAvailable()
            ? notLessThanZeroResult(results.freeSO2)
            : '-'}
        />
      )}
      <ResultCard
        color="secondary.main"
        title={t('so2.so2_to_add')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.SO2ToAdd)
          : '-'}
      />
      <ResultCard
        color="secondary.main"
        title={t('so2.total_so2_concentration')}
        result={resultsAvailable() && formik.values.totalSO2ConcentrationInValue !== ''
          ? notLessThanZeroResult(results.totalSO2Concentration)
          : '-'}
      />
      <Box sx={{ mt: 1 }} />
      <ResultCard
        title={t('so2.solution_p6')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P6)
          : '-'}
      />
      <ResultCard
        title={t('so2.solution_p8')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P8)
          : '-'}
      />
      <ResultCard
        title={t('so2.solution_p10')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P10)
          : '-'}
      />
      <ResultCard
        title={t('so2.solution_p18')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P18)
          : '-'}
      />
      <ResultCard
        title={t('so2.solution_p25')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P25)
          : '-'}
      />
      <ResultCard
        title={t('so2.solution_p40')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.solution_P40)
          : '-'}
      />
      <ResultCard
        title={t('so2.potassium_metabisulfite')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.potassiumMetabisulfite)
          : '-'}
      />
      <ResultCard
        title={t('so2.so2_gaz_bottle')}
        result={resultsAvailable()
          ? notLessThanZeroResult(results.SO2GazBottle)
          : '-'}
      />
    </Box>
  );
}
