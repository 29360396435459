/* eslint-disable import/prefer-default-export */
export function getUnitCategories() {
  return {
    volume: [
      { value: 'm3', display: 'm³' },
      { value: 'hL', display: 'HL (100L)' },
      { value: 'L', display: 'L (dm³)' },
      { value: 'dL', display: 'dl (100ml)' },
      { value: 'cL', display: 'cl' },
      { value: 'mL', display: 'ml (cm³)' },
      { value: 'barrelUS', display: 'barrel (US)' },
      { value: 'barrelUK', display: 'barrel (UK)' },
      { value: 'flozUK', display: 'floz (UK)' },
      { value: 'flozUS', display: 'floz (US)' },
      { value: 'galUK', display: 'gal (UK)' },
      { value: 'galUS', display: 'gal (US)' },
      { value: 'giUK', display: 'gi (UK)' },
      { value: 'giUS', display: 'gi (US)' },
      { value: 'pintUK', display: 'pint (UK)' },
      { value: 'pintUS', display: 'pint (US)' },
      { value: 'qtUK', display: 'qt (UK)' },
      { value: 'qtUS', display: 'qt (US)' },
      { value: 'cupUS', display: 'cup (US)' },
      { value: 'cupUK', display: 'cup (UK)' },
      { value: 'tbsp', display: 'tbsp' },
      { value: 'tsp', display: 'tsp' },
    ],
    length: [
      { value: 'cm', display: 'cm' },
      { value: 'in', display: 'in' },
      { value: 'ft', display: 'ft' },
      { value: 'mm', display: 'mm' },
      { value: 'm', display: 'm' },
      { value: 'km', display: 'km' },
      { value: 'mi', display: 'mi' },
      { value: 'yd', display: 'yd' },
      { value: 'nm', display: 'nm' },
    ],
    mass: [
      { value: 'kg', display: 'kg' },
      { value: 'g', display: 'g' },
      { value: 'mg', display: 'mg' },
      { value: 'lb', display: 'lb' },
      { value: 'oz', display: 'oz' },
      { value: 'tonne', display: 'tonne' },
      { value: 'stone', display: 'stone' },
    ],
    temperature: [
      { value: 'celsius', display: '°C' },
      { value: 'fahrenheit', display: '°F', disabled: true },
      { value: 'kelvin', display: 'K' },
    ],
    alcohol: [
      { value: 'TAV', display: '% vol' },
      { value: 'proofUS', display: 'proof (US)', disabled: true },
      { value: 'proofUK', display: 'proof (UK)', disabled: true },
      { value: 'ABV', display: 'ABV' },
    ],
    energy: [
      { value: 'kcal', display: 'kCal' },
      { value: 'kJ', display: 'kJ' },
      { value: 'cal', display: 'cal' },
    ],
  };
}
