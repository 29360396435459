import { dynamicUnitRestrictions } from 'components/boxettes/UnitDefinitionsAndMasks';

export default function alcoholUnitRelatedsFrom(alcoholUnit, temperatureUnit, densityUnit, globalState) {
  if (globalState.restrictedMode) {
    return {
      tavMin: dynamicUnitRestrictions(
        'restricted_tav_min_',
        'TAV',
        alcoholUnit,
      ),
      tavMax: dynamicUnitRestrictions(
        'restricted_tav_max_',
        'TAV',
        alcoholUnit,
      ),
      alcoholUnit,
      tempMin: dynamicUnitRestrictions(
        'restricted_temp_min_',
        'celsius',
        temperatureUnit,
      ),
      tempMax: dynamicUnitRestrictions(
        'restricted_temp_max_',
        'celsius',
        temperatureUnit,
      ),
      tempUnit: temperatureUnit,
      mvMin: dynamicUnitRestrictions(
        'restricted_mv_min_',
        'g / L',
        densityUnit,
      ),
      mvMax: dynamicUnitRestrictions(
        'restricted_mv_max_',
        'g / L',
        densityUnit,
      ),
      mvUnit: densityUnit,
    };
  }
  return {
    tavMin: dynamicUnitRestrictions('tav_min_', 'TAV', alcoholUnit),
    tavMax: dynamicUnitRestrictions('tav_max_', 'TAV', alcoholUnit),
    alcoholUnit,
    tempMin: dynamicUnitRestrictions('temp_min_', 'celsius', temperatureUnit),
    tempMax: dynamicUnitRestrictions('temp_max_', 'celsius', temperatureUnit),
    tempUnit: temperatureUnit,
    mvMin: dynamicUnitRestrictions('mv_min_', 'g / L', densityUnit),
    mvMax: dynamicUnitRestrictions('mv_max_', 'g / L', densityUnit),
    mvUnit: densityUnit,
  };
}
