export function initHistoryOperations(formNameInStorage) {
  const operationsFromStore = JSON.parse(localStorage
    .getItem(`history_operations::${formNameInStorage}`));
  if (operationsFromStore) {
    return operationsFromStore;
  }
  return [];
}

export function reducerHistoryOperations(currentState, dispatch) {
  let finalOperations = [...currentState];

  const filteredOperations = (id) => finalOperations.filter(
    (op) => op.id !== id,
  );

  const defaultDisplayName = () => new Date(dispatch.payload.operation.id).toLocaleString();

  switch (dispatch.type) {
    case 'add':
      if (finalOperations.length >= 10) {
        finalOperations.shift();
      }
      finalOperations.push({ ...dispatch.payload.operation, name: defaultDisplayName(), id: new Date().toISOString() });
      saveOperationInHistory(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    case 'remove':
      finalOperations = filteredOperations(dispatch.payload.operation.id);
      saveOperationInHistory(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    case 'rename':
      finalOperations = filteredOperations(dispatch.payload.operation.id);
      finalOperations.push({
        ...dispatch.payload.operation,
        name: dispatch.payload.name,
      });
      saveOperationInHistory(finalOperations, dispatch.payload.formNameInStorage);
      return finalOperations;
    default:
      throw new Error();
  }
}

function saveOperationInHistory(operations, formNameInStorage) {
  localStorage
    .setItem(`history_operations::${formNameInStorage}`, JSON.stringify(operations));
}
