import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Button from '@mui/material/Button';
import { useUser } from 'reactfire';
import { sendEmailVerification } from 'firebase/auth';

export default function VerifyEmail(props) {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const [emailSent, setemailSent] = React.useState(false);

  const sendEmail = () => {
    if (user) {
      sendEmailVerification(
        user,
        { url: `${window.location.protocol}//${window.location.host}/account?confirm_email=true` },
      );
      setemailSent(true);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        width: '100%',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <TaskAlt sx={{ fontSize: 40, color: 'secondary' }} />
      <Typography component="span" variant="h6">{t('signin.verify_email')}</Typography>
      <Typography component="span" variant="caption">
        {t('signin.verify_email_hint')}
        {' '}
        <b>contact@labox-apps.com</b>
      </Typography>
      {user && (
      <Button
        variant="contained"
        disabled={emailSent}
        color="secondary"
        onClick={() => sendEmail()}
      >
        {t('signin.re_send_email')}
      </Button>
      )}
    </Paper>
  );
}
