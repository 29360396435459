import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import { useFirestore, useFirestoreCollection, useUser } from 'reactfire';
import { collection, query, where } from 'firebase/firestore';

export default function ActiveAccessGrants() {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const { data: user } = useUser();
  const db = useFirestore();

  const accessGrantsCollectionPath = globalState.activeOrganization === '' ? `users/${user?.uid}/accessGrants`
    : `organizations/${globalState.activeOrganization}/accessGrants`;

  const activeSubsQuery = query(collection(db, accessGrantsCollectionPath), where('status', '==', 'active'));

  const { data: activeSubsQuerySnapshot } = useFirestoreCollection(activeSubsQuery);

  const activeAccessGrants = activeSubsQuerySnapshot
    ? activeSubsQuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) : [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography sx={{ flexGrow: 1 }} component="span" variant="h6">
        {t('access_grants.your_active_access_grants')}
      </Typography>
      {activeAccessGrants.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {activeAccessGrants.map((accessGrant) => (
            <ActiveAccessGrant key={accessGrant.id} accessGrant={accessGrant} />
          ))}
        </Box>
      )}
      {activeAccessGrants.length === 0 && (
        <Typography>{t('aucun')}</Typography>
      )}
    </Box>
  );
}

function ActiveAccessGrant({ accessGrant }) {
  const { t } = useTranslation();
  const { name, expirationDate } = accessGrant;

  const grantName = {
    'law-vs': 'VS',
    'law-vsop': 'VSOP',
    'law-xo': 'XO',
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      alignItems: 'flex-start',
      border: 1,
      borderRadius: 1,
      borderColor: 'primary.main',
      p: 1,
    }}
    >
      <Typography>{`${t('name')} : ${grantName[name] || name} `}</Typography>
      <Typography>
        {t(
          'expiration_date',
          { expirationDate: new Date(expirationDate.toDate()).toLocaleDateString() },
        )}
      </Typography>
    </Box>
  );
}
