import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import fr from './fr.json';
import de from './de.json';
import es from './es.json';
import pt from './pt.json';
import pl from './pl.json';

function detectLanguage() {
  const savedLocale = localStorage.getItem('saved_locale');
  return savedLocale;
}

export function initI18next() {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources: {
        en: {
          translation: en,
        },
        fr: {
          translation: fr,
        },
        de: {
          translation: de,
        },
        es: {
          translation: es,
        },
        pt: {
          translation: pt,
        },
        pl: {
          translation: pl,
        },
      },
      lng: detectLanguage(),
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false,
      },
    });
}

export default i18n;
