import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import { useSigninCheck } from 'reactfire';
import useGlobal from 'global-state/store';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function BoxetteCard(props) {
  const {
    image, title, activated, route, dispatchBoxettes, isFavorite, free = false,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: signInCheckResult } = useSigninCheck();
  const [globalState, globalActions] = useGlobal();

  const requiredGrants = ['law-vs', 'law-vsop', 'law-xo'];
  const hasRequiredGrants = requiredGrants.some((grant) => globalState.accessGrantNames.includes(grant));
  const isUnlocked = free || hasRequiredGrants;

  const notActivatedRedirect = () => {
    if (signInCheckResult?.signedIn === false) {
      navigate('/signin');
    } else if (signInCheckResult?.user.emailVerified === false) {
      globalActions.setSnackbarMessage({ message: t('signin.verify_email'), severity: 'warning' });
    }
  };

  const handleBoxetteClick = () => {
    if (signInCheckResult?.signedIn === false) {
      navigate('/signin');
    } else if (!isUnlocked) {
      navigate('/subscriptions');
    } else if (activated) {
      navigate(`${route}`);
    } else {
      notActivatedRedirect();
    }
  };

  const starIconColor = () => {
    if (isFavorite) {
      return 'gold';
    }
    return 'gray';
  };

  const starStrokeColor = () => {
    if (isFavorite) {
      return 'gold';
    }
    return 'black';
  };

  const tooltipText = () => {
    if (isFavorite) {
      return t('remove_from_favorites');
    }
    return t('add_to_favorites');
  };

  const handleFavoriteClick = (event) => {
    if (isFavorite) {
      dispatchBoxettes({ type: 'removeFromFavorites', name: route });
    } else {
      dispatchBoxettes({ type: 'addToFavorites', name: route });
    }
    event.stopPropagation();
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: '48%',
        maxWidth: 300,
        minWidth: 140,
        cursor: 'pointer',
        position: 'relative',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: { xs: 'scale(1)', sm: 'scale(1.02)' },
        },
      }}
      onClick={handleBoxetteClick}
    >
      {!isUnlocked && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(155, 155, 155, 0.65)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        />
      )}
      <CardMedia
        sx={{
          height: { xs: 120, sm: 200 },
          position: 'relative',
        }}
        image={image}
        title={title}
      />
      {/* {free && (
        <Typography
          sx={{
            position: 'absolute',
            top: 5,
            left: 5,
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: 'ourGreen.main',
            borderRadius: '5px',
            padding: '2px 5px',
            zIndex: 2,
          }}
        >
          Free
        </Typography>
      )} */}
      <Tooltip title={tooltipText()}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            fontSize: 20,
            p: 0.5,
            boxShadow: 3,
          }}
          color={starIconColor()}
          size="large"
          aria-label="favorite"
          component="label"
          onClick={handleFavoriteClick}
        >
          <StarIcon sx={{
            stroke: starStrokeColor(),
            strokeWidth: 0.5,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.3)',
            },
          }}
          />
        </IconButton>
      </Tooltip>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: { xs: 70, sm: 95 },
        px: { xs: 0.5, sm: 3 },
      }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
      </CardContent>
    </Card>
  );
}
