import * as yup from 'yup';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';

function positiveValidation(t) {
  return yup
    .number()
    .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .typeError(t('validations.positive_value'))
    .required(t('validations.positive_value'))
    .min(0, t('validations.positive_value'))
    .max(99999999999, t('validations.positive_value'));
}

export default function validationSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
  };

  return yup.object().shape({
    withBottle: yup.boolean(),
    abvAt20cValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(0, validationPhrases.tavValidationPhrase)
      .max(100, validationPhrases.tavValidationPhrase),
    sugarConcentrationValue: positiveValidation(t),
    lipidConcentrationValue: positiveValidation(t),
    proteinConcentrationValue: positiveValidation(t),
    bottleVolumeValue: yup
      .number()
      .when('withBottle', {
        is: true,
        then: (schema) => schema
          .typeError(t('validations.positive_value'))
          .required(t('validations.positive_value'))
          .min(1, t('validations.positive_value'))
          .max(99999999, t('validations.positive_value')),
        otherwise: (schema) => schema,
      }),
  });
}
