import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { BoxetteForm } from 'components/boxettes/BoxetteForm';
import Box from '@mui/material/Box';
import alcoholUnitRelatedsFrom from 'components/boxettes/UnitsRelatedFrom';
import useGlobal from '../../../global-state/store';
import validationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';
import DryExtractForm from './DryExtractForm';

export default function DryExtractBoxette(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const { computeObscurationAndDryExtract } = props;
  const [alcoholUnitRelateds, setAlcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
      globalState.userUnits.densityUnit,
      globalState,
    ),
  );

  const setAlcoholUnitRelatedsFrom = async (alcoholUnit, densityUnit) => {
    await setAlcoholUnitRelateds(
      alcoholUnitRelatedsFrom(alcoholUnit, globalState.userUnits.temperatureUnit, densityUnit, globalState),
    );
  };

  const boxetteCall = async (values) => {
    if (
      !values.isMVSelected
      && values.isRealTAVSelected
      && (values.rawTAVValue && values.realTAVValue
        && parseFloat(values.rawTAVValue) > parseFloat(values.realTAVValue))
    ) {
      globalActions.setSnackbarMessage({ message: t('validations.tav_raw_superior_to_real'), severity: 'warning' });
    } else {
      const results = await computeObscurationAndDryExtract({
        rawDensity20cValue: values.isMVSelected ? values.mvValue : null,
        rawAbv20cValue: values.isMVSelected ? null : values.rawTAVValue,
        trueAbv20cValue: values.isRealTAVSelected ? values.realTAVValue : null,
        dryExtractValue: values.isRealTAVSelected ? null : values.dryExtractValue,

        rawAbv20cUnit: values.tavUnit,
        rawDensity20cUnit: values.densityUnit,
        trueAbv20cUnit: values.tavUnit,
        dryExtractUnit: values.densityUnit,
        temperatureUnit: globalState.userUnits.temperatureUnit,

        rawDensity20cPrecision: decimalsMask[values.densityUnit],
        rawAbv20cPrecision: decimalsMask[values.tavUnit],

        trueAbv20cPrecision: decimalsMask[values.tavUnit],
        dryExtractPrecision: decimalsMask[values.densityUnit],

      });
      if (
        (results.rawABV.value && results.trueABV.value
        && parseFloat(results.rawABV.value) > parseFloat(results.trueABV.value))
      ) {
        globalActions.setSnackbarMessage({ message: t('validations.tav_raw_superior_to_real'), severity: 'warning' });
      }
      return results;
    }
    return ({});
  };

  const defaultFormValues = {
    mvValue: '',
    rawTAVValue: '',
    realTAVValue: '',
    dryExtractValue: '',
    isMVSelected: true,
    isRealTAVSelected: true,
    densityUnit: globalState.userUnits.densityUnit,
    tavUnit: globalState.userUnits.alcoholUnit,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Typography variant="h6" component="span">
        {t('boxettes.dryExtractObscuration')}
      </Typography>
      <Typography align="center" component="span" variant="caption">
        {t('dry_extract_obscuration.info_1', { joinArrays: '' })}
      </Typography>

      <BoxetteForm
        applyHistoryForResults
        decimalsMask={decimalsMask}
        boxetteCall={boxetteCall}
        defaultFormValues={defaultFormValues}
        savedUnits={['densityUnit']}
        formNameInStorage="dry_extract_obscuration_form"
        validationSchema={() => validationSchema(t, alcoholUnitRelateds)}
        form={(
          <DryExtractForm
            setAlcoholUnitRelatedsFrom={setAlcoholUnitRelatedsFrom}
          />
)}
      />
      <Typography align="center" component="span" variant="caption">
        {t('dry_extract_obscuration.info_2', { joinArrays: '' })}
      </Typography>
    </Box>
  );
}
