import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  useFirebaseApp,
  AuthProvider,
  AnalyticsProvider,
  FirestoreProvider,
  FirebaseAppProvider,
  FunctionsProvider,
  RemoteConfigProvider,
  useInitRemoteConfig,
  useInitFirestore,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import LoadingPage from 'components/LoadingPage';
import { Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { initI18next } from './i18n/i18next';
import Home from './components/Home';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'alcoholometry.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:14ee78bf594ed3db297452',
  measurementId: 'G-XTBKS2XPK5',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#3f4d5a',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    deepBlue: {
      main: '#213D45',
      contrastText: 'white',
    },
    background: {
      primary: '#f2f5f8',
    },
  },
});

function AllReactFireProviders() {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const functionsInstance = getFunctions(app);

  if (process.env.NODE_ENV === 'development') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
  }

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FunctionsProvider sdk={functionsInstance}>
          <ProvidersWithLoading />
        </FunctionsProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
}

function ProvidersWithLoading(props) {
  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    const baseSettings = {};

    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;

    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  const { status: remoteConfigStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 600000,
      fetchTimeoutMillis: 600000,
    };

    try {
      await fetchAndActivate(remoteConfig);
    } catch (e) {
      console.warn('Remote config failed to be activated');
    }

    return remoteConfig;
  });

  if (firestoreStatus === 'loading' || remoteConfigStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <RemoteConfigProvider sdk={remoteConfigInstance}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Box>
              <CssBaseline />
              <Home />
            </Box>
          </ThemeProvider>
        </StyledEngineProvider>
      </RemoteConfigProvider>
    </FirestoreProvider>
  );
}

function App() {
  initI18next();

  return (
    <HelmetProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <div className="App" style={{ whiteSpace: 'pre-line' }}>
          <AllReactFireProviders />
        </div>
      </FirebaseAppProvider>
    </HelmetProvider>
  );
}

export default App;
