import React, { useState } from 'react';
import {
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BoxettesIcon from '@mui/icons-material/Apps';
import SubscriptionsIcon from '@mui/icons-material/Payments';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ExpertsIcon from '@mui/icons-material/ContactPage';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmailIcon from '@mui/icons-material/Email';
import AutomatedIcon from '@mui/icons-material/ListAlt';
import BarrelIcon from 'icons/BarrelIcon';
import { useSigninCheck } from 'reactfire';
import logoen from 'files/logo-alco-en.svg';
import logofr from 'files/logo-alco-fr.svg';
import Truck from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import TechInfoIcon from '@mui/icons-material/MenuBook';

export default function SideDrawer() {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const navigate = useNavigate();

  const logo = () => (t('locale') === 'fr' ? logofr : logoen);

  const hasAccessToInventory = globalState.accessGrantNames.some((name) => ['law-xo', 'law-vsop'].includes(name));

  const handleInventoryClick = () => {
    if (!hasAccessToInventory) {
      navigate('/subscriptions');
    } else {
      window.open('https://inventory.labox-apps.com', '_blank');
    }
  };

  return (
    <Box>
      <Toolbar sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 1,
      }}
      >
        <img
          src={logo()}
          style={{ width: 120, height: 'auto' }}
          alt="LAW logo"
        />
      </Toolbar>
      <Box>
        <List>
          <ListItem
            component={Link}
            to="/boxettes"
            button
            key="boxettes"
          >
            <ListItemIcon>
              <BoxettesIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu.boxettes')} />
          </ListItem>
          <ListItem
            component="div"
            onClick={handleInventoryClick}
            button
            key="inventory"
          >
            <ListItemIcon>
              <AutomatedIcon />
            </ListItemIcon>
            <ListItemText primary={t('subscriptions.inventory')} />
          </ListItem>
          <ListItem
            component={Link}
            to="/menu-account"
            button
            key="settings"
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t('settings.settings')} />
          </ListItem>
          <ListItem
            component={Link}
            to="/subscriptions"
            button
            key="subscriptions"
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText primary={t('subscriptions.subscriptions')} />
          </ListItem>
          {globalState.issuers.length > 0
          && (
          <ListItem
            component={Link}
            to="/issuers"
            button
            key="issuers"
          >
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary="Codes" />
          </ListItem>
          )}

          <AboutUsItem />
          <ResourcesProItem />
          <OtherAppsItem />
        </List>

        {globalState.roles.includes('admin')
          && (
            <List>
              <ListItem
                component={Link}
                to="/admin"
                button
                key="admin"
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Super Admin" />
              </ListItem>
            </List>
          )}

      </Box>
    </Box>
  );
}

function AboutUsItem() {
  const { t } = useTranslation();

  return (
    <NestedListItem
      itemKey="aboutus"
      title={t('menu.aboutus')}
      icon={<HomeIcon />}
      list={(
        <List sx={{ pl: 2 }}>
          <ListItem
            component={Link}
            to="/about-us"
            button
            key="about-us"
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu.welcome')} />
          </ListItem>
          <ListItem
            component={Link}
            to="/tips"
            button
            key="tips"
          >
            <ListItemIcon>
              <TipsAndUpdatesIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu.tips')} />
          </ListItem>
          <ListItem
            component={Link}
            to="/menu-contact"
            button
            key="contact"
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Contact" />
          </ListItem>
        </List>
      )}
    />
  );
}

function ResourcesProItem() {
  const { t } = useTranslation();

  return (
    <NestedListItem
      itemKey="resourcesPro"
      title={t('menu.resources_pro')}
      icon={<BookIcon />}
      list={(
        <List sx={{ pl: 2 }}>
          <ListItem
            component={Link}
            to="/experts"
            button
            key="experts"
          >
            <ListItemIcon>
              <ExpertsIcon />
            </ListItemIcon>
            <ListItemText primary={t('menu.our_network')} />
          </ListItem>
          <BlogProListItem />
          <ListItem
            component={Link}
            to="/oiml-publication"
            button
            key="oiml-publication"
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="OIML" />
          </ListItem>
        </List>
      )}
    />
  );
}

function OtherAppsItem() {
  const { t } = useTranslation();

  return (
    <NestedListItem
      itemKey="otherApps"
      title={t('menu.other_apps')}
      icon={<AppShortcutIcon />}
      list={(
        <List sx={{ pl: 2 }}>
          <ListItem
            component="a"
            target="_blank"
            href="https://gc.digitank.labox-apps.com"
            button
            key="gauged_containers"
          >
            <ListItemIcon>
              <BarrelIcon size={24} color="#0000008a" />
            </ListItemIcon>
            <ListItemText primary={t('gauged_containers')} />
          </ListItem>
          <ListItem
            component="a"
            target="_blank"
            href="https://tt.digitank.labox-apps.com"
            button
            key="tanker_trucks"
          >
            <ListItemIcon>
              <Truck />
            </ListItemIcon>
            <ListItemText primary={t('tanker_trucks')} />
          </ListItem>
        </List>
      )}
    />
  );
}

function NestedListItem({
  itemKey, title, list, icon,
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        key={itemKey}
        onClick={handleClick}
      >
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {list}
      </Collapse>
    </>
  );
}

function BlogProListItem() {
  const { data: signInCheckResult } = useSigninCheck();
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();

  if (signInCheckResult?.signedIn === false) {
    return (
      <ListItem
        onClick={() => globalActions.setSnackbarMessage({ message: t('please_sign_in_for_func'), severity: 'info' })}
        button
        key="tech-info"
      >
        <ListItemIcon>
          <TechInfoIcon />
        </ListItemIcon>
        <ListItemText primary={t('menu.tech_info')} />
      </ListItem>
    );
  }
  return (
    <ListItem
      component="a"
      target="_blank"
      href="https://labox-alcoometrie-blog.com/"
      button
      key="tech-info"
    >
      <ListItemIcon>
        <TechInfoIcon />
      </ListItemIcon>
      <ListItemText primary={t('menu.tech_info')} />
    </ListItem>
  );
}
