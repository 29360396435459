import {
  Box, IconButton, Typography,
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Info from '@mui/icons-material/Info';
import useGlobal from 'global-state/store';

export default function Point({ text, infoButtonText }) {
  const [, globalActions] = useGlobal();
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'flex-start', textAlign: 'left',
    }}
    >
      <CheckIcon color="secondary" sx={{ fontSize: '18px', mt: 0.2 }} />
      <Box sx={{
        display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', textAlign: 'left',
      }}
      >
        <Typography sx={{ fontSize: '15px' }}>
          {text}
        </Typography>
        {infoButtonText && (
        <IconButton
          onClick={() => globalActions.setSnackbarMessage(
            { message: infoButtonText, severity: 'info' },
          )}
          sx={{ color: 'secondary.main', flexShrink: 0, p: 0.3 }}
        >
          <Info
            sx={{ fontSize: 16, p: 0, m: 0 }}
          />
        </IconButton>
        )}
      </Box>
    </Box>
  );
}
