import * as React from 'react';
import Box from '@mui/material/Box';
import { dynamicUnitName } from 'components/boxettes/UnitDefinitionsAndMasks';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useSigninCheck } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import AutomatedApiCalls from './AutomatedApiCalls';
import UllagingTAVVolume from './UllagingTAVVolume';
import TAVVolume from './TAVVolume';

export default function Automated() {
  const { t } = useTranslation();
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();

  const [units] = React.useState({
    volumeUnit: 'L',
    lengthUnit: 'cm',
    abvUnit: 'TAV',
    temperatureUnit: 'celsius',
  });

  React.useEffect(() => {
    if (signInCheckResult?.signedIn === false) {
      navigate('/signin');
    }
  }, [navigate, signInCheckResult?.signedIn]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 3,
    }}
    >
      <AutomatedApiCalls boxette={<UllagingTAVVolume units={units} />} />
      <AutomatedApiCalls boxette={<TAVVolume units={units} />} />
      <AcceptedUnits units={units} />
      <Typography
        sx={{ textAlign: 'left' }}
        component="span"
        variant="caption"
      >
        {t('automated.tav_info')}
      </Typography>
    </Box>
  );
}

function AcceptedUnits(props) {
  const { units } = props;
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center',
      }}
    >
      <Typography sx={{ mb: 3, fontWeight: 'bold' }}>
        {t('automated.acceptedUnits')}
      </Typography>
      <Box sx={{
        display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', gap: 3,
      }}
      >
        <Typography sx={{ flexGrow: 1 }}>
          {`${t('units.volume')}: 
            "${units.volumeUnit}"`}
        </Typography>
        <Typography sx={{ flexGrow: 1 }}>
          {`${t('units.alcohol')}: 
            "${units.abvUnit}" (${dynamicUnitName(units.abvUnit)})`}
        </Typography>
        <Typography sx={{ flexGrow: 1 }}>
          {`${t('units.length')}: 
            "${units.lengthUnit}"`}
        </Typography>
        <Typography sx={{ flexGrow: 1 }}>
          {`${t('units.temperature')}: 
            "${units.temperatureUnit}"`}
        </Typography>
      </Box>
    </Paper>
  );
}
