const { unit } = require('mathjs');
const { createUnit } = require('mathjs');
const { Unit } = require('mathjs');
const { round } = require('./utils');

if (!Unit.isValuelessUnit('qtUS')) {
  createUnit({
    barrelUS: '119.2405 L',
    barrelUK: '163.6592 L',
    flozUK: '0.0284131 L',
    flozUS: '0.0295735 L',
    galUK: '4.54609 L',
    galUS: '1.13652 L',
    giUK: '0.1420653125 L',
    giUS: '0.11829411825 L',
    pintUK: '0.568261 L',
    pintUS: '0.473176 L',
    qtUK: '1.13652 L',
    qtUS: '0.946353 L',
  });
}

if (!Unit.isValuelessUnit('mgP100mL')) {
  createUnit({
    mgP100mL: '1 g/hL',
  });
}

if (!Unit.isValuelessUnit('TAV')) {
  createUnit('TAV');
  createUnit({
    proofUS: '0.5 TAV',
    proofUK: '0.57 TAV',
    AbWt: '1.26 TAV',
  });
}

const volumeMasksHighPrecision = {
  m3: 3,
  hL: 4,
  L: 3,
  dL: 2,
  cL: 1,
  mL: 0,
  barrelUS: 3,
  barrelUK: 3,
  flozUK: 2,
  flozUS: 2,
  galUK: 3,
  galUS: 3,
  giUK: 3,
  giUS: 3,
  pintUK: 3,
  pintUS: 3,
  qtUK: 3,
  qtUS: 3,
};

const unitMasks = { ...volumeMasksHighPrecision };

const unitNamesDict = {
  proofUS: 'proof (US)',
  proofUK: 'proof (UK)',
  TAV: '% vol.',
  celsius: '°C',
  fahrenheit: '°F',
  mgP100mL: 'mg/100mL',
  m3: 'm³',
  hL: 'HL',
  barrelUS: 'barrel (US)',
  barrelUK: 'barrel (UK)',
  galUS: 'gal (US)',
  galUK: 'gal (UK)',
  L: 'L (dm³)',
  dL: 'dl',
  cL: 'cl',
  mL: 'ml (cm³)',
  flozUK: 'floz (UK)',
  flozUS: 'floz (US)',
  giUK: 'gi (UK)',
  giUS: 'gi (US)',
  pintUK: 'pint (UK)',
  pintUS: 'pint (US)',
  qtUK: 'qt (UK)',
  qtUS: 'qt (US)',
};

function convertAndRoundVolumeDisplay(value, volumeUnit) {
  return unit(value, 'L')
    .to(volumeUnit)
    .format({ precision: unitMasks[volumeUnit], notation: 'fixed' });
}

function convertAndRoundVolumeValue(value, volumeUnit) {
  return round(
    unit(value, 'L').to(volumeUnit).toNumber(volumeUnit),
    unitMasks[volumeUnit],
  );
}

function convertAndRoundTemperature(value, temperatureUnit) {
  return unit(value, 'celsius')
    .to(temperatureUnit)
    .format({ precision: 2, notation: 'fixed' });
}

function convertAndRoundMassVolume(value, densityUnit) {
  return unit(value, 'g / L')
    .to(densityUnit)
    .format({ precision: 1, notation: 'fixed' });
}

const baseUnitRelatedRestrictionsDict = {
  restricted_tav_min_TAV: 50,
  restricted_tav_max_TAV: 55,
  tav_min_TAV: 0,
  tav_max_TAV: 100,
  restricted_tav_mutage_min_TAV: 67,
  restricted_tav_mutage_max_TAV: 68,
  tav_mutage_min_TAV: 0,
  tav_mutage_max_TAV: 100,
  restricted_temp_min_celsius: 16,
  restricted_temp_max_celsius: 18,
  temp_min_celsius: -10,
  temp_max_celsius: 40,
  volume_min_L: 0,
  volume_max_L: 99999999,
  restricted_volume_min_L: 300,
  restricted_volume_max_L: 350,
  diameter_min_cm: 0,
  diameter_max_cm: 99999999,
  restricted_diameter_min_cm: 70,
  restricted_diameter_max_cm: 75,
  'mv_min_g / L': 759.20,
  'mv_max_g / L': 999.84,
};

function baseUnitDictTo(baseName, baseUnit, newUnit) {
  return round(
    unit(baseUnitRelatedRestrictionsDict[baseName], baseUnit)
      .to(newUnit)
      .toNumber(),
    1,
  );
}

const additionalUnitRelatedRestrictionsDict = {
  restricted_tav_min_proofUS: baseUnitDictTo(
    'restricted_tav_min_TAV',
    'TAV',
    'proofUS',
  ),
  restricted_tav_max_proofUS: baseUnitDictTo(
    'restricted_tav_max_TAV',
    'TAV',
    'proofUS',
  ),
  restricted_tav_min_proofUK: baseUnitDictTo(
    'restricted_tav_min_TAV',
    'TAV',
    'proofUK',
  ),
  restricted_tav_max_proofUK: baseUnitDictTo(
    'restricted_tav_max_TAV',
    'TAV',
    'proofUK',
  ),
  tav_min_proofUS: baseUnitDictTo('tav_min_TAV', 'TAV', 'proofUS'),
  tav_max_proofUS: baseUnitDictTo('tav_max_TAV', 'TAV', 'proofUS'),
  tav_min_proofUK: baseUnitDictTo('tav_min_TAV', 'TAV', 'proofUK'),
  tav_max_proofUK: baseUnitDictTo('tav_max_TAV', 'TAV', 'proofUK'),
  restricted_tav_mutage_min_proofUS: baseUnitDictTo(
    'restricted_tav_mutage_min_TAV',
    'TAV',
    'proofUS',
  ),
  restricted_tav_mutage_max_proofUS: baseUnitDictTo(
    'restricted_tav_mutage_max_TAV',
    'TAV',
    'proofUS',
  ),
  restricted_tav_mutage_min_proofUK: baseUnitDictTo(
    'restricted_tav_mutage_min_TAV',
    'TAV',
    'proofUK',
  ),
  restricted_tav_mutage_max_proofUK: baseUnitDictTo(
    'restricted_tav_mutage_max_TAV',
    'TAV',
    'proofUK',
  ),
  tav_mutage_min_proofUS: baseUnitDictTo(
    'tav_mutage_min_TAV',
    'TAV',
    'proofUS',
  ),
  tav_mutage_max_proofUS: baseUnitDictTo(
    'tav_mutage_max_TAV',
    'TAV',
    'proofUS',
  ),
  tav_mutage_min_proofUK: baseUnitDictTo(
    'tav_mutage_min_TAV',
    'TAV',
    'proofUK',
  ),
  tav_mutage_max_proofUK: baseUnitDictTo(
    'tav_mutage_max_TAV',
    'TAV',
    'proofUK',
  ),
  restricted_temp_min_fahrenheit: baseUnitDictTo(
    'restricted_temp_min_celsius',
    'celsius',
    'fahrenheit',
  ),
  restricted_temp_max_fahrenheit: baseUnitDictTo(
    'restricted_temp_max_celsius',
    'celsius',
    'fahrenheit',
  ),
  temp_min_fahrenheit: baseUnitDictTo(
    'temp_min_celsius',
    'celsius',
    'fahrenheit',
  ),
  temp_max_fahrenheit: baseUnitDictTo(
    'temp_max_celsius',
    'celsius',
    'fahrenheit',
  ),
};

const unitRelatedRestrictionsDict = {

  ...baseUnitRelatedRestrictionsDict,
  ...additionalUnitRelatedRestrictionsDict,
};

function dynamicUnitRestrictions(baseName, baseUnit, newUnit) {
  if (unitRelatedRestrictionsDict[baseName + newUnit] === undefined) {
    const newRestriction = baseUnitDictTo(
      baseName + baseUnit,
      baseUnit,
      newUnit,
    );
    unitRelatedRestrictionsDict[baseName + newUnit] = newRestriction;
  }
  return unitRelatedRestrictionsDict[baseName + newUnit];
}

function dynamicUnitName(unitName) {
  const changedUnit = unitNamesDict[unitName];
  if (unitNamesDict[unitName] === undefined) {
    return unitName;
  }
  return changedUnit;
}

function rewriteValueAndUnit(unitedValue) {
  const valueUnitArray = unitedValue.split(' ');
  if (valueUnitArray.length === 2) {
    const fixedUnit = dynamicUnitName(valueUnitArray[1]);
    return `${valueUnitArray[0]} ${fixedUnit}`;
  }
  return unitedValue;
}

function readibleValueAndUnit(value, unitParam) {
  const fixedUnit = dynamicUnitName(unitParam);
  return `${value} ${fixedUnit}`;
}

export {
  dynamicUnitName,
  convertAndRoundVolumeDisplay,
  convertAndRoundVolumeValue,
  convertAndRoundMassVolume,
  convertAndRoundTemperature,
  rewriteValueAndUnit,
  dynamicUnitRestrictions,
  readibleValueAndUnit,
  unitMasks,
};
