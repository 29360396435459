import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/boxettes/BoxetteInputs';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import { Paper } from '@mui/material';
import decimalsMask from './DecimalsMask';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';

export default function MutageAlcohol(props) {
  const {
    formik, handleChange, handleBlur, setResults,
  } = React.useContext(BoxetteFormContext);
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: 1,
        p: 2,
      }}
    >
      <Typography>
        {t('mutage.mutage_alcohol')}
      </Typography>
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.volume_value')}</Typography>
          }
        input={(
          <NumberInput
            disabled={['1', '2', '3', '4'].includes(formik.values.useCase)}
            id="SpiritVolumeValue"
            name="SpiritVolumeValue"
            value={formik.values.SpiritVolumeValue}
            placeholder="ex: 100"
            onChange={handleChange}
            decimalScale={decimalsMask[formik.values.SpiritVolumeUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.SpiritVolumeUnit);
            }}
          />
          )}
        error={
            formik.touched.SpiritVolumeValue
            && formik.errors.SpiritVolumeValue && (
              <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
                {formik.errors.SpiritVolumeValue}
              </Typography>
            )
          }
        select={(
          <VolumeUnitPicker
            unit={formik.values.SpiritVolumeUnit}
            setUnit={async (newUnit) => {
              await formik.setFieldValue('SpiritVolumeUnit', newUnit);
              setResults({});
            }}
          />
          )}
      />
      <LabelInputSelect
        label={
          <Typography component="span">{t('forms.with_tav')}</Typography>
        }
        input={(
          <NumberInput
            disabled={['3', '4'].includes(formik.values.useCase)}
            onChange={handleChange}
            name="SpiritABVValue"
            placeholder="ex: 56.88"
            value={formik.values.SpiritABVValue}
            decimalScale={decimalsMask[formik.values.SpiritABVUnit]}
            onBlur={(e) => {
              handleBlur(e, formik.values.SpiritABVUnit);
            }}
          />
        )}
        error={formik.touched.SpiritABVValue && formik.errors.SpiritABVValue && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
          {formik.errors.SpiritABVValue}
        </Typography>
        )}
        select={(
          <Typography>
            {dynamicUnitName(formik.values.SpiritABVUnit)}
          </Typography>
        )}
      />
    </Paper>
  );
}
