import React from 'react';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CreditLoadingButton(props) {
  const {
    calculating, isValid, text, inParrallelClickHandle, width = '100%',
  } = props;
  return (
    <LoadingButton
      loading={calculating}
      color="secondary"
      variant="contained"
      disabled={!isValid}
      type="submit"
      onClick={inParrallelClickHandle}
      sx={{
        width,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>
        {text}
      </Typography>
    </LoadingButton>
  );
}
