import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AlcoholUnitPicker(props) {
  const { unit, setUnit } = props;
  return (
    <FormControl variant="outlined">
      <Select
        labelId="alcohol-select"
        id="alcohol-select"
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
      >
        <MenuItem value="TAV">% vol</MenuItem>
        <MenuItem value="proofUS" disabled>proof (US)</MenuItem>
        <MenuItem value="proofUK" disabled>proof (UK)</MenuItem>
      </Select>
    </FormControl>
  );
}
