import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useFirebaseApp, useSigninCheck, useUser } from 'reactfire';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Box from '@mui/material/Box';

export default function AutomatedApiCalls(props) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const { boxette } = props;
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  // eslint-disable-next-line no-unused-vars
  const { statusAuth, data: signInCheckResult } = useSigninCheck();
  // eslint-disable-next-line no-unused-vars
  const { statusUser, data: user } = useUser();
  const analytics = getAnalytics();

  async function sendRequest(method, url, body, hdrs) {
    let headers = hdrs;
    headers = typeof headers !== 'undefined' ? headers : new Headers();
    headers.append('UserLocale', t('locale'));
    if (method === 'POST') {
      headers.append('Content-Type', 'application/json');
    }
    const myInit = {
      method,
      headers,
      mode: 'cors',
      cache: 'default',
    };

    if (method === 'POST') {
      const dataAndPrecision = {
        ...body,
      };
      myInit.body = JSON.stringify(dataAndPrecision);
    }
    const myRequest = new Request(url, myInit);
    logEvent(analytics, 'automated_api_call', {
      url,
      appName: 'Alcoholometry',
      organization: globalState.activeOrganization,
      userUid: user?.uid,
    });
    try {
      const response = await fetch(myRequest);
      await handleCallErrors(response);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      if (error instanceof TableMatchException) {
        globalActions.setSnackbarMessage({ message: t('error.handled_error_values'), severity: 'warning' });
      } else {
        globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
        logEvent(analytics, 'boxette_api_call_unhandled_error', {
          error: error.message,
        });
      }
      return null;
    }
  }

  async function sendAuthenticatedRequest(method, url, body) {
    const token = await user?.getIdToken(true);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    return sendRequest(method, url, body, myHeaders);
  }

  async function handleCallErrors(response) {
    if (!response.ok) {
      if (response.status === 401) {
        signOut(auth);
      } else if (response.status === 500) {
        throw Error(
          `${t('menu_boxettes.error_service')}. \n${response.statusText}`,
        );
      } else if (response.status === 402) {
        throw Error(t('menu_boxettes.error_credits'));
      } else if (response.status === 400) {
        const responseJson = await response.json();
        if (responseJson.code === 11) {
          throw new TableMatchException();
        } else if (responseJson.code === 12) {
          throw Error(t(
            'automated.error_header',
            {
              missingHeaders: responseJson.missingHeaders,
              unknownHeaders: responseJson.unknownHeaders,
              joinArrays: '',
            },
          ));
        }
      }
      throw Error(`${response.status} ${response.statusText}`);
    }
    return response;
  }

  async function ullagingABVConvVolumeConv(parameters) {
    const requestMethod = signInCheckResult?.signedIn === true
      ? sendAuthenticatedRequest
      : sendRequest;
    const results = await requestMethod('POST', '/automated-api/alcoholometric-volume-conversion-ullaging', parameters);
    downloadCsv(results.csv, 'resultats_tav_volume_recensement');
    return results.result;
  }

  async function abvConvVolumeConv(parameters) {
    const requestMethod = signInCheckResult?.signedIn === true
      ? sendAuthenticatedRequest
      : sendRequest;
    const results = await requestMethod('POST', '/automated-api/alcoholometric-volume-conversion', parameters);
    downloadCsv(results.csv, 'resultats_tav_volume');
    return results.result;
  }

  function downloadCsv(csv, name) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${name}.csv`;
    hiddenElement.click();
  }

  return (
    <Box sx={{ width: '100%' }}>
      {React.cloneElement(boxette, {
        ullagingABVConvVolumeConv,
        abvConvVolumeConv,
      })}
    </Box>
  );
}

export class TableMatchException extends Error {
  constructor(message) {
    super(message);
    this.name = 'TABLE_MATCH_ERROR';
    this.message = message;
  }
}
