import React from 'react';
import Box from '@mui/material/Box';
import {
  Typography, Card, CardContent, Link as MUILink,
} from '@mui/material';

export function ContactCard(props) {
  const { images, text, link } = props;
  return (
    <Card
      style={{
        display: 'flex', flexDirection: 'column', margin: 15, width: '75%', maxWidth: 800,
      }}
    >
      <MUILink
        component="a"
        target="_blank"
        href={link}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <CardContent sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'flex-start',
          gap: 2,
        }}
        >

          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'column' },
            justifyContent: 'center',
            textAlign: 'left',
          }}
          >
            {images}
          </Box>

          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left',
          }}
          >
            {text}
          </Box>
        </CardContent>
      </MUILink>
    </Card>
  );
}

export function OrangeText(props) {
  const { text } = props;
  return (
    <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 'bold' }} component="span">
      {text}
    </Typography>
  );
}

export function DoubleText(props) {
  const { text1, text2 } = props;
  return (
    <Typography variant="body2" color="textSecondary" component="span">
      <OrangeText text={text1} />
      {' '}
      {text2}
    </Typography>
  );
}
