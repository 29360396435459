import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import orangeVolume from 'files/orange-volume.png';
import wineVolume from 'files/wine-volume.png';
import { NumberInput, LabelInputSelect } from 'components/boxettes/BoxetteInputs';
import Checkbox from '@mui/material/Checkbox';
import VolumeUnitPicker from 'components/units/VolumeUnitPicker';
import CreditLoadingButton from 'components/CreditLoadingButton';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import decimalsMask from './DecimalsMask';

export default function VolumeForm(props) {
  const {
    formik, handleChange, handleBlur, calculating,
  } = useContext(BoxetteFormContext);
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {t('reduction_improvement.intro_phrase')}
      </Typography>
      <ImageRadioText
        text={t('reduction_improvement.choose_volume_start')}
        isSelected={!formik.values.isPreparation}
        imageSource={orangeVolume}
        setSelected={() => formik.setFieldValue('isPreparation', false)}
        colors={{ bg: '#FAEAD2', check: '#F4AD45' }}
      />
      <ImageRadioText
        text={t('reduction_improvement.choose_volume_final')}
        isSelected={formik.values.isPreparation}
        imageSource={wineVolume}
        setSelected={() => formik.setFieldValue('isPreparation', true)}
        colors={{ bg: '#EED8DD', check: '#975462' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
        >
          <Typography component="span" sx={{ fontWeight: 'bold' }}>{t('reduction_improvement.at_20C')}</Typography>
          <IconButton
            color="primary"
            onClick={() => globalActions.setSnackbarMessage({
              message: t('reduction_improvement.ref_vol_temp_info'),
              severity: 'info',
            })}
          >
            <Info />
          </IconButton>
        </Box>
        <LabelInputSelect
          input={(
            <NumberInput
              onChange={handleChange}
              name="volumeValue"
              placeholder="ex: 4.567"
              value={formik.values.volumeValue}
              decimalScale={decimalsMask[formik.values.volumeUnit]}
              onBlur={(e) => {
                handleBlur(e, formik.values.volumeUnit);
              }}
            />
                )}
          select={(
            <VolumeUnitPicker
              unit={formik.values.volumeUnit}
              setUnit={async (newUnit) => {
                await formik.setFieldValue('volumeUnit', newUnit);
              }}
            />

                )}
          error={formik.touched.volumeValue
                  && formik.errors.volumeValue && (
                    <Typography style={{ fontSize: 10, color: 'red' }}>
                      {formik.errors.volumeValue}
                    </Typography>
          )}
        />
        {formik.touched.volume && formik.errors.volume && (
        <Typography component="span" style={{ fontSize: 13, color: 'red' }}>{formik.errors.volume}</Typography>)}
      </Box>
      <Box sx={{
        width: '100%', maxWidth: 500, display: 'flex', flexDirection: 'column', gap: 1,
      }}
      >
        <CreditLoadingButton
          calculating={calculating}
          isValid={formik.isValid}
          cost={1}
          text={t('forms.calcul')}
          inParrallelClickHandle={() => { "setPage('results')"; }}
        />
      </Box>
    </Box>
  );
}

function ImageRadioText(props) {
  const {
    isSelected, setSelected, colors, imageSource, text,
  } = props;
  const backgroundColor = isSelected ? colors.bg : '#fafaf5';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: 500,
        backgroundColor,
        flexGrow: 1,
      }}
      onClick={setSelected}
    >
      <img
        src={imageSource}
        style={{
          flexBasis: 1,
          width: '100%',
          maxWidth: 100,
          height: 'auto',
        }}
        alt={imageSource.toString()}
      />
      <IconButton>
        <Checkbox
          fontSize="large"
          checked={isSelected}
          color="secondary"
        />
      </IconButton>
      <Typography component="span">{text}</Typography>
    </Box>
  );
}
