import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ValidateUserEmail from './ValidateUserEmail';
import ActivateCodeToUser from './ActivateCodeToUser';
import CreateCode from './CreateCode';
import ActivateCodeToOrganization from './ActivateCodeToOrganization';
import ManageAccessGrants from './accessGrants/ManageAccessGrants';

export default function Admin(props) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 3,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <ValidateUserEmail />
        <ManageAccessGrants />
      </Box>
      <Typography variant="h4">
        Ancien modèle avec les codes et les crédits:
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 3,
        }}
      >

        <ActivateCodeToUser />
        <ActivateCodeToOrganization />
        <CreateCode />
      </Box>
    </Box>
  );
}
