import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { BoxetteFormContext } from 'components/boxettes/BoxetteForm';
import { useTranslation } from 'react-i18next';
import { dynamicUnitName } from '../UnitDefinitionsAndMasks';

export default function Results() {
  const {
    results, resultsAvailable,
  } = React.useContext(BoxetteFormContext);

  if (!resultsAvailable()) {
    return (
      <>
      </>
    );
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 1,
      justifyContent: 'center',
      width: '100%',
    }}
    >
      {['Spirit', 'Mout', 'Pm'].map((name) => (
        <ElementDisplay key={name} name={name} results={results} />
      ))}
    </Box>
  );
}

function ElementDisplay({ name, results }) {
  const { t } = useTranslation();
  return (
    <Paper elevation={0} sx={{ borderRadius: 1, flexGrow: 1 }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'ourGreen.main',
        p: 2,
        borderRadius: '4px 4px 0px 0px;',
      }}
      >
        <Typography variant="h6" sx={{ color: 'white' }}>
          {t(`mutage.element_${name}`)}
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 1, px: 3, py: 1, alignItems: 'flex-start',
      }}
      >
        {['Volume', 'ABV', 'Sugar'].map((key) => (
          <Box key={key}>
            <Typography variant="body1">
              {t(`mutage.res_${key}`)}
              {' : '}
              {results[`${name}${key}`].value}
              {' '}
              {dynamicUnitName(results[`${name}${key}`].unit)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}
