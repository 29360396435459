import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Select, MenuItem, FormControl,
  Tooltip,
  IconButton,
} from '@mui/material';
import useGlobal from 'global-state/store';
import InfoIcon from '@mui/icons-material/Info';

export default function LanguageSelectToggle() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(localStorage.getItem('saved_locale') || t('locale'));
  const [, globalActions] = useGlobal();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  const handleLocaleChange = (event) => {
    const newLocale = event.target.value;
    setLocale(newLocale);
    i18n.changeLanguage(newLocale);
    localStorage.setItem('saved_locale', newLocale);
    if (newLocale !== 'en' && newLocale !== 'fr') {
      const warningMessage = t('translation_warning');
      globalActions.setSnackbarMessage(({ message: warningMessage, severity: 'info' }));
    }
  };

  return (
    <FormControl variant="outlined">
      <Select
        size="small"
        id="language-select"
        value={locale}
        onChange={handleLocaleChange}
        sx={{ with: 100, height: 33 }}
      >
        <MenuItem value="en">EN</MenuItem>
        <MenuItem value="fr">FR</MenuItem>
        <MenuItem value="de">
          DE
          <TranslationWarning />
        </MenuItem>
        <MenuItem value="es">
          ES
          <TranslationWarning />
        </MenuItem>
        <MenuItem value="pt">
          PT
          <TranslationWarning />
        </MenuItem>
        <MenuItem value="pl">
          PL
          <TranslationWarning />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

function TranslationWarning() {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('translation_warning')}>
      <IconButton sx={{ width: 12 }} color="ourOrange">
        <InfoIcon sx={{ fontSize: 12 }} />
      </IconButton>
    </Tooltip>
  );
}
