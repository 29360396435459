import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useUser, useSigninCheck } from 'reactfire';
import Button from '@mui/material/Button';
import { getAuth, signOut } from 'firebase/auth';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link as MUILink } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import useGlobal from 'global-state/store';
import VerifyEmail from 'components/account/VerifyEmail';
import OrganizationsSelect from 'components/organization/OrganizationsSelect';
import ActiveProducts from 'components/subscriptions/ActiveProducts';
import FavoriteUnitsSelection from './FavoriteUnitsSelection';
import LanguageSelect from './LanguageSelect';
import AlcoApiConfig from './AlcoApiConfig';
import ActiveAccessGrants from './ActiveAccessGrants';

export default function MyAccount(props) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { status, data: user } = useUser();
  const auth = getAuth();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const { data: signInCheckResult } = useSigninCheck();

  function email() {
    if (user) {
      return user.email;
    }
    return '_';
  }

  const logout = () => {
    signOut(auth).then(() => {
      globalActions.setAccessGrants([]);
      globalActions.setAccessGrantNames([]);
      globalActions.setUserStatus('initial');
      globalActions.setUserOrganizations({});
      globalActions.setActiveOrganization('');
      navigate('/');
    });
  };

  const boxettesActivated = () => signInCheckResult?.signedIn === true
    && signInCheckResult?.user.emailVerified === true;

  React.useEffect(() => {
    if (signInCheckResult?.signedIn === false) {
      navigate('/signin');
    }
  }, [navigate, signInCheckResult?.signedIn]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        width: '100%',
        maxWidth: 1000,
      }}
    >
      {boxettesActivated() === false && (
      <VerifyEmail />)}

      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '100%',
          gap: 3,
          mb: 2,
        }}
        >
          <Typography
            component="span"
            sx={{ fontWeight: 'bold', wordBreak: 'break-word' }}
            variant="h6"
          >
            {email()}
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            sx={{ color: 'red' }}
            size="large"
            onClick={logout}
            startIcon={<ExitToApp />}
          >
            {t('user_card.quit')}
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography>{t('welcome.contact_message')}</Typography>
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <OrganizationsSelect />
      </Paper>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <ActiveProducts redirectToSubscriptionsIfNone />
      </Paper>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <ActiveAccessGrants />
      </Paper>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '100%',
        gap: 1,
      }}
      >
        <FavoriteUnitsSelection />
        <LanguageSelect />
        <AlcoApiConfig />
      </Box>
    </Box>
  );
}
