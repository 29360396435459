import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import FileDownload from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

export default function OperationsHistoryDialog(props) {
  const { t } = useTranslation();
  const {
    operations, setOpen,
    open, applyValuesAndResultsFromHistory,
    dispatchHistoryOperations,
    formNameInStorage,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
        {t('history.long_title')}
      </DialogTitle>
      <DialogContent>
        {operations.length > 0 && (
        <List sx={{ width: '100%', gap: 1 }}>
          {operations.slice().reverse().map((op) => (
            <Box key={op.id}>
              <OperationItem
                operation={op}
                applyValuesAndResultsFromHistory={applyValuesAndResultsFromHistory}
                handleClose={handleClose}
                dispatchHistoryOperations={dispatchHistoryOperations}
                formNameInStorage={formNameInStorage}
                operations={operations}
              />
              <Divider />
            </Box>
          ))}
        </List>
        )}
        {operations.length <= 0 && (
        <Typography component="span">
          {t('history.empty')}
        </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function OperationItem(props) {
  const {
    operation,
    applyValuesAndResultsFromHistory,
    handleClose,
    dispatchHistoryOperations,
    formNameInStorage,
  } = props;
  const displayName = new Date(operation.id).toLocaleString();
  const { t } = useTranslation();

  const handleRemoveClick = (event) => {
    dispatchHistoryOperations({ type: 'remove', payload: { operation, formNameInStorage } });
  };

  return (
    <ListItem>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
      }}
      >

        <Typography>{displayName}</Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1,
        }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              applyValuesAndResultsFromHistory(operation);
              handleClose();
            }}
            endIcon={<FileDownload />}
          >
            {t('apply')}
          </Button>
          <Tooltip title={t('delete')}>
            <IconButton
              size="large"
              aria-label="favorite"
              component="label"
              onClick={handleRemoveClick}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </ListItem>
  );
}
